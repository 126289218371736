import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import HTMLSidebar from "./HTMLSidebar";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";

const MetaRole = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const codeSnippetJSX = `<meta charset="UTF-8">`;
    const codeSnippetJSX2 = `<meta name="viewport" content="width=device-width, initial-scale=1.0">`;
    const codeSnippetJSX3 = `<meta name="title" content="Your Page Title">`;
    const codeSnippetJSX4 = `<meta name="description" content="A concise description of your webpage">`;
    const codeSnippetJSX5 = `<meta name="keywords" content="Keyword1, Keyword2, Keyword3">`;
    const codeSnippetJSX6 = `<meta name="robots" content="index, follow">`;
    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    return (
        <>
            <HighLight />
            <Helmet>
                <title>The Role of Meta Tags in HTML - Weblession</title>
                <meta name="description" content="Meta tags are an integral part of HTML that play a crucial role in conveying essential information about a web page to both search engines and visitors." />
                <meta name="Keywords" content="how to add meta tags in html for seo, uses of meta tags in html, multiple meta tags in html, how to add meta tags in html, all meta tags in html, explain meta tags in html, how to write meta tags in html, how to add meta tags in html for search engines, meta tag in html for browser compatibility, basic meta tag in html, what is meta tag in html with example, meta element in html code, responsive meta tags in your html document, meta tag in html for responsive, meta tag in html for mobile, what are meta tags used for in html, meta tags html not working" />
                <link rel="canonical" href="https://www.weblession.com/meta-tag-in-html" />
                <meta name="og:type" property="og:type" content="article" />
                <meta name="og:url" property="og:url" content="https://www.weblession.com/meta-tag-in-html" />
                <meta name="og:title" property="og:title" content="The Role of Meta Tags in HTML" />
                <meta name="og:description" property="og:description" content="Meta tags are an integral part of HTML that play a crucial role in conveying essential information about a web page to both search engines and visitors." />
                <meta name="og:image" property="og:image" content="images/html_css.webp" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <InnerPagesBanner title="The Role of Meta Tags in HTML" description="Meta tags are an integral part of HTML that play a crucial role in conveying essential information about a web page to both search engines and visitors." url="Home" sec="HTML" active="Meta Tags" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <h2 className="common_title">What are Meta Tags?</h2>
                            <p>
                                Meta tags are HTML components that furnish metadata pertaining to a webpage. They don't appear on the webpage itself but rather in the HTML code's head section. These tags serve as a communication bridge between the website and various entities, such as browsers, search engines, and social media platforms.
                            </p>
                            <h2 className="common_title">Key Meta Tags and Their Roles</h2>
                            <ol>
                                <li>
                                    <strong>Meta Charset Tag</strong>
                                    <div className="position-relative">
                                        <pre>
                                            <code className="javascript">
                                                {codeSnippetJSX}
                                            </code>
                                        </pre>
                                        <div className="copy_btn_right">
                                            <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippetJSX)}>
                                                <img src="images/copy_icon.svg" />
                                                <span className="tooltiptext">Copy</span>
                                            </button>
                                        </div>
                                    </div>
                                    This tag specifies the character encoding for the webpage. It ensures that the browser displays text correctly, especially for international characters.
                                </li>
                                <li>
                                    <strong>Meta Viewport Tag</strong>
                                    <div className="position-relative">
                                        <pre>
                                            <code className="javascript">
                                                {codeSnippetJSX2}
                                            </code>
                                        </pre>
                                        <div className="copy_btn_right">
                                            <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippetJSX2)}>
                                                <img src="images/copy_icon.svg" />
                                                <span className="tooltiptext">Copy</span>
                                            </button>
                                        </div>
                                    </div>
                                    This tag is crucial for responsive web design. It instructs the browser on how to scale and display the content on various devices, ensuring a consistent user experience
                                </li>
                                <li>
                                    <strong>Meta Title Tag</strong>
                                    <div className="position-relative">
                                        <pre>
                                            <code className="javascript">
                                                {codeSnippetJSX3}
                                            </code>
                                        </pre>
                                        <div className="copy_btn_right">
                                            <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippetJSX3)}>
                                                <img src="images/copy_icon.svg" />
                                                <span className="tooltiptext">Copy</span>
                                            </button>
                                        </div>
                                    </div>
                                    This tag specifies the title of the webpage, which is displayed in the title bar or tab of the web browser. It's also a crucial element for search engine optimization (SEO) since search engines often use it as the page's title in search results.
                                </li>
                                <li>
                                    <strong>Meta Description Tag</strong>
                                    <div className="position-relative">
                                        <pre>
                                            <code className="javascript">
                                                {codeSnippetJSX4}
                                            </code>
                                        </pre>
                                        <div className="copy_btn_right">
                                            <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippetJSX4)}>
                                                <img src="images/copy_icon.svg" />
                                                <span className="tooltiptext">Copy</span>
                                            </button>
                                        </div>
                                    </div>
                                    The meta description tag furnishes a concise overview of the content featured on the webpage. Search engines may use this description in search results, making it a vital component for attracting users.
                                </li>
                                <li>
                                    <strong>Meta Keywords Tag (deprecated)</strong>
                                    <div className="position-relative">
                                        <pre>
                                            <code className="javascript">
                                                {codeSnippetJSX5}
                                            </code>
                                        </pre>
                                        <div className="copy_btn_right">
                                            <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippetJSX5)}>
                                                <img src="images/copy_icon.svg" />
                                                <span className="tooltiptext">Copy</span>
                                            </button>
                                        </div>
                                    </div>
                                    While less relevant today, this tag used to contain a list of keywords related to the webpage's content. However, search engines now rely more on content analysis than keyword lists.
                                </li>
                                <li>
                                    <strong>Meta Robots Tag</strong>
                                    <div className="position-relative">
                                        <pre>
                                            <code className="javascript">
                                                {codeSnippetJSX6}
                                            </code>
                                        </pre>
                                        <div className="copy_btn_right">
                                            <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippetJSX6)}>
                                                <img src="images/copy_icon.svg" />
                                                <span className="tooltiptext">Copy</span>
                                            </button>
                                        </div>
                                    </div>
                                    This tag instructs search engine bots on how to interact with your webpage. "Index" allows the page to be indexed in search results, while "follow" tells bots to follow links on the page.
                                </li>
                            </ol>
                            <h2 className="common_title">Conclusion</h2>
                            <p>
                                Meta tags are the unsung heroes of HTML, silently working behind the scenes to ensure your webpage is displayed correctly and optimized for search engines. By understanding their roles and using them effectively, you can enhance your website's usability and visibility, ultimately providing a better experience for your users.
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <HTMLSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default MetaRole;