import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';


const NotFound = () => {

    return (
        <>
            <Helmet>
                <title>Not Found - Weblession</title>
                <link rel="canonical" href="https://www.weblession.com/" />                
            </Helmet>
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="text-center">
                        <img src="images/404.webp" className="img-fluid" width="300" height="300" />
                        <h1 className="heading_title">Seems like you took a wrong turn!</h1>
                        <p>
                            Let's get you back on track
                        </p>
                        <Link to="/" className="common_btn">Go to Home</Link>
                    </div>
                </div>
            </section>
        </>
    )
}

export default NotFound;