const AllInterviewData = [
    {
        id: 1,
        menutitle:"HTML",
        url:"/html-interview",
    },
    {
        id: 2,
        menutitle:"CSS",
        url:"/css-interview",
    },
    {
        id: 3,
        menutitle:"React.js",
        url:"/react-interview",
    },
    {
        id: 4,
        menutitle:"PHP",
        url:"/php-interview",
    },
    {
        id: 5,
        menutitle:"jQuery",
        url:"/jQuery-interview",
    },
    {
        id: 6,
        menutitle:"Wordpress",
        url:"/wordpress-interview",
    },       
]

export default AllInterviewData;