import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import ReactSidebar from '../Pages/Sidebar';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";

const ErrorReloading = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const codeSnippet = `RewriteEngine On
RewriteBase /
RewriteCond %{REQUEST_FILENAME} !-f
RewriteCond %{REQUEST_FILENAME} !-d
RewriteRule ^(.*)$ /index.html [L]`;
    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    return (
        <>
            <HighLight />
            <Helmet>
                <title>How to Prevent a 404 error when reloading a page in a React.js - Weblession</title>
                <meta name="description" content="In a React.js application, preventing the reloading of a 404 error page using .htaccess. When you see a 404 error in a React SPA, it usually means that the client-side routing has failed to match a route, and the server is unaware of this since all routing is handled on the client side." />
                <meta name="Keywords" content="how to prevent reloading in react, how to stop reloading page in react js, reloading page in react, react app keeps reloading" />
                <link rel="canonical" href="https://www.weblession.com/prevent-404-reloading" />
                <meta name="og:type" property="og:type" content="article" />
                <meta name="og:url" property="og:url" content="https://www.weblession.com/prevent-404-reloading" />
                <meta name="og:title" property="og:title" content="How to Prevent a 404 error when reloading a page in a React.js" />
                <meta name="og:description" property="og:description" content="When you see a 404 error in a React SPA, it usually means that the client-side routing has failed to match a route, and the server is unaware of this since all routing is handled on the client side." />
                <meta name="og:image" property="og:image" content="images/latest_react.webp" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <InnerPagesBanner title="How to Prevent a 404 error when reloading a page in a React.js" description="In a React.js application, preventing the reloading of a 404 error page using .htaccess." url="Home" sec="React" active="Prevent a 404 error when reloading a page" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <p>
                                When you see a 404 error in a React SPA, it usually means that the client-side routing has failed to match a route, and the server is unaware of this since all routing is handled on the client side.
                            </p>
                            <p>
                                To address this issue and prevent 404 errors from showing when the page is reloaded or when a direct link is accessed, you should consider implementing server-side routing and handling of all routes in your React application. This can be achieved using React Router on the client side and configuring your server to serve the same HTML page for all routes.
                            </p>
                            <p>
                                Create a <strong>.htaccess</strong> file in your project's public directory to redirect all requests to your <strong>index.html</strong> file. This is the key step to handle client-side routing on the server side.
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                Ensure that your server is properly configured to use the <strong>.htaccess</strong> file for Apache or the appropriate configuration for your server if you're using a different web server.
                            </p>
                            <p>
                                Now, when you reload a page or access a direct link in your React application, the server will route all requests to your <strong>index.html</strong> file. React Router will then take over and handle the routing on the client side, ensuring that your routes work correctly.
                            </p>
                            <p>
                                Remember to adjust the routes and components in the example code to match your application's structure and requirements.
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <ReactSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default ErrorReloading;