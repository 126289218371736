import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import HTMLSidebar from "./HTMLSidebar";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";

const LockGrid = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const codeSnippet = `<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Fixed Grid</title>
        <style>
            body {
                margin: 0;
                overflow: hidden; /* Prevent scrollbars from appearing */
            }
    
            .grid-container {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                grid-template-rows: repeat(3, 1fr);
                position: fixed;
                top: 0;
                left: 0;
                width: 100vw;
                height: 100vh;
                background-color: lightgray; /* Optional: Add background color */
            }
    
            .grid-item {
                border: 1px solid black; /* Optional: Add borders to grid items */
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 1.5em;
            }
        </style>
    </head>
    <body>
        <div class="grid-container">
            <div class="grid-item">1</div>
            <div class="grid-item">2</div>
            <div class="grid-item">3</div>
            <div class="grid-item">4</div>
            <div class="grid-item">5</div>
            <div class="grid-item">6</div>
            <div class="grid-item">7</div>
            <div class="grid-item">8</div>
            <div class="grid-item">9</div>
        </div>
    </body>
    </html>`;
    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    return (
        <>
            <HighLight />
            <Helmet>
                <title>How can a 3x3 grid be fixed to the viewport irrespective of the content size in HTML - Weblession</title>
                <meta name="description" content="To lock a 3x3 grid to the viewport in HTML5, you can use CSS to create a fixed-position grid that stays in place even when the content size changes." />
                <meta name="Keywords" content="How to lock a 3x3 grid to the viewport regardless of content size in HTML, How to lock a 3x2 grid to the viewport regardless of content size in HTML, grid max-content, css grid size to content, css grid min-content vs max-content, html viewport shrink-to-fit, css grid column width max content, css grid column content width, css grid column width based on content" />
                <link rel="canonical" href="https://www.weblession.com/lock-grid-in-fullscreen" />
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3545356737183934" crossorigin="anonymous"></script>
                <script>
                    {
                        `(adsbygoogle = window.adsbygoogle || []).push({});`
                    }
                </script>
            </Helmet>
            <InnerPagesBanner title="How can a 3x3 grid be fixed to the viewport irrespective of the content size in HTML?" url="Home" sec="HTML/CSS" active="Lock Grid" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <p>
                                To lock a 3x3 grid to the viewport in HTML5, you can use CSS to create a fixed-position grid that stays in place even when the content size changes. Here's an example:
                            </p>
                            <ins class="adsbygoogle"
                                style={{ display: 'block' }}
                                data-ad-client="ca-pub-3545356737183934"
                                data-ad-slot="4530471982"
                                data-ad-format="auto"
                                data-full-width-responsive="true"></ins>
                            <div className="mt-2">
                                <div className="position-relative">
                                    <pre>
                                        <code className="javascript">
                                            {codeSnippet}
                                        </code>
                                    </pre>
                                    <div className="copy_btn_right">
                                        <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet)}>
                                            <img src="images/copy_icon.svg" />
                                            <span className="tooltiptext">Copy</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <p>
                                <strong>In this example:</strong>
                            </p>
                            <p>
                                The <strong>".grid-container"</strong> class is used to define the grid container and set its properties, such as display, grid-template-columns, grid-template-rows, position (fixed), and dimensions (width and height set to 100vw and 100vh, respectively).
                            </p>
                            <p>
                                The <strong>".grid-item"</strong> class is used to style each grid item. Feel free to tailor the visual presentation according to your requirements.
                            </p>
                            <p>
                                The body has margin set to 0 to remove default margin, and overflow is set to hidden to prevent scrollbars from appearing.
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <HTMLSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default LockGrid;