import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import HTMLSidebar from "./HTMLSidebar";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";

const SemanticHTML = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const codeSnippetJSX = `"<div>"`;
    const codeSnippetJSX2 = `<header>`;
    const codeSnippetJSX3 = `"<span>"`;
    const codeSnippetJSX4 = `<header>
    <h1>Your Website Name</h1>
    <nav>
        <ul>
            <li><a href="#">Home</a></li>
            <li><a href="#">About</a></li>
            <li><a href="#">Services</a></li>
        </ul>
    </nav>
</header>`;
    const codeSnippetJSX5 = `"<main>"`;
    const codeSnippetJSX6 = `<main>
    <article>
        <h2>Article Title</h2>
        <p>Article content goes here...</p>
    </article>
</main>`;
    const codeSnippetJSX7 = `"<section>"`;
    const codeSnippetJSX8 = `<section>
    <h2>Services</h2>
    <p>Explore our range of services...</p>
</section>`;
    const codeSnippetJSX9 = `"<aside>"`;
    const codeSnippetJSX10 = `<aside>
    <h3>Related Links</h3>
    <ul>
        <li><a href="#">Useful Resource 1</a></li>
        <li><a href="#">Useful Resource 2</a></li>
    </ul>
</aside>`;
    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    return (
        <>
            <HighLight />
            <Helmet>
                <title>Exploring Semantic Elements in HTML - Weblession</title>
                <meta name="description" content="When developing a website, utilizing semantic elements in HTML goes beyond aesthetics—it enhances accessibility, search engine optimization (SEO), and overall understanding of your content." />
                <meta name="Keywords" content="new semantic elements in html5, use of semantic elements in html5, why we use semantic elements in html5, list of semantic elements in html5, advantages of semantic elements in html5, what are semantic elements in html, all semantic elements in html, what is meant by semantic elements in html, semantic elements in html example, examples of semantic elements in html, what is semantic elements in html, html semantic layout example, html semantics best practices, list of semantic elements in html5" />
                <link rel="canonical" href="https://www.weblession.com/semantic-html" />
                <meta name="og:type" property="og:type" content="article" />
                <meta name="og:url" property="og:url" content="https://www.weblession.com/semantic-html" />
                <meta name="og:title" property="og:title" content="Exploring Semantic Elements in HTML" />
                <meta name="og:description" property="og:description" content="When developing a website, utilizing semantic elements in HTML goes beyond aesthetics—it enhances accessibility, search engine optimization (SEO), and overall understanding of your content." />
                <meta name="og:image" property="og:image" content="images/html_css.webp" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <InnerPagesBanner title="Exploring Semantic Elements in HTML" description="When developing a website, utilizing semantic elements in HTML goes beyond aesthetics—it enhances accessibility, search engine optimization (SEO), and overall understanding of your content." url="Home" sec="HTML" active="Semantic Elements" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <h2 className="common_title">Semantic Elements</h2>
                            <p>
                                Semantic elements in HTML are tags that convey a specific meaning to both machines (like search engines and screen readers) and humans. Unlike non-semantic elements like <strong>{codeSnippetJSX}</strong> and <strong>{codeSnippetJSX3}</strong>, which primarily serve for styling and layout purposes, semantic elements directly define the role and significance of content within the context of the page.
                            </p>
                            <h2 className="common_title">Benefits of Using Semantic Elements</h2>
                            <ol>
                                <li>
                                    <strong>Accessibility:</strong> Screen readers and assistive technologies rely on semantic markup to provide accurate information to visually impaired users. Proper semantic elements ensure that the content's structure and purpose are conveyed effectively.
                                </li>
                                <li>
                                    <strong>SEO:</strong> Search engines utilize semantic elements to understand the content hierarchy and relevance. Using appropriate semantic tags can positively impact your website's search engine rankings.
                                </li>
                                <li>
                                    <strong>Code Clarity:</strong> Semantic elements make your HTML code more self-explanatory. Developers can easily identify the purpose of each section, leading to cleaner and more maintainable
                                </li>
                            </ol>
                            <h2 className="common_title">Examples of Semantic Elements</h2>
                            <ol>
                                <li>
                                    <strong>{codeSnippetJSX2}</strong>: Defines the header section of a webpage, usually containing the site logo, navigation, and other introductory content.
                                    <div className="position-relative">
                                        <pre>
                                            <code className="javascript">
                                                {codeSnippetJSX4}
                                            </code>
                                        </pre>
                                        <div className="copy_btn_right">
                                            <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippetJSX4)}>
                                                <img src="images/copy_icon.svg" />
                                                <span className="tooltiptext">Copy</span>
                                            </button>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>{codeSnippetJSX5}</strong>: Represents the main content of a webpage, excluding headers and footers.
                                    <div className="position-relative">
                                        <pre>
                                            <code className="javascript">
                                                {codeSnippetJSX6}
                                            </code>
                                        </pre>
                                        <div className="copy_btn_right">
                                            <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippetJSX6)}>
                                                <img src="images/copy_icon.svg" />
                                                <span className="tooltiptext">Copy</span>
                                            </button>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>{codeSnippetJSX7}</strong>: Defines a thematic grouping of content, often with a heading.
                                    <div className="position-relative">
                                        <pre>
                                            <code className="javascript">
                                                {codeSnippetJSX8}
                                            </code>
                                        </pre>
                                        <div className="copy_btn_right">
                                            <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippetJSX8)}>
                                                <img src="images/copy_icon.svg" />
                                                <span className="tooltiptext">Copy</span>
                                            </button>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <strong>{codeSnippetJSX9}</strong>: Represents content that is tangentially related to the main content, such as sidebars or advertisements.
                                    <div className="position-relative">
                                        <pre>
                                            <code className="javascript">
                                                {codeSnippetJSX10}
                                            </code>
                                        </pre>
                                        <div className="copy_btn_right">
                                            <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippetJSX10)}>
                                                <img src="images/copy_icon.svg" />
                                                <span className="tooltiptext">Copy</span>
                                            </button>
                                        </div>
                                    </div>
                                </li>
                            </ol>
                            <h2 className="common_title">Conclusion</h2>
                            <p>
                                Semantic elements are essential tools in creating a well-structured and accessible web page. By providing meaning and context to different parts of the content, semantic elements enhance user experience, improve SEO, and promote maintainable code. Incorporate these elements into your HTML to build more meaningful and effective websites.
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <HTMLSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default SemanticHTML;