import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import JquerySidebar from "./JquerySidebar";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";

const AjaxJquery = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const codesnippet = `<script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>`;
    const codesnippet2 = `<!DOCTYPE html>
    <html>
    <head>
        <title>AJAX GET Request Example</title>
        <script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>
    </head>
    <body>
        <button id="fetchData">Fetch Data</button>
        <div id="result"></div>
    
        <script>
            $(document).ready(function () {
                $('#fetchData').click(function () {
                    $.ajax({
                        url: 'https://jsonplaceholder.typicode.com/posts/1',
                        method: 'GET',
                        success: function (data) {
                            $('#result').html(JSON.stringify(data));
                        },
                        error: function (error) {
                            console.error('Error:', error);
                        }
                    });
                });
            });
        </script>
    </body>
</html>`;
    const codesnippet3 = `"<div>"`;
    const codesnippet4 = `<!DOCTYPE html>
    <html>
    <head>
        <title>AJAX POST Request Example</title>
        <script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>
    </head>
    <body>
        <button id="sendData">Send Data</button>
        <div id="response"></div>
    
        <script>
            $(document).ready(function () {
                $('#sendData').click(function () {
                    var dataToSend = {
                        title: 'New Post',
                        body: 'This serves as the body of the new post.',
                        userId: 1
                    };
    
                    $.ajax({
                        url: 'https://jsonplaceholder.typicode.com/posts',
                        method: 'POST',
                        data: JSON.stringify(dataToSend),
                        contentType: 'application/json',
                        success: function (response) {
                            $('#response').html(JSON.stringify(response));
                        },
                        error: function (error) {
                            console.error('Error:', error);
                        }
                    });
                });
            });
        </script>
    </body>
</html>`;
    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    return (
        <>
        <HighLight />
            <Helmet>
                <title>How to Make an AJAX Request Using jQuery - Weblession</title>
                <link rel="canonical" href="https://www.weblession.com/ajax-request-using-jquery" />
                <meta name="og:type" property="og:type" content="article" />
                <meta name="og:url" property="og:url" content="https://www.weblession.com/ajax-request-using-jquery" />
                <meta name="og:title" property="og:title" content="How to Make an AJAX Request Using jQuery" />
                <meta name="og:description" property="og:description" content="AJAX (Asynchronous JavaScript and XML) allows you to send and receive data from a web server without needing to refresh the entire web page." />
                <meta name="og:image" property="og:image" content="images/latest_jquery.webp" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <InnerPagesBanner title="How to Make an AJAX Request Using jQuery" description="AJAX (Asynchronous JavaScript and XML) allows you to send and receive data from a web server without needing to refresh the entire web page." url="Home" sec="Jquery" active="AJAX Request Using jQuery" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <p>
                                jQuery simplifies the process of making AJAX requests, making it a popular choice for web developers. In this article, we'll explore how to make an AJAX request using jQuery with step-by-step code examples.
                            </p>
                            <h2 className="common_title">Prerequisites</h2>
                            <p>
                                Before we get started, ensure that you have jQuery included in your project. You can either download jQuery and host it locally or use a Content Delivery Network (CDN) to include it in your HTML file.
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codesnippet}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codesnippet)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <h2 className="common_title">Making a Simple AJAX GET Request</h2>
                            <p>
                                Let's start with a basic example of making an AJAX GET request to fetch data from a remote server.
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codesnippet2}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codesnippet2)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                In this example, we have a button with the ID fetchData and a <strong> {codesnippet3}</strong> element with the ID result. When you click the button, an AJAX GET request is made to the specified URL, and the response data is displayed in the result div.
                            </p>
                            <h2 className="common_title">Making AJAX POST Request</h2>
                            <p>
                                Now, let's see how to make an AJAX POST request to send data to a server.
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codesnippet4}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codesnippet4)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                In this example, when you click the <strong>"sendData"</strong> button, an AJAX POST request is made with the specified data, and the response from the server is displayed in the <strong>"response"</strong> div.
                            </p>
                            <h2 className="common_title">Conclusion</h2>
                            <p>
                                jQuery makes it straightforward to perform AJAX requests in your web applications. Whether you need to fetch data from a server or send data to it, these examples should provide you with a solid foundation for making AJAX requests using jQuery.
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <JquerySidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default AjaxJquery;