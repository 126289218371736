import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import PHPSidebar from "./PHPSidebar";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";

const FileUpload = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };

    const codeSnippet = `<!DOCTYPE html>
    <html>
    <head>
        <title>File Upload Example</title>
    </head>
    <body>
        <h1>Upload a File</h1>
        <form action="upload.php" method="post" enctype="multipart/form-data">
            <input type="file" name="fileToUpload" id="fileToUpload">
            <input type="submit" value="Upload File" name="submit">
        </form>
    </body>
</html>`;
    const codeSnippet2 = `<?php
$targetDirectory = "uploads/"; // Specify the directory where you want to store uploaded files.
$targetFile = $targetDirectory . basename($_FILES["fileToUpload"]["name"]);
$uploadOk = 1;
$fileType = strtolower(pathinfo($targetFile, PATHINFO_EXTENSION));

// Check if the file is a valid image (you can customize this part for different file types).
if(isset($_POST["submit"])) {
    $check = getimagesize($_FILES["fileToUpload"]["tmp_name"]);
    if($check !== false) {
        echo "File is an image - " . $check["mime"] . ".";
        $uploadOk = 1;
    } else {
        echo "File is not an image.";
        $uploadOk = 0;
    }
}

// Check if the file already exists.
if (file_exists($targetFile)) {
    echo "Sorry, the file already exists.";
    $uploadOk = 0;
}

// Check file size (you can customize the maximum size as needed).
if ($_FILES["fileToUpload"]["size"] > 500000) {
    echo "Sorry, your file is too large.";
    $uploadOk = 0;
}

// Allow only specific file types (you can customize this list).
$allowedExtensions = array("jpg", "jpeg", "png", "gif");
if (!in_array($fileType, $allowedExtensions)) {
    echo "Sorry, only JPG, JPEG, PNG, and GIF files are allowed.";
    $uploadOk = 0;
}

// If everything is okay, try to upload the file.
if ($uploadOk == 0) {
    echo "Sorry, your file was not uploaded.";
} else {
    if (move_uploaded_file($_FILES["fileToUpload"]["tmp_name"], $targetFile)) {
        echo "The file " . basename($_FILES["fileToUpload"]["name"]) . " has been uploaded.";
    } else {
        echo "Apologies, we encountered an issue while uploading your file.";
    }
}
?>`;
    return (
        <>
        <HighLight />
            <Helmet>
                <title>How to file upload in PHP - Weblession</title>
                <meta name="description" content="Adding file upload functionality to your PHP website can greatly enhance its interactivity and utility. Whether you want to enable users to upload images..." />
                <meta name="Keywords" content="how to upload file in php and mysql, how to upload file in html and php, php file upload example, how to get upload file in php, php file upload example, how to validate file upload in php, how to upload file in php and store in database, php how to upload a file, upload file php script, how to upload large file in php" />
                <link rel="canonical" href="https://www.weblession.com/file-uploadin-php" />
                <meta name="og:type" property="og:type" content="article" />
                <meta name="og:url" property="og:url" content="https://www.weblession.com/file-uploadin-php" />
                <meta name="og:title" property="og:title" content="How to file upload in PHP" />
                <meta name="og:description" property="og:description" content="Adding file upload functionality to your PHP website can greatly enhance its interactivity and utility. Whether you want to enable users to upload images..." />
                <meta name="og:image" property="og:image" content="images/latest_php.webp" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <InnerPagesBanner title="How to file upload in PHP" description="Adding file upload functionality to your PHP website can greatly enhance its interactivity and utility. Whether you want to enable users to upload images, documents, or any other file type, PHP provides a straightforward way to achieve this. In this tutorial, we'll walk you through the process of creating a simple file upload feature in your PHP script." url="Home" sec="PHP" active="How to upload file in PHP" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <h2 className="common_title">Step 1: HTML Form</h2>
                            <p>
                                Start by creating an HTML form that allows users to select and upload a file. Here's a basic example:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                In this form, the <strong>enctype="multipart/form-data"</strong> attribute is crucial for handling file uploads.
                            </p>
                            <h2 className="common_title">Step 2: PHP Script</h2>
                            <p>
                                Create a PHP script (in this example, named <strong>upload.php</strong>) that handles the uploaded file. Here's a simple PHP script to get you started:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet2}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet2)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                This PHP script performs several checks, including file type, size, and whether the file already exists. Adjust these checks according to your specific requirements.
                            </p>
                            <h2 className="common_title">Step 3: Handling Uploads</h2>
                            <p>
                                Create a directory named "uploads" in the same directory as your PHP script to store uploaded files. Ensure that the directory has appropriate permissions for file uploads to work.
                            </p>
                            <h2 className="common_title">Conclusion</h2>
                            <p>
                                With this step-by-step guide, you've learned how to create a file upload feature in a PHP script. This functionality can be a valuable addition to your website, enabling users to share images, documents, or any other file types. Customize the script and form to suit your website's needs and user requirements.
                            </p>
                            <p>
                                Remember to implement proper security measures, such as file type validation and user authentication, to ensure a secure and reliable file upload system.
                            </p>
                            <p>
                                Feel free to enhance and adapt this example to your website's design and functionality needs.
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <PHPSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default FileUpload;