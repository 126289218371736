import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import PHPSidebar from "./PHPSidebar";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";

const LoaderJquery = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const codesnippet = `composer require livewire/livewire`;
    const codesnippet2 = `// app/Http/Livewire/Posts.php

    namespace App\Http\Livewire;
    
    use Livewire\Component;
    use App\Models\Post;
    
    class Posts extends Component
    {
        public $posts;
    
        public function mount()
        {
            $this->posts = Post::paginate(10);
        }
    
        public function render()
        {
            return view('livewire.posts', ['posts' => $this->posts]);
        }
    }`;
    const codesnippet3 = `<!-- resources/views/livewire/posts.blade.php -->

    <div>
        <!-- Display Posts -->
        <div>
            @foreach ($posts as $post)
                <!-- Render each post -->
            @endforeach
        </div>
    
        <!-- Loader Animation -->
        @if ($posts->count() < $posts->total())
            <div id="loader" class="loader"></div>
        @endif
    </div>
    
    @push('scripts')
        <script>
            document.addEventListener("livewire:load", function () {
                Livewire.hook('beforeDomUpdate', () => {
                    $('#loader').show(); // Show loader before Livewire update
                });
    
                Livewire.hook('afterDomUpdate', () => {
                    $('#loader').hide(); // Hide loader after Livewire update
                });
            });
        </script>
    @endpush`;
    const codesnippet4 = `<!-- resources/views/livewire/posts.blade.php -->

    @push('scripts')
        <script>
            document.addEventListener("livewire:load", function () {
                Livewire.hook('beforeDomUpdate', () => {
                    $('#loader').show(); // Show loader before Livewire update
                });
    
                Livewire.hook('afterDomUpdate', () => {
                    $('#loader').hide(); // Hide loader after Livewire update
                });
    
                // Smooth scroll to top after Livewire update
                Livewire.hook('afterDomUpdate', () => {
                    $('html, body').animate({ scrollTop: 0 }, 'slow');
                });
            });
        </script>
    @endpush`;

    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    return (
        <>
            <HighLight />
            <Helmet>
                <title>How to solve loaders & jquery is not working in the livewire pagination - Weblession</title>
                <meta name="description" content="Livewire stands as a robust framework tailored for crafting dynamic interfaces within Laravel applications..." />
                <meta name="Keywords" content="loaders & jquery is not working in the livewire pagination, livewire pagination custom, livewire loading not working, livewire loading spinner, livewire loading animation, laravel livewire loading, how to solve loading issue in livewire pagination" />
                <link rel="canonical" href="https://www.weblession.com/how-to-solve-loaders-jquery-is-not-working-in-livewire-pagination" />
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3545356737183934"
                    crossorigin="anonymous"></script>
                <script>
                    {
                        `(adsbygoogle = window.adsbygoogle || []).push({});`
                    }
                </script>
            </Helmet>
            <InnerPagesBanner title="How to solve loaders & jquery is not working in the livewire pagination" description="Enhancing Livewire Pagination with Loaders and jQuery Integration" url="Home" sec="PHP Laravel" active="Loader & Jquery in livewire" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <p>
                                Livewire stands as a robust framework tailored for crafting dynamic interfaces within Laravel applications. One common challenge developers face is integrating loaders and jQuery functionality seamlessly into Livewire pagination. In this tutorial, we'll explore how to achieve this integration to enhance user experience while navigating through paginated content.
                            </p>
                            <h2 className="common_title">Step 1: Set Up Livewire Pagination</h2>
                            <p>
                                First, ensure you have Livewire installed in your Laravel project. If Livewire is not yet installed in your Laravel project, you can easily add it using Composer:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codesnippet}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codesnippet)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                Next, set up Livewire pagination in your Livewire component. Suppose we have a simple Livewire component named Posts. Within this component, we'll paginate a collection of <strong>"posts"</strong> fetched from the database.
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codesnippet2}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codesnippet2)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <h2 className="common_title">Step 2: Add Loader Animation</h2>
                            <p>
                                To provide feedback to users while pagination is loading, integrate a loader animation into your Livewire component's Blade view.
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codesnippet3}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codesnippet3)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <h2 className="common_title">Step 3: Integrate jQuery for Smooth Pagination</h2>
                            <p>
                                In some cases, Livewire pagination might not handle certain jQuery functionalities seamlessly. We can integrate jQuery to enhance pagination smoothness.
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codesnippet4}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codesnippet4)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                Conclusion:
                            </p>
                            <p>
                                By integrating loaders and jQuery functionality into Livewire pagination, we can enhance user experience and provide smoother navigation through paginated content in Laravel applications. This approach ensures that users receive immediate feedback during pagination operations and enjoy a seamless browsing experience.
                            </p>
                            <div className="mt-2 mb-3">
                                <ins class="adsbygoogle"
                                    style={{ display: 'block' }}
                                    data-ad-client="ca-pub-3545356737183934"
                                    data-ad-slot="8129949411"
                                    data-ad-format="auto"
                                    data-full-width-responsive="true"></ins>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <PHPSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default LoaderJquery;