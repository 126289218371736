import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";
import PythonSidebar from "./Sidebar";

const PolymorphismPython = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const codeSnippet = `# Polymorphism in Python

    # Base class
    class Animal:
        def speak(self):
            pass
    
    # Derived class 1
    class Dog(Animal):
        def speak(self):
            return "Woof!"
    
    # Derived class 2
    class Cat(Animal):
        def speak(self):
            return "Meow!"
    
    # Function demonstrating polymorphism
    def animal_sound(animal):
        return animal.speak()
    
    # Creating instances of the classes
    dog_instance = Dog()
    cat_instance = Cat()
    
    # Using the function with different objects
    print("Dog says:", animal_sound(dog_instance))
    print("Cat says:", animal_sound(cat_instance))`;

    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    return (
        <>
            <HighLight />
            <Helmet>
                <title>Polymorphism in python - Weblession</title>
                <meta name="description" content="Polymorphism is a concept in object-oriented programming that enables objects of distinct classes to be handled and manipulated as instances of a shared or common base class. This paradigm promotes flexibility in code design by allowing a single interface (methods or properties) to be implemented by multiple classes, facilitating interchangeability and extensibility in the overall system architecture." />
                <meta name="Keywords" content="polymorphism in python, inheritance and polymorphism in python, runtime polymorphism in python, compile time polymorphism in python, polymorphism in python example, polymorphism python attributes, advantages of polymorphism in python" />
                <link rel="canonical" href="https://www.weblession.com/polymorphism-in-python" />

                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3545356737183934"
                    crossorigin="anonymous"></script>
                <script>
                    {
                        `(adsbygoogle = window.adsbygoogle || []).push({ });`
                    }
                </script>
            </Helmet>
            <InnerPagesBanner title="Polymorphism in python" description="Polymorphism is a concept in object-oriented programming that enables objects of distinct classes to be handled and manipulated as instances of a shared or common base class." url="Home" sec="Python" active="Polymorphism in python" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <div className="mb-3">
                                {/* Json web Token */}
                                <ins className="adsbygoogle"
                                    style={{ display: 'block' }}
                                    data-ad-client="ca-pub-3545356737183934"
                                    data-ad-slot="5398141269"
                                    data-ad-format="auto"
                                    data-full-width-responsive="true"></ins>
                            </div>
                            <p>
                                This paradigm promotes flexibility in code design by allowing a single interface (methods or properties) to be implemented by multiple classes, facilitating interchangeability and extensibility in the overall system architecture.
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                <strong>In this example:</strong>
                            </p>
                            <ol>
                                <li>
                                    We have a base class <strong>Animal</strong> with a method <strong>speak</strong> that is overridden by the derived classes.
                                </li>
                                <li>
                                    The <strong>Dog</strong> class and <strong>Cat</strong> class both inherit from the <strong>Animal</strong> class and provide their own implementation of the <strong>speak</strong> method.
                                </li>
                                <li>
                                    The <strong>animal_sound</strong> function takes an object of type <strong>Animal</strong> as a parameter and calls its <strong>speak</strong> method. This function demonstrates polymorphism because it can work with objects of different classes as long as they are derived from the <strong>Animal</strong> class.
                                </li>
                                <li>
                                    We create instances of the <strong>Dog</strong> and <strong>Cat</strong> classes and use the <strong>animal_sound</strong> function to get their respective sounds.
                                </li>
                            </ol>
                            <p>
                                Polymorphism in Python allows for more flexible and extensible code, as you can write functions and methods that work with a common interface (base class) and can be used with various derived classes without modification.
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <PythonSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default PolymorphismPython;