const ReactSidebarDat = [
    {
        id: 1,
        menutitle:"What is React?",
        url:"/what-is-react",
    },
    {
        id: 2,
        menutitle:"How to install React.js application?",
        url:"/react-install",
    },
    {
        id: 3,
        menutitle:"Let's Explain the concept of Virtual DOM in React.",
        url:"/virtual-dom",
    },
    {
        id: 4,
        menutitle:"Learn Create React component",
        url:"/create-react-component",
    },
    {
        id: 5,
        menutitle:"Easy learn props in React",
        url:"/props-react",
    },
    {
        id: 6,
        menutitle:"What is state in React?",
        url:"/what-is-state-in-react",
    },
    {
        id: 7,
        menutitle:"useEffect Hook",
        url:"/useEffect-hook",
    },
    {
        id: 8,
        menutitle:"What is JSX?",
        url:"/what-is-JSX",
    },
    {
        id: 9,
        menutitle:"How to install Bootstrap in React.js?",
        url:"/how-to-install-bootstrap-in-react",
    }, 
    {
        id: 10,
        menutitle:"API call in React.js",
        url:"/api-call-in-react",
    },
    {
        id: 11,
        menutitle:"How to Prevent a 404 error when reloading a page in a React.js?",
        url:"/prevent-404-reloading",
    },
    {
        id: 12,
        menutitle:"How can I control render blocking in an HTML and React.js application?",
        url:"/how-to-control-render-blocking-in-an-HTML-reactjs-application",
    },
    {
        id: 13,
        menutitle:"Boost Your Website Page Speed: A Comprehensive Guide",
        url:"/boost-your-website-page-speed-a-comprehensive-guide",
    },
    {
        id: 14,
        menutitle:"How to create dynamic meta tags for every page in a React.js application?",
        url:"/how-to-create-dynamic-meta-tags-for-every-page-in-a-Reactjs-application",
    },
    {
        id: 15,
        menutitle:"How can i show load more card on react.js application?",
        url:"/how-to-show-load-more-card-on-reactjs-application",
    },
    {
        id: 16,
        menutitle:"Learn text to speech in react.js application",
        url:"/learn-text-to-speech-in-reactjs-application",
    },
    {
        id: 17,
        menutitle:"How to place manually Google AdSense ads in react.js?",
        url:"/how-to-place-manually-google-adSense-ads-in-reactjs",
    },
    {
        id: 18,
        menutitle:"Is it possible to use a custom hook inside useeffect in react (reactjs, react hooks, development)?",
        url:"/is-it-possible-to-use-a-custom-hook-inside-useeffect-in-react",
    },
    {
        id: 19,
        menutitle:"How to Perform Debounce in React.js?",
        url:"/how-to-perform-debounce-in-reactjs",
    },
    {
        id: 20,
        menutitle:"Identify the smallest building block of react.js",
        url:"/smallest-block-in-reactjs",
    },
    {
        id: 21,
        menutitle:"How can Azure Key Vault secrets be utilized in a React App?",
        url:"/how-can-azure-key-vault-secrets-utilized-in-react-app",
    },
]

export default ReactSidebarDat;