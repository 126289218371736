import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import JquerySidebar from "./JquerySidebar";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";

const FadeOutJquery = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const codesnippet = `<!DOCTYPE html>
    <html>
    <head>
        <title>Fading Out an Element in jQuery</title>
        <script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>
        <style>
            #fadeButton {
                padding: 10px;
                background-color: #007bff;
                color: #fff;
                cursor: pointer;
            }
        </style>
    </head>
    <body>
        <button id="fadeButton">Fade Out</button>
    
        <script>
            $(document).ready(function () {
                $('#fadeButton').click(function () {
                    // Fade out the button with a duration of 1000 milliseconds (1 second)
                    $(this).fadeOut(1000);
                });
            });
        </script>
    </body>
</html>`;
    const codesnippet2 = `<!DOCTYPE html>
<html>
<head>
    <title>Fading Out with Callback in jQuery</title>
    <script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>
    <style>
        #fadeDiv {
            width: 200px;
            height: 200px;
            background-color: #f00;
        }
    </style>
</head>
<body>
    <div id="fadeDiv"></div>

    <script>
        $(document).ready(function () {
            $('#fadeDiv').click(function () {
                // Fade out the div with a duration of 1500 milliseconds (1.5 seconds)
                // After fading out, display an alert
                $(this).fadeOut(1500, function () {
                    alert('Div has been faded out!');
                });
            });
        });
    </script>
</body>
</html>`;
    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    return (
        <>
        <HighLight />
            <Helmet>
                <title>Fading Out an Element in jQuery - Weblession</title>
                <link rel="canonical" href="https://www.weblession.com/fade-out-in-jquery" />
                <meta name="og:type" property="og:type" content="article" />
                <meta name="og:url" property="og:url" content="https://www.weblession.com/fade-out-in-jquery" />
                <meta name="og:title" property="og:title" content="Fading Out an Element in jQuery" />
                <meta name="og:description" property="og:description" content="Fading out an element is a common user interface effect used to gradually hide an element from view. jQuery provides a straightforward..." />
                <meta name="og:image" property="og:image" content="images/latest_jquery.webp" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <InnerPagesBanner title="Fading Out an Element in jQuery" description="Fading out an element is a common user interface effect used to gradually hide an element from view. jQuery provides a straightforward way to achieve this effect using the .fadeOut() method. In this article, we'll explore how to fade out an element using jQuery with step-by-step code examples." url="Home" sec="Jquery" active="fadeOut in jQuery" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <h2 className="common_title">Using the .fadeOut() Method</h2>
                            <p>
                                The .fadeOut() method allows you to smoothly hide an element by gradually reducing its opacity until it becomes invisible. Here's how you can use it:
                            </p>
                            <h2 className="common_title">Example 1: Fading Out a Button</h2>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codesnippet}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codesnippet)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                In this example:
                            </p>
                            <ol>
                                <li>
                                    We include jQuery and define a button with the ID <strong>"fadeButton"</strong>.
                                </li>
                                <li>
                                    When the button is clicked, we use the <strong>.fadeOut()</strong> method to gradually fade out the button over a duration of 1000 milliseconds (1 second).
                                </li>
                                <li>
                                    The button will smoothly fade out, becoming invisible when the animation is complete.
                                </li>
                            </ol>
                            <h2 className="common_title">Example 2: Fading Out a Div with Callback</h2>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codesnippet2}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codesnippet2)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                In this example
                            </p>
                            <ol>
                                <li>
                                    We have a <strong>"div"</strong> element with the ID <strong>"fadeDiv"</strong>.
                                </li>
                                <li>
                                    When the <strong>"div"</strong> is clicked, we use the <strong>".fadeOut()"</strong> method with a duration of 1500 milliseconds (1.5 seconds).
                                </li>
                                <li>
                                    We provide a callback function that displays an alert when the fading out animation is complete.
                                </li>
                            </ol>
                            <h2 className="common_title">Conclusion</h2>
                            <p>
                                Fading out elements using jQuery's <strong>".fadeOut()"</strong> method is a simple yet effective way to add visual transitions to your web pages. Whether you want to hide buttons, divs, or other HTML elements, this technique can enhance the user experience by making elements gradually disappear. Experiment with different durations and effects to achieve the desired visual impact in your web applications.
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <JquerySidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default FadeOutJquery;