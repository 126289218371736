import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";
import { Link } from "react-router-dom";
import ReactSidebar from "../Pages/Sidebar";

const TextToSpeech = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    const codeSnippet = `npm install react react-dom`;
    const codeSnippet2 = `import React, { useState } from 'react';

    const TextToSpeech = () => {
      const [text, setText] = useState('');
      const synth = window.speechSynthesis;
    
      const handleInputChange = (e) => {
        setText(e.target.value);
      };
    
      const handleSpeak = () => {
        const utterance = new SpeechSynthesisUtterance(text);
        synth.speak(utterance);
      };
    
      return (
        <div>
          <h1>Text to Speech in React</h1>
          <textarea
            placeholder="Enter text to speak"
            value={text}
            onChange={handleInputChange}
          />
          <button onClick={handleSpeak}>Speak</button>
        </div>
      );
    };
    
    export default TextToSpeech;`;
    const codeSnippet3 = `import React from 'react';
    import './App.css';
    import TextToSpeech from './TextToSpeech';
    
    function App() {
      return (
        <div className="App">
          <TextToSpeech />
        </div>
      );
    }
    
    export default App;`;
    const codeSnippet4 = `npm start`;
    return (
        <>
            <HighLight />
            <Helmet>
                <title>Learn text to speech in react.js application - Weblession</title>
                <meta name="description" content="Before you begin, make sure you have React.js set up in your project. You can create a new React app using Create React App or another method of your choice." />
                <meta name="Keywords" content="how to convert text to speech in react js, speech to text using react js, react text to speech example, text to speech converter code in javascript, text to speech not working, how to get text read to you, how to convert text to speech in javascript, text to speech javascript example, javascript text to speech api" />
                <link rel="canonical" href="https://www.weblession.com/learn-text-to-speech-in-reactjs-application" />
                <meta name="og:type" property="og:type" content="article" />
                <meta name="og:url" property="og:url" content="https://www.weblession.com/learn-text-to-speech-in-reactjs-application" />
                <meta name="og:title" property="og:title" content="Learn text to speech in react.js application" />
                <meta name="og:description" property="og:description" content="Before you begin, make sure you have React.js set up in your project. You can create a new React app using Create React App or another method of your choice." />
                <meta name="og:image" property="og:image" content="images/latest_html.webp" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <InnerPagesBanner title="Learn text to speech in React.js application" description="Before you begin, make sure you have React.js set up in your project. You can create a new React app using Create React App or another method of your choice." url="Home" sec="React" active="Learn text to speech in React.js" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <h2 className="common_title">Here's a simple implementation</h2>
                            <p>
                                First, install the necessary dependencies
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                            Develop a new React component to implement the <strong>"Text to Speech"</strong> functionality.. You can name it something like <strong>TextToSpeech.js</strong>
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet2}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet2)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                Now, you can use the <strong>TextToSpeech</strong> component in your main <strong>App.js</strong> file or any other component.
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet3}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet3)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                Style your components by modifying the CSS in your <strong>App.css</strong> file or by using a styling library like Bootstrap.
                            </p>
                            <h2 className="common_title">Run your React app</h2>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet4}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet4)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                This is a basic implementation of text-to-speech in React using the browser's built-in capabilities. Depending on your specific requirements, you may want to enhance this by providing more options for voice selection, rate, pitch, or handling different text inputs. Additionally, you might consider adding error handling for cases where the Speech Synthesis API is not available in some browsers.
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <ReactSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default TextToSpeech;