import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";
import AngularSidebar from "../Pages/AngularSidebar";

const AngularDirective = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    const codeSnippet = `<!DOCTYPE html>
    <html ng-app="tooltipApp">
    <head>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/angular-ui-bootstrap/2.5.6/ui-bootstrap-csp.css" />
        <script src="https://ajax.googleapis.com/ajax/libs/angularjs/1.8.2/angular.min.js"></script>
        <script src="https://cdnjs.cloudflare.com/ajax/libs/angular-ui-bootstrap/2.5.6/ui-bootstrap-tpls.js"></script>
    </head>
    <body>
        <div ng-controller="TooltipController">
            <button type="button" uib-tooltip="This is a tooltip!" tooltip-placement="top">
                Hover over me!
            </button>
        </div>
        
        <script>
            // 2. **JavaScript:**
            var app = angular.module('tooltipApp', ['ui.bootstrap']);
    
            app.controller('TooltipController', function($scope) {
                // Controller logic can be added here if needed
            });
        </script>
    </body>
    </html>`;
    return (
        <>
            <HighLight />
            <Helmet>
                <title>Angular.js directive not working with uib-tooltip - Weblession</title>
                <meta name="description" content="In AngularJS, the UI Bootstrap library (ui-bootstrap) provides several useful directives for building rich web applications. One of these directives is uib-tooltip, which allows you to easily add tooltips to elements in your AngularJS application." />
                <meta name="Keywords" content="angular.js directive not working with uib-tooltip, How to solve angular.js directive for uib-tooltip, uib tooltip html, angularjs uib-popover, angularjs ui bootstrap, angularjs directive not working, uib-tooltip angularjs, angularjs material tooltip" />
                <link rel="canonical" href="https://www.weblession.com/angularjs-directive-not-working-with-uib-tooltip" />
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3545356737183934"
                    crossorigin="anonymous"></script>
                <script>
                    {
                        `(adsbygoogle = window.adsbygoogle || []).push({});`
                    }
                </script>
            </Helmet>
            <InnerPagesBanner title="Angular.js directive not working with uib-tooltip" description="In AngularJS, the UI Bootstrap library (ui-bootstrap) provides several useful directives for building rich web applications." url="Home" sec="Angular.js" active="Angular.js directive" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <p>
                                One of these directives is <strong>uib-tooltip</strong>, which allows you to easily add tooltips to elements in your AngularJS application.
                            </p>
                            <p>
                                If you encounter an issue where an <strong>Angular.js</strong> directive does not work for <strong>uib-tooltip</strong>, there could be several reasons for this. Here is a list of potential problems and how to resolve them:
                            </p>
                            <ol>
                                <li>
                                    <strong>Missing UI Bootstrap Library</strong>: Make sure you have included the Angular UI Bootstrap library in your project. This library provides the <strong>uib-tooltip</strong> directive.
                                </li>
                                <li>
                                    <strong>Incorrect Angular Version</strong>: UI Bootstrap is compatible with AngularJS (Angular 1.x), but it may not work with newer versions of Angular (Angular 2+). Make sure you have the compatible versions of AngularJS and UI Bootstrap.
                                </li>
                                <li>
                                    <strong>Directive Initialization</strong>: Check that you have initialized the directive correctly, including importing the necessary module and injecting the dependency in your AngularJS application.
                                </li>
                                <li>
                                    <strong>Tooltip Placement</strong>: Check the placement option of the tooltip. If you're using <strong>uib-tooltip</strong>, you can specify placement like <strong>top, bottom, left, or right.</strong>
                                </li>
                                <li>
                                    <strong>HTML Sanitization</strong>: If you have HTML content in your tooltip, it might be blocked by AngularJS's strict context security policies. Use the ng-bind-html directive and make sure to include the necessary AngularJS modules.
                                </li>
                                <li>
                                    <strong>Compatibility Issues</strong>: Make sure your version of AngularJS, UI Bootstrap, and other dependencies are compatible.
                                </li>
                                <li>
                                    <strong>Directive Attribute Name</strong>: Ensure you are using the correct attribute name <strong>uib-tooltip</strong> (not tooltip or title).
                                </li>
                            </ol>
                            <p>
                                Let's look at a code example that demonstrates how to use <strong>uib-tooltip</strong> correctly:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                In the example above:
                            </p>
                            <ul>
                                <li>
                                    <strong>HTML:</strong> We include the AngularJS library and the UI Bootstrap library.
                                </li>
                                <li>
                                    We define a module <strong>tooltipApp</strong> and inject the <strong>ui.bootstrap</strong> dependency.
                                </li>
                                <li>
                                    The <strong>TooltipController</strong> is defined as a controller, though it's not necessary in this simple example.
                                </li>
                                <li>
                                    In the HTML body, a button is created with the <strong>uib-tooltip</strong> directive, specifying the tooltip message, and <strong>tooltip-placement</strong> set to "top" for the tooltip to appear above the button when hovered over.
                                </li>
                            </ul>
                            <p>
                                If you're still facing issues, verify that the libraries and versions are compatible, and check the browser console for any errors. This will help you further troubleshoot the issue.
                            </p>
                            <div className="mt-3">                                
                                <ins class="adsbygoogle"
                                    style={{ display: 'block' }}
                                    data-ad-client="ca-pub-3545356737183934"
                                    data-ad-slot="3121889067"
                                    data-ad-format="auto"
                                    data-full-width-responsive="true"></ins>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <AngularSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default AngularDirective;