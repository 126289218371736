import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import PHPSidebar from "./PHPSidebar";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";

const SetRetrieve = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const codesnippet = `<?php
    // Establish a cookie with a one-hour expiration period.
    setcookie("username", "JohnDoe", time() + 3600, "/");
?>`;
    const codesnippet2 = `<?php
    // Retrieve the value of the "username" cookie
    $username = $_COOKIE["username"];
    echo "Welcome back, $username!";
?>`;
    const codesnippet3 = `<?php
    // Delete the "username" cookie
    setcookie("username", "", time() - 3600, "/");
?>`;

    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    return (
        <>
        <HighLight />
            <Helmet>
                <title>Cookie Handling in PHP - Weblession</title>
                <meta name="description" content="Cookies are a fundamental part of web development, enabling you to store small pieces of data on a user's device." />
                <meta name="Keywords" content="cookies in php with example, what is the use of cookies in php, php cookie functions, handling cookies in php, php cookie vs session" />
                <link rel="canonical" href="https://www.weblession.com/set-retrieve-cookies" />
                <meta name="og:type" property="og:type" content="article" />
                <meta name="og:url" property="og:url" content="https://www.weblession.com/set-retrieve-cookies" />
                <meta name="og:title" property="og:title" content="Cookie Handling in PHP" />
                <meta name="og:description" property="og:description" content="Cookies are a fundamental part of web development, enabling you to store small pieces of data on a user's device." />
                <meta name="og:image" property="og:image" content="images/latest_php.webp" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <InnerPagesBanner title="Cookie Handling in PHP" description="Cookies are a fundamental part of web development, enabling you to store small pieces of data on a user's device." url="Home" sec="PHP" active="Cookie in PHP" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <p>
                                PHP provides a straightforward way to work with cookies, making it possible to remember user preferences, track sessions, and personalize web experiences. In this guide, we'll explore how to set and retrieve cookies using PHP, providing you with practical examples along the way.
                            </p>
                            <h2 className="common_title">Setting Cookies in PHP</h2>
                            <p>
                                To create a cookie in PHP, you employ the <strong>"setcookie()"</strong> function. This function allows you to specify various parameters, including the cookie name, value, expiration time, and more.
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codesnippet}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codesnippet)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                In the example above, we set a cookie named "username" with the value "JohnDoe" that will expire in one hour (3600 seconds) and is available across the entire domain ("/").
                            </p>
                            <h2 className="common_title">Retrieving Cookies in PHP</h2>
                            <p>
                                To retrieve cookies in PHP, you can use the <strong>"$_COOKIE"</strong> superglobal array. This array contains all the cookies sent by the client to the server.
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codesnippet2}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codesnippet2)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                In this code snippet, we retrieve the value of the "username" cookie and use it to personalize a welcome message for the user.
                            </p>
                            <h2 className="common_title">Cookie Expiration and Path</h2>
                            <ol>
                                <li>
                                    Expiration Time: You can set the expiration time of a cookie by adjusting the timestamp passed to the <strong>"setcookie()"</strong> function. A timestamp of <strong>"time() + X"</strong> will make the cookie expire in X seconds from the current time.
                                </li>
                                <li>
                                    Cookie Path: The fourth parameter of <strong>"setcookie()"</strong> allows you to specify the path within your domain where the cookie is available. "/" makes the cookie accessible throughout the entire domain, while a specific path restricts its scope.
                                </li>
                            </ol>
                            <h2 className="common_title">Deleting Cookies</h2>
                            <p>
                                To remove a cookie, you can adjust its expiration time to a date in the past. This action effectively erases the cookie from the user's device.
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codesnippet3}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codesnippet3)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                In this example, we set the "username" cookie's expiration time to a past date, effectively deleting it.
                            </p>
                            <h2 className="common_title">Conclusion</h2>
                            <p>
                                Cookies play a crucial role in web development by allowing you to store and retrieve data on a user's device. With PHP's <strong>"setcookie()"</strong> function and the <strong>"$_COOKIE"</strong> superglobal array, you can easily implement features like user authentication, remember-me functionality, and personalized user experiences. Understanding how to set, retrieve, and manage cookies is a valuable skill for building dynamic and user-friendly websites.
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <PHPSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default SetRetrieve;