const JquerySidebarData = [
    {
        id: 1,
        menutitle:"Difference between .html() and .text() methods in jQuery",
        url:"/difference-html-text-method",
    },
    {
        id: 2,
        menutitle:"How can you make an AJAX request using jQuery?",
        url:"/ajax-request-using-jquery",
    },
    {
        id: 3,
        menutitle:"What is chaining in jQuery?",
        url:"/chaining-in-jquery",
    },
    {
        id: 4,
        menutitle:"The purpose of the noConflict() method in jQuery",
        url:"/noconflict-method",
    },    
    {
        id: 5,
        menutitle:"Fade out an element using jQuery",
        url:"/fade-out-in-jquery",
    },
    {
        id: 6,
        menutitle:"Fade out an element using jQuery",
        url:"/fade-out-in-jquery",
    },
    {
        id: 7,
        menutitle:"How to append textarea if span have data action in jQuery",
        url:"/how-to-append-textarea-if-span-have-data-action",
    },
]

export default JquerySidebarData;