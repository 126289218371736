import React from 'react';
import '../css/InnerPagesBanner.css';
import { Link } from 'react-router-dom';

const InnerPagesBanner = (props) => {
    return (
        <>
            <section id="common_inner_banner" className="common_inner_banner">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-sm-7">
                            <h1 className="heading_title text-white mb-2">{props.title}</h1>
                            <p>
                                {props.description}
                            </p>
                        </div>
                        <div className="col-sm-5">
                            <nav aria-label="breadcrumb" className='breadcurum_right'>
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><Link to="/">{props.url}</Link></li>
                                    <li className="breadcrumb-item"><Link to={props.secURL}>{props.sec}</Link></li>
                                    <li className="breadcrumb-item active" aria-current="page">{props.active}</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default InnerPagesBanner;