import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";
import PHPSidebar from "./PHPSidebar";
import { Link } from "react-router-dom";

const PageDecrease = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    const codeSnippet = `<!DOCTYPE html><html><head><title>My Website</title></head><body><h1>Welcome to My Website</h1><p>This is a sample page.</p></body></html>`;
    const codeSnippet2 = `<?php
    $expire = 60 * 60 * 24 * 7; // Cache for 1 week
    header("Pragma: public");
    header("Cache-Control: max-age=" . $expire);
    header('Expires: ' . gmdate('D, d M Y H:i:s', time() + $expire) . ' GMT');
    ?>`;
    const codeSnippet3 = `<IfModule mod_deflate.c>
    AddOutputFilterByType DEFLATE text/html text/plain text/xml text/css text/javascript application/javascript
</IfModule>`;
    const codeSnippet4 = `<script async src="script.js"></script>`;
    return (
        <>
            <HighLight />
            <Helmet>
                <title>How to decrease page size in HTML and PHP - Weblession</title>
                <meta name="description" content="In today's fast-paced digital world, website performance is crucial for retaining visitors and ranking well in search engines." />
                <meta name="Keywords" content="how to decrease html page size, how to decrease size of web page, how to reduce the page size in html, how to reduce html page size, how to reduce web page size, how to decrease page size in html and php" />
                <link rel="canonical" href="https://www.weblession.com/how-to-decrease-page-size-in-HTML-and-PHP" />
                <meta name="og:type" property="og:type" content="article" />
                <meta name="og:url" property="og:url" content="https://www.weblession.com/how-to-decrease-page-size-in-HTML-and-PHP" />
                <meta name="og:title" property="og:title" content="How to decrease page size in HTML and PHP" />
                <meta name="og:description" property="og:description" content="In today's fast-paced digital world, website performance is crucial for retaining visitors and ranking well in search engines." />
                <meta name="og:image" property="og:image" content="images/latest_php.webp" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <InnerPagesBanner title="How to decrease page size in HTML and PHP?" description="In today's fast-paced digital world, website performance is crucial for retaining visitors and ranking well in search engines." url="Home" sec="PHP" active="Decrease page size in HTML & PHP" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <p>
                                One significant aspect of performance optimization is reducing the size of your web pages. In this article, we will explore how to decrease page size in HTML and PHP to ensure faster load times and a better user experience.
                            </p>
                            <h2 className="common_title">Minimize HTML and PHP Code</h2>
                            <p>
                                One of the most effective ways to decrease page size is to minimize your HTML and PHP code. Eliminate unnecessary white spaces, line breaks, and indentation. Use online tools like HTML minifiers and PHP code optimizers to automate this process.
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <h2 className="common_title">Compress Images</h2>
                            <p>
                                Images often contribute significantly to page size. To reduce their impact, compress images without sacrificing quality. Tools like <Link to="https://tinypng.com/">TinyPNG</Link> can help you optimize your images before uploading them to your website.
                            </p>
                            <h2 className="common_title">Leverage Browser Caching</h2>
                            <p>
                                In PHP, you can instruct browsers to cache static resources like images, stylesheets, and scripts. This reduces the need for repeated downloads on subsequent visits to your site, improving load times.
                            </p>
                            <h2 className="common_title">Example (PHP)</h2>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet2}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet2)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <h2 className="common_title">Use GZIP Compression</h2>
                            <p>
                                Enable GZIP compression on your server to reduce the size of text-based resources like HTML, CSS, and JavaScript files. This reduces the amount of data transferred between the server and the browser.
                            </p>
                            <h2 className="common_title">Example (Apache .htaccess)</h2>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet3}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet3)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <h2 className="common_title">Load Scripts Asynchronously</h2>
                            <p>
                                Load JavaScript files asynchronously, especially if they are not critical for rendering the initial content. This prevents them from blocking the rendering process and allows the page to load faster.
                            </p>
                            <h2 className="common_title">Example (HTML)</h2>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet4}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet4)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <h2 className="common_title">Minimize External Dependencies</h2>
                            <p>
                                Minimize the use of external resources like fonts and libraries. Only include what is necessary for your site to function properly.
                            </p>
                            <h2 className="common_title">Optimize Database Queries</h2>
                            <p>
                                In PHP, optimize your database queries to reduce the amount of data retrieved from the database. Use indexes and caching where appropriate.
                            </p>
                            <h2 className="common_title">Conclusion</h2>
                            <p>
                                Decreasing page size in HTML and PHP is crucial for improving website performance and user experience. By following these tips and optimizing your code and assets, you can ensure that your web pages load quickly and efficiently.
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <PHPSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default PageDecrease;