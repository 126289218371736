import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";
import AngularSidebar from "../Pages/AngularSidebar";

const BootstrapAngular = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    const codeSnippet = `npm install bootstrap`;
    const codeSnippet2 = `<!DOCTYPE html>
    <html>
    <head>
        <title>Angular.js with Bootstrap</title>
        <link rel="stylesheet" href="node_modules/bootstrap/dist/css/bootstrap.min.css">
        <!-- Include any additional CSS files here -->
    </head>
    <body ng-app="myApp">
    
        <!-- Your Angular.js application content goes here -->
    
        <script src="node_modules/bootstrap/dist/js/bootstrap.bundle.min.js"></script>
        <!-- Include any additional JavaScript files here -->
    </body>
</html>`;
    return (
        <>
            <HighLight />
            <Helmet>
                <title>How to install bootstrap in Angular.js - Weblession</title>
                <meta name="description" content="To install Bootstrap in an Angular.js project, you can use npm (Node Package Manager) to manage dependencies. Here's a step-by-step guide:" />
                <meta name="Keywords" content="How to install bootstrap in Angular js, install bootstrap in angular project, install bootstrap in angular 11, how to import bootstrap into angular, install bootstrap angular npm, how to add bootstrap.min.js in angular, how to install react-bootstrap in node js, angular bootstrap 5 example, how to add bootstrap js in angular project, how to npm install bootstrap" />
                <link rel="canonical" href="https://www.weblession.com/how-to-install-bootstrap-in-angularjs" />
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3545356737183934"
                    crossorigin="anonymous"></script>
                <script>
                    {
                        `(adsbygoogle = window.adsbygoogle || []).push({ });`
                    }
                </script>
            </Helmet>
            <InnerPagesBanner title="How to install bootstrap in Angular.js" description="To install Bootstrap in an Angular.js project, you can use npm (Node Package Manager) to manage dependencies. Here's a step-by-step guide:" url="Home" sec="Angular.js" active="Bootstrap install in Angular.js" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <ol>
                                <li>
                                    <strong>Create or Navigate to Your Angular.js Project Directory:</strong> First, make sure you're in the directory of your Angular.js project, or create a new one if you haven't already.
                                </li>
                                <li>
                                    <strong>Install Bootstrap Package:</strong> In your terminal or command prompt, run the following command to install Bootstrap using npm:
                                </li>
                            </ol>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                This command will download Bootstrap and add it as a dependency in your project's <strong>package.json</strong> file.
                            </p>
                            <h2 className="common_title">Include Bootstrap in Your Angular.js Project</h2>
                            <p>
                                After installing Bootstrap, you need to include it in your Angular.js project. You can do this by adding Bootstrap's CSS and JavaScript files to your project's HTML files. Here's an example of how you can include Bootstrap in your Angular.js application:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet2}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet2)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                Replace <strong>"node_modules/bootstrap/dist/css/bootstrap.min.css"</strong> and <strong>"node_modules/bootstrap/dist/js/bootstrap.bundle.min.js"</strong> with the correct paths to Bootstrap's CSS and JavaScript files in your project.
                            </p>
                            <h2 className="common_title">Verify Installation</h2>
                            <p>
                                After including Bootstrap in your project, you can verify that it's working correctly by adding Bootstrap components to your Angular.js application and testing them.
                            </p>
                            <p>
                                With these steps, you should have Bootstrap successfully installed and integrated into your Angular.js project.
                            </p>
                            <div className="mt-3">
                                {/* Conclusion bottom */}
                                <ins className="adsbygoogle"
                                    style={{ display: 'block' }}
                                    data-ad-client="ca-pub-3545356737183934"
                                    data-ad-slot="2854541895"
                                    data-ad-format="auto"
                                    data-full-width-responsive="true"></ins>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <AngularSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default BootstrapAngular;