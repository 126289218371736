import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";
import PythonSidebar from "./Sidebar";

const FileHandling = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const codeSnippet = `# File Handling in Python

    # Opening a file in write mode ('w')
    file_path = 'example.txt'
    
    # Writing to a file
    with open(file_path, 'w') as file:
        file.write('Hello, this is an example of file handling in Python.\n')
        file.write('We can perform various operations such as reading, writing, and appending.\n')
    
    # Reading from a file
    with open(file_path, 'r') as file:
        content = file.read()
        print('File Content:')
        print(content)
    
    # Appending to a file
    with open(file_path, 'a') as file:
        file.write('Now, we are adding more content to the file.\n')
    
    # Reading the updated file
    with open(file_path, 'r') as file:
        updated_content = file.read()
        print('Updated File Content:')
        print(updated_content)`;

    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    return (
        <>
            <HighLight />
            <Helmet>
                <title>File Handling in python - Weblession</title>
                <meta name="description" content="Here's a brief explanation and a simple code example for file handling in Python..." />
                <meta name="Keywords" content="file handling in python, binary file handling in python, file handling in python append, append in file handling in python" />
                <link rel="canonical" href="https://www.weblession.com/file-handling-in-python" />

                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3545356737183934"
                    crossorigin="anonymous"></script>
                <script>
                    {
                        `(adsbygoogle = window.adsbygoogle || []).push({ });`
                    }
                </script>
            </Helmet>
            <InnerPagesBanner title="File Handling in Pyhton" description="Here's a brief explanation and a simple code example for file handling in Python." url="Home" sec="Python" active="File handling in python" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <div className="mb-3">
                                {/* Json web Token */}
                                <ins className="adsbygoogle"
                                    style={{ display: 'block' }}
                                    data-ad-client="ca-pub-3545356737183934"
                                    data-ad-slot="5398141269"
                                    data-ad-format="auto"
                                    data-full-width-responsive="true"></ins>
                            </div>
                            <p>
                                File handling in Python is a fundamental aspect of working with data and information stored in files. Python provides built-in functions and modules to perform various file operations. Here's a brief overview along with a code example:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                <strong>In this example:</strong>
                            </p>
                            <ol>
                                <li>
                                    We start by opening a file named 'example.txt' in write mode ('w') and write some initial content.
                                </li>
                                <li>
                                    Next, we open the file in read mode ('r') to read and print its content.
                                </li>
                                <li>
                                    Then, we open the file in append mode ('a') to add more content to it.
                                </li>
                                <li>
                                    Finally, we read and print the updated content of the file.
                                </li>
                            </ol>
                            <p>
                                This is a basic illustration of file handling in Python. You can perform various other operations like reading line by line, handling binary files, and more, depending on your requirements. File handling is crucial for tasks such as data persistence, logging, and configuration management in Python applications.
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <PythonSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default FileHandling;