import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import HTMLSidebar from "./HTMLSidebar";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";

const DisplaFlex = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const codeSnippet = `<!DOCTYPE html>
    <html>
    <head>
        <style>
            .navbar {
                display: flex;
                justify-content: space-between;
                align-items: center;
                background-color: #333;
                color: #fff;
                padding: 10px;
            }
            .logo {
                font-size: 24px;
            }
            .menu {
                list-style: none;
                display: flex;
            }
            .menu li {
                margin-right: 20px;
            }
        </style>
    </head>
    <body>
        <div class="navbar">
            <div class="logo">Your Logo</div>
            <ul class="menu">
                <li>Home</li>
                <li>About</li>
                <li>Services</li>
                <li>Contact</li>
            </ul>
        </div>
    </body>
</html>`;
    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    return (
        <>
        <HighLight />
            <Helmet>
                <title>Use of display flex in CSS - Weblession</title>
                <meta name="description" content="When it comes to designing a user-friendly and visually appealing website, the layout of your user interface (UI) elements plays a crucial role." />
                <meta name="Keywords" content="what is display flex in css, what does display flex do in css, how to use display flex css, what does css display flex do, use of display flex in css, what is the use of display flex in css, display flex css example, css display flex meaning, 	
use css flexbox" />
                <link rel="canonical" href="https://www.weblession.com/display-flex" />
                <meta name="og:type" property="og:type" content="article" />
                <meta name="og:url" property="og:url" content="https://www.weblession.com/display-flex" />
                <meta name="og:title" property="og:title" content="Use of display flex in CSS" />
                <meta name="og:description" property="og:description" content="When it comes to designing a user-friendly and visually appealing website, the layout of your user interface (UI) elements plays a crucial role." />
                <meta name="og:image" property="og:image" content="images/html_css.webp" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <InnerPagesBanner title="Use of display flex in CSS" description="When it comes to designing a user-friendly and visually appealing website, the layout of your user interface (UI) elements plays a crucial role." url="Home" sec="HTML/CSS" active="Initial-scale" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <p>
                                In CSS, <strong>"display: flex"</strong> is a property used to define a flexible container for laying out child elements. It transforms the way these elements are displayed within their parent container, making it easier to achieve complex layouts. Let's take a brief look at how it functions:
                            </p>
                            <ul>
                                <li>
                                    <strong>Container and Items:</strong> The elements you want to arrange are placed within a parent container, often referred to as a flex container.
                                </li>
                                <li>
                                    <strong>Direction:</strong> By default, <strong>"display: flex"</strong> arranges items horizontally. However, you can change the direction to vertical using <strong>"flex-direction: column"</strong>.
                                </li>
                                <li>
                                    <strong>Alignment:</strong> Flexbox provides powerful alignment options for both the container and its items. You can control how items are positioned along the main and cross axes.
                                </li>
                                <li>
                                    <strong>Flexibility:</strong> Elements inside a flex container can dynamically adjust their size to fill available space, making it perfect for responsive design.
                                </li>
                            </ul>
                            <h2 className="common_title">Practical Example</h2>
                            <p>
                                Let's dive into a practical example to illustrate the use of <strong>"display: flex"</strong>. Suppose you want to create a navigation bar with a logo on the left and a menu on the right. You can accomplish this with ease using the following approach:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                In this example, we create a navigation bar with a flex container <strong>(.navbar)</strong> that arranges its children <strong> (div.logo and ul.menu)</strong> in a row with space between them. The <strong>align-items</strong> property centers items vertically within the container, and the result is a clean and responsive navigation bar.
                            </p>
                            <h2 className="common_title">Conclusion</h2>
                            <p>
                                <strong>"display: flex"</strong> is a powerful tool for simplifying the arrangement of UI elements on your website. Its flexibility and ease of use make it an essential skill for web developers. By mastering flexbox, you can create stunning, responsive layouts that enhance the overall user experience of your website.
                            </p>
                            <p>
                                Start experimenting with <strong>"display: flex"</strong> in your web projects today and unlock a world of design possibilities!
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <HTMLSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default DisplaFlex;