import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import JquerySidebar from "./JquerySidebar";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";

const ChainingJquery = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const codesnippet = `<!DOCTYPE html>
    <html>
    <head>
        <title>Chaining in jQuery</title>
        <script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>
    </head>
    <body>
        <p id="myParagraph">This is a sample paragraph.</p>
    
        <script>
            $(document).ready(function () {
                // Chaining multiple methods
                $('#myParagraph')
                    .css('color', 'blue')
                    .fadeOut(2000)
                    .fadeIn(2000)
                    .html('New content using chaining')
                    .css('font-weight', 'bold');
            });
        </script>
    </body>
</html>`;
    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    return (
        <>
        <HighLight />
            <Helmet>
                <title>Chaining in jQuery - Weblession</title>
                <link rel="canonical" href="https://www.weblession.com/chaining-in-jquery" />
                <meta name="og:type" property="og:type" content="article" />
                <meta name="og:url" property="og:url" content="https://www.weblession.com/chaining-in-jquery" />
                <meta name="og:title" property="og:title" content="Chaining in jQuery" />
                <meta name="og:description" property="og:description" content="Chaining is a powerful and fundamental concept in jQuery that allows you to perform multiple actions on a set of elements in a concise and efficient way." />
                <meta name="og:image" property="og:image" content="images/latest_jquery.webp" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <InnerPagesBanner title="Chaining in jQuery" description="Chaining is a powerful and fundamental concept in jQuery that allows you to perform multiple actions on a set of elements in a concise and efficient way." url="Home" sec="Jquery" active="Chaining in jQuery" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <p>
                                jQuery simplifies the process of making AJAX requests, making it a popular choice for web developers. In this article, we'll explore how to make an AJAX request using jQuery with step-by-step code examples.It enables you to apply a sequence of jQuery methods to the same selection, resulting in cleaner and more readable code. In this article, we will delve into the concept of chaining in jQuery with code examples to illustrate its utility.
                            </p>
                            <h2 className="common_title">Basic Concept of Chaining</h2>
                            <p>
                                In jQuery, chaining is achieved by connecting multiple methods one after another, where each method operates on the same set of selected elements. By chaining methods, you can perform a series of operations without repeatedly selecting the same elements, which not only simplifies your code but also improves performance.
                            </p>
                            <h2 className="common_title">Example 1: Chaining in Action</h2>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codesnippet}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codesnippet)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                In this example, we start by selecting the <strong>"#myParagraph"</strong> element and then chain several methods: <strong>css(), fadeOut(), fadeIn(), html(),</strong> and another <strong>css()</strong> method. Each method operates on the same element without the need to reselect it.
                            </p>
                            <h2 className="common_title">Benefits of Chaining</h2>
                            <ol>
                                <li>
                                    <strong>Improved Readability:</strong> Chaining allows you to express a sequence of actions in a linear and intuitive manner, making your code more readable and easier to understand.
                                </li>
                                <li>
                                    <strong>Efficiency:</strong> Chaining minimizes the number of times you access the DOM by reusing the selected elements. This results in better performance compared to repeatedly selecting the same elements.
                                </li>
                                <li>
                                    <strong>Compact Code:</strong> Chaining enables you to perform complex operations with minimal code, reducing the overall size of your scripts.
                                </li>
                                <li>
                                    <strong>Easier Maintenance:</strong> When you need to make changes to a series of operations, you can quickly locate and modify them within the chain, simplifying maintenance.
                                </li>
                            </ol>
                            <h2 className="common_title">Conclusion</h2>
                            <p>
                                Chaining is a core concept in jQuery that enhances the clarity and efficiency of your code. By stringing together multiple methods, you can streamline your scripts, leading to more maintainable and performant web applications. Whether you're manipulating CSS properties, handling animations, or working with content, mastering chaining in jQuery will greatly benefit your development efforts.
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <JquerySidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default ChainingJquery;