import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import AllPagesData from "./AllPagesData";
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import HighLight from "./HighLight";

const AllListingPages = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [noOfElement, setnoOfElement] = useState(4);
    const slice = AllPagesData.slice(0, noOfElement);
    const loadMore = () => {
        setnoOfElement(noOfElement + noOfElement);
    }
    return (
        <>
            <HighLight />
            <Helmet>
                <title>Recent Post - Weblession</title>
                <meta name="description" content="Are you prepared to elevate your web development skills to new heights? In today's fast-paced digital landscape, creating a responsive website is no longer just a trend – it's a necessity." />
                <meta name="Keywords" content="" />
                <link rel="canonical" href="https://www.weblession.com/recent-post" />
                <meta name="og:type" property="og:type" content="article" />
                <meta name="og:url" property="og:url" content="https://www.weblession.com/recent-post" />
                <meta name="og:title" property="og:title" content="Recent Post" />
                <meta name="og:description" property="og:description" content="Are you prepared to elevate your web development skills to new heights? In today's fast-paced digital landscape, creating a responsive website is no longer just a trend – it's a necessity." />
                <meta name="og:image" property="og:image" content="images/latest_php.webp" />
                <meta name="twitter:card" content="summary" />

                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3545356737183934"
                    crossorigin="anonymous"></script>
                <script>
                    {
                        `(adsbygoogle = window.adsbygoogle || []).push({ });`
                    }
                </script>
            </Helmet>
            <InnerPagesBanner title="Recent Post" description="Are you prepared to elevate your web development skills to new heights? In today's fast-paced digital landscape, creating a responsive website is no longer just a trend – it's a necessity." url="Home" sec="Recent Post" active="Latest Post" />
            <section className="react_common_topic" id="react_common_topic">
                <div className="container">
                    <div className="row">
                        {
                            slice.map((val, ind) => {
                                return (
                                    <div className="col-sm-6" key={ind}>
                                        <div className="mb-3">
                                            <div className="white_widget_inner all_post">
                                                <div className="row align-items-center">
                                                    <div className="col-sm-5">
                                                        <div className="grid">
                                                            <figure className="effect-lily">
                                                                <img src={val.imgsrc} className="latest_post_img"
                                                                    alt={val.altTag} />
                                                            </figure>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-7">
                                                        <h3 className="small_heading p-0">{val.subtitle}</h3>
                                                        <Link to={val.link} className="effect_link">
                                                            {val.title}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="text-center">
                        <button className="common_btn load_more" onClick={() => loadMore()}>Load More</button>
                    </div>
                    <div className="mt-3">
                        <ins className="adsbygoogle"
                            style={{ display: 'block' }}
                            data-ad-format="autorelaxed"
                            data-ad-client="ca-pub-3545356737183934"
                            data-ad-slot="4035977227"></ins>
                    </div>
                </div>
            </section>

        </>
    )
}

export default AllListingPages;