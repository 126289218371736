import React from "react";
import '../css/footer.css';
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
            <footer className="footer_new" id="footer_new">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3">
                            <div className="mb-2">
                                <h2 className="common_title">HTML</h2>
                                <ul>
                                    <li>
                                        <Link to="/initial-scale-in-html" className="footer_link">Why use initial-scale in HTML?</Link>
                                    </li>
                                    <li>
                                        <Link to="/viewport-in-html" className="footer_link">Why use viewport in HTML?</Link>
                                    </li>
                                    <li>
                                        <Link to="/difference-div-span" className="footer_link">Explain the difference between <code>(&lt;div&gt;)</code> and <code>(&lt;span&gt;)</code> elements.</Link>
                                    </li>
                                    <li>
                                        <Link to="/semantic-html" className="footer_link">Explain the concept of semantic HTML.</Link>
                                    </li>
                                    <li>
                                        <Link to="/meta-tag-in-html" className="footer_link">What is the role of the <code>(&lt;meta&gt;)</code> tag in HTML?</Link>
                                    </li>
                                    <li>
                                        <Link to="/self-closing-tag" className="footer_link">What are self-closing tags?</Link>
                                    </li>
                                    <li>
                                        <Link to="/html-elements-tag" className="footer_link">What are HTML elements and tags?</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="mb-2">
                                <h2 className="common_title">React.js</h2>
                                <ul>
                                    <li>
                                        <Link to="/what-is-react" className="footer_link">What is React.js?</Link>
                                    </li>
                                    <li>
                                        <Link to="/virtual-dom" className="footer_link">Let's Explain the concept of Virtual DOM in React.</Link>
                                    </li>
                                    <li>
                                        <Link to="/create-react-component" className="footer_link">Learn React components</Link>
                                    </li>
                                    <li>
                                        <Link to="/props-react" className="footer_link">Easy learn props in React</Link>
                                    </li>
                                    <li>
                                        <Link to="/what-is-state-in-react" className="footer_link">What is state in React?</Link>
                                    </li>
                                    <li>
                                        <Link to="/useEffect-hook" className="footer_link">Purpose of the useEffect hook?</Link>
                                    </li>
                                    <li>
                                        <Link to="/what-is-JSX" className="footer_link">What is JSX?</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="mb-2">
                                <h2 className="common_title">PHP</h2>
                                <ul>
                                    <li>
                                        <Link to="/what-is-php" className="footer_link">What is PHP?</Link>
                                    </li>
                                    <li>
                                        <Link to="/difference-echo-print" className="footer_link">Let's Explain the difference between <code>"echo"</code> and <code>"print"</code> in PHP.</Link>
                                    </li>
                                    <li>
                                        <Link to="/difference-php-javascript" className="footer_link">Difference between PHP and JavaScript</Link>
                                    </li>
                                    <li>
                                        <Link to="/include-external-php-file" className="footer_link">How do you include an external PHP file in another PHP file?</Link>
                                    </li>                                    
                                    <li>
                                        <Link to="/session-in-php" className="footer_link">Session in PHP</Link>
                                    </li>
                                    <li>
                                        <Link to="/set-retrieve-cookies" className="footer_link">Set and retrieve cookies in PHP</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className="mb-2">
                                <h2 className="common_title">Jquery</h2>
                                <ul>
                                    <li>
                                        <Link to="/difference-html-text-method" className="footer_link">Difference between <code>.html()</code> and <code>.text()</code> methods in jQuery</Link>
                                    </li>
                                    <li>
                                        <Link to="/ajax-request-using-jquery" className="footer_link">How can you make an AJAX request using jQuery?</Link>
                                    </li>
                                    <li>
                                        <Link to="/chaining-in-jquery" className="footer_link">What is chaining in jQuery?</Link>
                                    </li>
                                    <li>
                                        <Link to="/noconflict-method" className="footer_link">The purpose of the noConflict() method in jQuery</Link>
                                    </li>
                                    <li>
                                        <Link to="/fade-out-in-jquery" className="footer_link">Fade out an element using jQuery</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="copyright_widget mt-2">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <p>
                                    &copy; copyright {(new Date().getFullYear())} Weblession. All Rights Reserved.
                                </p>
                            </div>
                            <div className="col-sm-6">
                                <div className="footer_right_menu">
                                    <ul>
                                        <li>
                                            <Link to="/privacy-policy" className="effect_link small_font">Privacy policy</Link>
                                        </li>
                                        <li>
                                            <Link to="/term-condition" className="effect_link small_font">Terms & Conditions</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;