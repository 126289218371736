import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import '../css/Navbar.css';

const Navbar = () => {
    let navigate = useNavigate();

    function changeLocation(placeToGo){
        navigate(placeToGo, { replace: true });
        window.location.reload();
    }
    return (
        <>
            <nav className="navbar navbar-expand-lg bg-light">
                <div className="container">
                    <Link className="navbar-brand" to="/"><span>Web</span>lession</Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/"><img src="images/home_icon.svg" width="20px" height="20px" className="icon_top" alt="Home" /></NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/about-us">About Us</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/recent-post">Recent Post</NavLink>
                                {/* <NavLink className="nav-link" to="/recent-post" onClick={() => changeLocation('/recent-post')}>Recent Post</NavLink> */}
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/html-interview">Interview Tips</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar;