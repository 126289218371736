const WordpresCommonData = [
    {
        id: 1,
        menutitle:"Updating Custom Field WordPress Using WooCommerce",
        url:"/update-custom-field-in-wordpress-using-wooCommerce",
    },   
    {
        id: 3,
        menutitle:"How to set attribute placeholder for the wordpress login page?",
        url:"/how-to-set-attribute-placeholder-for-the-wordpress-login-page",
    },    
]

export default WordpresCommonData;