import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import HTMLSidebar from "./HTMLSidebar";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";

const DiffrenceDIVSpan = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const codeSnippetJSX = `"<div>"`;
    const codeSnippetJSX2 = `<!DOCTYPE html>
    <html>
    <head>
    <style>
      .container {
        border: 1px solid #ddd;
        padding: 10px;
      }
    </style>
    </head>
    <body>
    
    <div class="container">
      <h1>Welcome to Our Website</h1>
      <p>This is a <strong>div</strong> element used to group and style content.</p>
    </div>
    
    </body>
</html>`;
    const codeSnippetJSX3 = `"<span>"`;
    const codeSnippetJSX4 = `<!DOCTYPE html>
    <html>
    <head>
    <style>
      .highlight {
        background-color: yellow;
        font-weight: bold;
      }
    </style>
    </head>
    <body>
    
    <p>
      This is a <span class="highlight">highlighted</span> text using the <strong>span</strong> element.
    </p>
    
    </body>
</html>`;
    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    return (
        <>
            <HighLight />
            <Helmet>
                <title>Difference Between div and span Elements - Weblession</title>
                <meta name="description" content="Let's dive into the key differences between <div> and <span> elements with code examples to illustrate their usage..." />
                <meta name="Keywords" content="what is the difference between a div and a span, html difference between div and span, difference between div and span tags in html, html difference between div and section, what is the difference between div and span elements, span vs. div, div and span difference, difference between div and span elements, difference between div and span with example, difference between div span and section" />
                <link rel="canonical" href="https://www.weblession.com/difference-div-span" />
                <meta name="og:type" property="og:type" content="article" />
                <meta name="og:url" property="og:url" content="https://www.weblession.com/difference-div-span" />
                <meta name="og:title" property="og:title" content="Difference Between div and span Elements" />
                <meta name="og:description" property="og:description" content="Let's dive into the key differences between <div> and <span> elements with code examples to illustrate their usage..." />
                <meta name="og:image" property="og:image" content="images/html_css.webp" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <InnerPagesBanner title="Difference Between <div> and <span> Elements" description="Let's dive into the key differences between <div> and <span> elements with code examples to illustrate their usage." url="Home" sec="HTML" active="Difference div & span" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <h2 className="common_title">The {codeSnippetJSX} Element</h2>
                            <p>
                                The <strong>{codeSnippetJSX}</strong> (short for division) element is a block-level container that's commonly used to group and structure larger sections of content on a webpage. It's often used to create divisions or compartments within the layout, allowing you to apply styles, scripts, or other attributes to the content contained within it.
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippetJSX2}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippetJSX2)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                In this example, the <strong>{codeSnippetJSX}</strong> element with the class container groups the heading and paragraph elements. It's styled with a border and padding to visually differentiate it from other content on the page.
                            </p>
                            <h2 className="common_title">The {codeSnippetJSX3} Element</h2>
                            <p>
                                On the other hand, the <strong>{codeSnippetJSX3}</strong> element is an inline-level container that's used to apply styling, such as CSS styles or JavaScript interactions, to a specific portion of text or inline content. It doesn't add any structural meaning on its own and is often used to target specific parts of the text for formatting or scripting.
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippetJSX4}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippetJSX4)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                In this example, the <strong>{codeSnippetJSX3}</strong> element with the class highlight is used to apply a background color and bold styling to the word "highlighted" within the paragraph.
                            </p>
                            <h2 className="common_title">Summary</h2>
                            <p>
                                In essence, the main difference between <strong>{codeSnippetJSX}</strong> and <strong>{codeSnippetJSX3}</strong> elements lies in their usage:
                            </p>
                            <ul>
                                <li>
                                    <strong>{codeSnippetJSX}</strong>: elements are block-level containers used to group and structure larger sections of content.
                                </li>
                                <li>
                                    <strong>{codeSnippetJSX3}</strong>: elements are inline-level containers used to apply styling or scripting to specific portions of text or inline content.
                                </li>
                            </ul>
                            <p>
                                Understanding when and how to use these elements will help you create well-structured and visually appealing web pages.
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <HTMLSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default DiffrenceDIVSpan;