import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import ReactSidebar from '../Pages/Sidebar';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";
import PythonSidebar from "./Sidebar";

const SendRequest = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const codeSnippet = `from flask import Flask, request, jsonify

    app = Flask(__name__)
    
    @app.route('/get_data', methods=['GET'])
    def get_data():
        # Simulate fetching data from a database or performing some computation
        data = {'message': 'Hello from Server B! This is the data you requested.'}
        return jsonify(data)
    
    @app.route('/post_data', methods=['POST'])
    def post_data():
        # Receive data from Server A
        received_data = request.json
    
        # Process the received data (you can perform any required operations here)
        result = {'status': 'Data received successfully', 'received_data': received_data}
    
        return jsonify(result)
    
    if __name__ == '__main__':
        app.run(port=5001)`;

    const codeSnippet2 = `import requests

    # Server B URL
    server_b_url = 'http://localhost:5001'
    
    # Send GET request to Server B
    get_response = requests.get(f'{server_b_url}/get_data')
    
    # Print the response from Server B
    print('GET Response from Server B:')
    print(get_response.json())
    print('\n')
    
    # Payload for the POST Request
    post_data = {'key': 'value', 'another_key': 'another_value'}
    
    # Initiate a POST Request to Server B
    post_response = requests.post(f'{server_b_url}/post_data', json=post_data)
    
    # Print the response from Server B for the POST request
    print('POST Response from Server B:')
    print(post_response.json())`;

    const codeSnippet3 = `pip install Flask requests`;

    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    return (
        <>
            <HighLight />
            <Helmet>
                <title>How to Establish Communication Between Two Servers Using Requests? - Weblession</title>
                <meta name="description" content="To demonstrate how to send requests back and forth between two servers, we'll create a simple example using Python and the popular requests library." />
                <meta name="Keywords" content="How to Establish Communication Between Two Servers Using Requests, communicate between two python scripts, communication between 2 python programs" />
                <link rel="canonical" href="https://www.weblession.com/how-to-establish-communication-between-two-servers-using-requests" />

                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3545356737183934"
                    crossorigin="anonymous"></script>
                <script>
                    {
                        `(adsbygoogle = window.adsbygoogle || []).push({ });`
                    }
                </script>
            </Helmet>
            <InnerPagesBanner title="How to Establish Communication Between Two Servers Using Requests?" description="To demonstrate how to send requests back and forth between two servers, we'll create a simple example using Python and the popular requests library." url="Home" sec="Python" active="Send Request" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <div className="mb-3">                                
                                {/* Numba on Window */}
                                <ins className="adsbygoogle"
                                    style={{ display: 'block' }}
                                    data-ad-client="ca-pub-3545356737183934"
                                    data-ad-slot="7669072614"
                                    data-ad-format="auto"
                                    data-full-width-responsive="true"></ins>                                
                            </div>
                            <p>
                                In this example, Server A will send a GET request to Server B, and Server B will respond with some data. Then, Server A will send a POST request to Server B with additional information.
                            </p>
                            <p>
                                Let's start with Server B, which will respond to the GET request and handle the POST request.
                            </p>
                            <h2 className="common_title">Server B (Receiver Server)</h2>
                            <p>
                                Here's an example of how you might use the <strong>requests</strong> library to include a JWT in your HTTP request headers:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                Now, let's create Server A, which will initiate the communication by sending a GET request to Server B and then follow up with a POST request.
                            </p>
                            <h2 className="common_title">Server A (Sender Server)</h2>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet2}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet2)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                In this example, Server A uses the requests library to communicate with Server B. Server B, implemented using Flask, responds to the GET request with some sample data and processes the POST request by printing the received data. Modify the code according to your particular requirements.
                            </p>
                            <p>
                                Remember to install Flask and requests in your Python environment before running the code:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet3}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet3)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                Feel free to customize this example based on your specific requirements or use it as a starting point for more complex interactions between servers.
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <PythonSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default SendRequest;