const VueSidebarDat = [
    {
        id: 1,
        menutitle:"How to install bootstrap in Vue.js?",
        url:"/how-to-install-bootstrap-in-vuejs",
    },
    {
        id: 2,
        menutitle:"How can I optimize Swiper.js in Vue.js to prevent page freezes",
        url:"/how-can-i-optimize-swiperjs-in-vuejs-to-prevent-page-freezes",
    }
]

export default VueSidebarDat;