import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import ReactSidebar from '../Pages/Sidebar';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";
import PythonSidebar from "./Sidebar";
import { Link } from "react-router-dom";

const NumbaWindow = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const codeSnippet = `conda create --name myenv`;
    const codeSnippet2 = `conda activate myenv`;
    const codeSnippet3 = `conda install -c conda-forge numba`;
    const codeSnippet4 = `python -m numba.runtests`;
    const codeSnippet5 = `conda activate myenv`;

    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    return (
        <>
            <HighLight />
            <Helmet>
                <title>How to Install numba on windows for python? - Weblession</title>
                <meta name="description" content="To install Numba on Windows for Python, you can use the following steps:" />
                <meta name="Keywords" content="How to Install numba on windows for python?, how to install numpy windows 10, install numpy cmd windows, how to install numpy on windows, install python and numpy on windows" />
                <link rel="canonical" href="https://www.weblession.com/how-to-install-numba-on-windows-for-python" />

                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3545356737183934"
                    crossorigin="anonymous"></script>
                <script>
                    {
                        `(adsbygoogle = window.adsbygoogle || []).push({ });`
                    }
                </script>
            </Helmet>
            <InnerPagesBanner title="How to Install numba on windows for python?" description="To install Numba on Windows for Python, you can use the following steps:" url="Home" sec="Python" active="Install numba on windows for python" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <div className="mb-3">
                                {/* Json web Token */}
                                <ins className="adsbygoogle"
                                    style={{ display: 'block' }}
                                    data-ad-client="ca-pub-3545356737183934"
                                    data-ad-slot="7988582919"
                                    data-ad-format="auto"
                                    data-full-width-responsive="true"></ins>
                            </div>
                            <h2 className="common_title">Install Anaconda</h2>
                            <p>
                                If you don't have Anaconda installed, it's recommended to install it first. Anaconda is a distribution of Python that comes with many scientific computing and data science packages, including Numba.
                            </p>
                            <p>
                                You can download Anaconda from <Link to="https://www.anaconda.com/products/distribution" target="_blank">https://www.anaconda.com/products/distribution</Link>. Choose the appropriate version (e.g., Python 3.8) and download the installer.
                                Proceed with the installation steps provided on the Anaconda website.
                            </p>
                            <h2 className="common_title">Open Anaconda Prompt</h2>
                            <p>
                                After installing Anaconda, open the Anaconda Prompt from the Start menu. This is a terminal where you can execute commands.
                            </p>
                            <h2 className="common_title">
                                Create a Virtual Environment (Optional but Recommended)
                            </h2>
                            <p>
                                It's a good practice to create a virtual environment for your project. You have the option to generate a virtual environment by utilizing the subsequent command:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                Substitute "myenv" with the preferred name for your virtual environment. Activate the virtual environment:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet2}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet2)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <h2 className="common_title">Install Numba</h2>
                            <p>
                                Once you have the virtual environment activated, you can install Numba using the following command:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet3}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet3)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                This command installs Numba from the conda-forge channel.
                            </p>
                            <h2 className="common_title">Verify Installation</h2>
                            <p>
                                You can verify that Numba is installed by running the following command:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet4}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet4)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                This command runs Numba's test suite. If everything is set up correctly, you should see a message indicating that the tests passed.
                            </p>
                            <p>
                                Now, you have Numba installed on your Windows system. You can use it in your Python scripts or Jupyter Notebooks to accelerate your numerical code.
                            </p>
                            <p>
                                Remember to activate your virtual environment every time you work on your project:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet5}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet5)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                If you encounter any issues during the installation process, you may want to check the official Numba documentation or the Numba GitHub repository for the latest information.
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <PythonSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default NumbaWindow;