import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";
import AngularSidebar from "../Pages/AngularSidebar";

const DynamicGenerate = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    const codeSnippet = `const fs = require('fs');

    function generateAngularJson() {
        // Initialize the base configuration for angular.json
        let angularJsonConfig = {
            version: 1,
            projects: {
                app: {
                    projectType: 'application',
                    root: 'src/',
                    sourceRoot: 'src/',
                    prefix: 'app',
                    targets: {
                        build: {
                            builder: '@angular-devkit/build-angular:browser',
                            options: {
                                outputPath: 'dist/app',
                                index: 'src/index.html',
                                main: 'src/main.ts',
                                polyfills: 'src/polyfills.ts',
                                tsConfig: 'src/tsconfig.app.json',
                                assets: ['src/favicon.ico', 'src/assets'],
                                styles: ['src/styles.css'],
                                scripts: [],
                            },
                        },
                        serve: {
                            builder: '@angular-devkit/build-angular:dev-server',
                            options: {
                                browserTarget: 'app:build',
                            },
                        },
                        // Additional targets can be added here (e.g., test, lint)
                    },
                },
                // Add additional projects as needed
            },
        };
    
        // Modify configuration as needed
        // Example: Adding a new project
        angularJsonConfig.projects['another-app'] = {
            projectType: 'application',
            root: 'src/another-app/',
            sourceRoot: 'src/another-app/',
            prefix: 'anotherApp',
            targets: {
                build: {
                    builder: '@angular-devkit/build-angular:browser',
                    options: {
                        outputPath: 'dist/another-app',
                        index: 'src/another-app/index.html',
                        main: 'src/another-app/main.ts',
                        polyfills: 'src/another-app/polyfills.ts',
                        tsConfig: 'src/another-app/tsconfig.app.json',
                        assets: ['src/another-app/favicon.ico', 'src/another-app/assets'],
                        styles: ['src/another-app/styles.css'],
                        scripts: [],
                    },
                },
                // Additional targets can be added here (e.g., serve, test, lint)
            },
        };
    
        // Write the configuration to angular.json file
        const angularJsonPath = './angular.json';
        fs.writeFileSync(angularJsonPath, JSON.stringify(angularJsonConfig, null, 2));
    
        console.log('angular.json has been created and configured.');
    }
    
    // Call the function to generate angular.json
    generateAngularJson();`;
    return (
        <>
            <HighLight />
            <Helmet>
                <title>How can I dynamically generate an angular.json file - Weblession</title>
                <meta name="description" content="Building an angular.json file dynamically can be useful when you want to programmatically configure an Angular project based on certain requirements. While Angular CLI provides a standardized angular.json file that includes project and build configuration, you can generate and modify the file dynamically using Node.js or other JavaScript/TypeScript-based scripts." />
                <meta name="Keywords" content="how can i dynamically generate an angular.json file, generate angular.json file, angular load json file dynamically, angular create json object dynamically, angular dynamically load js file, how to generate angular.json" />
                <link rel="canonical" href="https://www.weblession.com/how-can-i-dynamically-generate-an-angular-json-file" />
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3545356737183934"
                    crossorigin="anonymous"></script>
                <script>
                    {
                        `(adsbygoogle = window.adsbygoogle || []).push({});`
                    }
                </script>
            </Helmet>
            <InnerPagesBanner title="How can I dynamically generate an angular.json file" description="Building an angular.json file dynamically can be useful when you want to programmatically configure an Angular project based on certain requirements." url="Home" sec="Angular.js" active="Dynamically Angular.json" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <p>
                                While Angular CLI provides a standardized <strong>angular.json</strong> file that includes project and build configuration, you can generate and modify the file dynamically using Node.js or other JavaScript/TypeScript-based scripts.
                            </p>
                            <p>
                                Here's how you can approach dynamically building an <strong>angular.json</strong> file:
                            </p>
                            <ol>
                                <li>
                                    <strong>Initialize the angular.json Structure</strong>: Begin by initializing the base structure for <strong>angular.json</strong>. This includes setting up the default projects and targets.
                                </li>
                                <li>
                                    <strong>Read Existing Configuration (if any)</strong>: If you want to modify an existing <strong>angular.json</strong> file, you can read the existing file and parse it into a JavaScript object.
                                </li>
                                <li>
                                    <strong>Modify Configuration Based on Requirements</strong>: Use logic in your script to modify the configuration based on your requirements. You may need to add or modify projects, targets, styles, or any other configuration within <strong>angular.json</strong>.
                                </li>
                                <li>
                                    <strong>Write Back to File</strong>: Once you've modified the <strong>angular.json</strong> configuration object, serialize it back to JSON and write it to the file.
                                </li>
                            </ol>
                            <p>
                                Here's a code example that demonstrates how you might dynamically build an <strong>angular.json</strong> file:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                In the code above:
                            </p>
                            <ul>
                                <li>
                                    The script begins by initializing a basic <strong>angular.json</strong> structure with a sample project.
                                </li>
                                <li>
                                    Adjust the configuration as needed to suit your requirements. In the code, a new project called "another-app" is added to the configuration.
                                </li>
                                <li>
                                    Finally, the script writes the modified configuration to an <strong>angular</strong>.json file.
                                </li>
                            </ul>
                            <p>
                                This example should give you a starting point for dynamically building or modifying an <strong>angular.json</strong> file.
                            </p>
                            <div className="mt-3 mb-3">
                                <ins class="adsbygoogle"
                                    style={{ display: 'block' }}
                                    data-ad-client="ca-pub-3545356737183934"
                                    data-ad-slot="3121889067"
                                    data-ad-format="auto"
                                    data-full-width-responsive="true"></ins>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <AngularSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default DynamicGenerate;