import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import HTMLSidebar from "./HTMLSidebar";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";

const DataTable = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const codeSnippet = `<!DOCTYPE html>
    <html>
    <head>
        <title>Data Table Example</title>
    </head>
    <body>
        <h1>Employee Information</h1>
        <table border="1">
            <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Department</th>
                <th>Salary</th>
            </tr>
            <tr>
                <td>001</td>
                <td>John Doe</td>
                <td>HR</td>
                <td>$50,000</td>
            </tr>
            <!-- Add more rows as needed -->
        </table>
    </body>
</html>`;
    const codeSnippet2 = `"<table>"`;
    const codeSnippet3 = `"<tr>"`;
    const codeSnippet4 = `"<th>"`;
    const codeSnippet5 = `"<td>"`;
    const codeSnippet6 = `<!DOCTYPE html>
    <html>
    <head>
        <title>Data Table Example</title>
        <link rel="stylesheet" type="text/css" href="https://cdn.datatables.net/1.11.6/css/jquery.dataTables.css">
    </head>
    <body>
        <h1>Employee Information</h1>
        <table id="employeeTable">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Department</th>
                    <th>Salary</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>001</td>
                    <td>John Doe</td>
                    <td>HR</td>
                    <td>$50,000</td>
                </tr>
                <!-- Add more rows as needed -->
            </tbody>
        </table>
    
        <script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>
        <script src="https://cdn.datatables.net/1.11.6/js/jquery.dataTables.js"></script>
        <script>
            $(document).ready(function() {
                $('#employeeTable').DataTable();
            });
        </script>
    </body>
</html>`;

    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    return (
        <>
            <HighLight />
            <Helmet>
                <title>How to implement datatable in HTML - Weblession</title>
                <meta name="description" content="Data tables are a fundamental component of web design, allowing you to present information in a structured and organized format." />
                <meta name="Keywords" content="how to add datatable in html, how to include datatable in html, how to make a datatable in html, how to add jquery datatable in html, datatable render html as text, add html in datatable column, what is table in html explain with example, datatable html css javascript, datatable html example, how to convert html table to datatable in jquery, datatables cdn example" />
                <link rel="canonical" href="https://www.weblession.com/datatable-in-html" />
                <meta name="og:type" property="og:type" content="article" />
                <meta name="og:url" property="og:url" content="https://www.weblession.com/datatable-in-html" />
                <meta name="og:title" property="og:title" content="How to implement datatable in HTML" />
                <meta name="og:description" property="og:description" content="Data tables are a fundamental component of web design, allowing you to present information in a structured and organized format." />
                <meta name="og:image" property="og:image" content="images/html_css.webp" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <InnerPagesBanner title="How to implement datatable in HTML" description="Data tables are a fundamental component of web design, allowing you to present information in a structured and organized format." url="Home" sec="HTML/CSS" active="Datatable in HTML" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <p>
                                Whether you're displaying product listings, financial data, or any other tabular information, HTML provides a straightforward way to create data tables. In this guide, we'll explore how to create a data table in HTML and make it interactive.
                            </p>
                            <h2 className="common_title">Basic HTML Table Structure</h2>
                            <p>
                                Let's start with the fundamental HTML structure for creating a basic data table:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet2)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                In this example, we use the <strong>{codeSnippet2}</strong>, <strong>{codeSnippet3}</strong> (table row), <strong>{codeSnippet4}</strong> (table header), and <strong>{codeSnippet5}</strong> (table data) elements to structure the table. The <strong>border="1"</strong> attribute adds a simple border to the table for better visibility.
                            </p>
                            <h2 className="common_title">Adding Interactive Features</h2>
                            <p>
                                To enhance your data table and make it more user-friendly, you can incorporate features like sorting and searching. For this purpose, JavaScript libraries like DataTables.js are highly beneficial.
                            </p>
                            <p>
                                Here's how you can integrate DataTables.js into your HTML document:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet6}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet6)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                In this example, we include the DataTables.js library via CDN and initialize it using JavaScript. This enables sorting, searching, and pagination for your data table without writing extensive code.
                            </p>
                            <h2 className="common_title">Conclusion</h2>
                            <p>
                                Creating a data table in HTML is essential for presenting structured information on your website. You can start with a basic HTML table structure and enhance it further with JavaScript libraries like DataTables.js to make it interactive and user-friendly.
                            </p>
                            <p>
                                Customize the table design and functionality to meet your website's specific needs, and you'll have a powerful tool for displaying data effectively.
                            </p>
                            <p>
                                Feel free to adapt this code and guide to your website's style and requirements.
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <HTMLSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default DataTable;