import React, { useEffect } from 'react';
import hljs from 'highlight.js';
import 'highlight.js/styles/a11y-light.css';
// import 'highlight.js/styles/arduino-light.css';

const HighLight = () => {
    useEffect(() => {
        hljs.initHighlightingOnLoad();
    }, []);
    return(
        <>

        </>
    )
}

export default HighLight;