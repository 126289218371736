import React, { useState } from "react";
import ReactCommonTopic from "../Pages/ReactCommonTopic";
import ReactSidebar from "../Pages/Sidebar";
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";

const PropsReact = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const codeSnippetJSX = `import React from 'react';
import Product from './Product';
    
function App() {
    const productData = {
    name: 'Example Product',
    price: 49.99,
    description: 'This is an example product for demonstration purposes.',
    };

    return (
    <div>
        <h1>Welcome to Our Online Store</h1>
        <Product product={productData} />
    </div>
    );
}
    
export default App;`;
    const codeSnippetJSX2 = `import React from 'react';

function Product(props) {
  const { name, price, description } = props.product;

  return (
    <div>
      <h2>{name}</h2>
      <p>Price:{price}</p>
      <p>Description: {description}</p>
    </div>
  );
}

export default Product;`;
    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    return (
        <>
        <HighLight />
            <Helmet>
                <title>Exploring Props in React.js: Passing Data Between Components - Weblession</title>
                <meta name="description" content="In the world of React.js, the concept of props (short for properties) is a fundamental building block that enables seamless communication and data sharing between different components." />
                <meta name="Keywords" content="how to pass props in react, how to use props in react, difference between state and props in react, render props in react, best way to pass props in react, difference between props and state in react with example, how to pass props between components in react, render props in react example, props in react example, conditional props in react" />
                <link rel="canonical" href="https://www.weblession.com/props-react" />
                <meta name="og:type" property="og:type" content="article" />
                <meta name="og:url" property="og:url" content="https://www.weblession.com/props-react" />
                <meta name="og:title" property="og:title" content="Exploring Props in React.js: Passing Data Between Components" />
                <meta name="og:description" property="og:description" content="In React.js, creating reusable and modular components is at the heart of building dynamic and interactive user interfaces." />
                <meta name="og:image" property="og:image" content="images/latest_react.webp" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <InnerPagesBanner title="Exploring Props in React.js: Passing Data Between Components" description="In React.js, creating reusable and modular components is at the heart of building dynamic and interactive user interfaces." url="Home" sec="React" active="Props in React" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <h2 className="common_title">Introduction</h2>
                            <p>
                                In the world of React.js, the concept of "props" (short for properties) is a fundamental building block that enables seamless communication and data sharing between different components. Props allow you to pass data from a parent component to its child components, facilitating dynamic and flexible user interfaces. In this guide, we'll dive into the world of props, exploring their significance and demonstrating how they work with real-world examples.
                            </p>
                            <h2 className="common_title">Understanding Props</h2>
                            <p>
                                Props are a way of passing data from one component to another in React. They are read-only and provide a means of communicating between components without altering the original data. Props enable components to be modular and reusable, as different instances of the same component can be customized with distinct data through props.
                            </p>
                            <h2 className="common_title">Example Scenario</h2>
                            <p>
                                Let's consider a simple scenario of a "Product" component that displays information about a product. We'll pass down data about the product using props.
                            </p>
                            <h2 className="common_title">Parent Component - App.js</h2>
                            <p>
                                Let's consider a simple example where we have a counter component that increments a value on each click. We'll illustrate how the Virtual DOM operates using React.
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippetJSX}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippetJSX)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <h2 className="common_title">Child Component - Product.js</h2>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippetJSX2}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippetJSX2)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                In this example, the App component serves as the parent component, and the Product component is the child component. The productData object in the parent component is passed to the Product component as a prop named product. The child component then extracts the data from the prop and displays it.
                            </p>
                            <h2 className="common_title">Benefits of Using Props</h2>
                            <ol>
                                <li>
                                    <strong>Reusability:</strong> Components can be reused with different data, promoting a modular design.
                                </li>
                                <li>
                                    <strong>Data Flow:</strong> Props enforce a unidirectional data flow, making it easier to track and manage data changes.
                                </li>
                                <li>
                                    <strong>Separation of Concerns:</strong> Components remain focused on their specific responsibilities, enhancing code organization.
                                </li>
                                <li>
                                    <strong>Testing:</strong> Props make it simpler to test components in isolation as they rely on externally provided data.
                                </li>
                            </ol>
                            <h2 className="common_title">Conclusion</h2>
                            <p>
                                Props in React.js play a vital role in creating dynamic and interactive user interfaces by facilitating the transfer of data between components. By understanding and utilizing props effectively, developers can build reusable and modular components that contribute to more maintainable and efficient codebases.
                            </p>
                            <p>
                                So, whether you're passing down simple data or more complex objects, props provide the bridge for seamless communication between your React components, enhancing the overall user experience of your application.
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <ReactSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}


export default PropsReact;