import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import PHPSidebar from "./PHPSidebar";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";

const IncludePHPFiles = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const codesnippet = `<?php
    include('header.php');
?>`;
    const codesnippet2 = `<?php
    require('footer.php');
?>`;
    const codesnippet3 = `<?php
    include_once('config.php');
    include_once('functions.php');
?>`;
    const codesnippet4 = `<?php
    require_once('database.php');
    require_once('utils.php');
?>`;

    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };

    return (
        <>
        <HighLight />
            <Helmet>
                <title>Include External PHP Files - Weblession</title>
                <meta name="description" content="In web development, it's often necessary to organize your code efficiently and reuse common elements across multiple pages." />
                <meta name="Keywords" content="php include external file, include external file in html, php include files from another directory, include php file in another folder, php include file example, php include external php file, include external php files" />
                <link rel="canonical" href="https://www.weblession.com/include-external-php-file" />
                <meta name="og:type" property="og:type" content="article" />
                <meta name="og:url" property="og:url" content="https://www.weblession.com/include-external-php-file" />
                <meta name="og:title" property="og:title" content="Include External PHP Files" />
                <meta name="og:description" property="og:description" content="In web development, it's often necessary to organize your code efficiently and reuse common elements across multiple pages." />
                <meta name="og:image" property="og:image" content="images/latest_php.webp" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <InnerPagesBanner title="Include External PHP Files" description="In web development, it's often necessary to organize your code efficiently and reuse common elements across multiple pages." url="Home" sec="PHP" active="Include External PHP Files" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <p>
                                One way to achieve this is by including external PHP files. This practice allows you to modularize your code and keep it organized, resulting in cleaner and more maintainable projects. In this guide, we'll explore how to include external PHP files and provide practical examples.
                            </p>
                            <h2 className="common_title">Using include and require</h2>
                            <p>
                                In PHP, you can include external PHP files using two main functions: <strong> "include"</strong> and <strong>"require"</strong>. Both functions serve a similar purpose, but there is a crucial difference between them.
                            </p>
                            <ol>
                                <li>
                                    <strong>include:</strong> If the specified file is not found, PHP will issue a warning but continue executing the script.
                                </li>
                                <li>
                                    <strong>require:</strong> If the specified file is not found, PHP will issue a fatal error and stop script execution.
                                </li>
                            </ol>
                            <h2 className="common_title">Example 1: Using include</h2>
                            <p>
                                Suppose you have a file named "header.php" that contains the header of your website. You can include it in another PHP file like this:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codesnippet}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codesnippet)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                If "header.php" doesn't exist, PHP will display a warning but continue executing the script.
                            </p>
                            <h2 className="common_title">Example 2: Using require</h2>
                            <p>
                                Let's say you have a file named "footer.php" that contains the footer section of your website. You can include it in another PHP file like this:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codesnippet2}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codesnippet2)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                If "footer.php" doesn't exist, PHP will display a fatal error and stop script execution.
                            </p>
                            <h2 className="common_title">Using include_once and require_once</h2>
                            <p>
                                To prevent accidentally including the same file multiple times, you can use <strong>"include_once"</strong> and <strong>require_once</strong>. These functions check if the file has already been included, and if so, they won't include it again.
                            </p>
                            <h2 className="common_title">Example 3: Using include_once</h2>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codesnippet3}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codesnippet3)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <h2 className="common_title">Example 4: Using require_once</h2>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codesnippet4}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codesnippet4)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <h2 className="common_title">Conclusion</h2>
                            <p>
                                Including external PHP files is a fundamental technique in web development that promotes code reusability and maintainability. By using <strong>"include",</strong> <strong>"require",</strong> <strong>include_once,</strong> or <strong> require_once,</strong> you can efficiently manage your codebase and make your projects more organized and easier to maintain. Whether it's headers, footers, configuration settings, or utility functions, external PHP file inclusion is a valuable tool in your web development toolbox.
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <PHPSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default IncludePHPFiles;