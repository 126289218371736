import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import HTMLSidebar from "./HTMLSidebar";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";

const ViewPortinHTML = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const codeSnippetJSX = `<!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Your Website</title>
    </head>
    <body>
      <header>
        <h1>Welcome to Your Website</h1>
      </header>
      
      <nav>
        <ul>
          <li><a href="#">Home</a></li>
          <li><a href="#">About</a></li>
          <li><a href="#">Services</a></li>
          <li><a href="#">Contact</a></li>
        </ul>
      </nav>
      
      <main>
        <section>
          <h2>About Us</h2>
          <p>Your content goes here</p>
        </section>
        
        <section>
          <h2>Our Services</h2>
          <ul>
            <li>Service 1</li>
            <li>Service 2</li>
            <li>Service 3</li>
          </ul>
        </section>
      </main>
      
      <footer>
        <p>&copy; 2023 Your Website. All rights reserved.</p>
      </footer>
    </body>
</html>`;
    const codeSnippetJSX2 = `"<head>"`;

    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    return (
        <>
            <HighLight />
            <Helmet>
                <title>Viewport in HTML - Weblession</title>
                <meta name="description" content="The viewport meta tag is used to control the layout and behavior of a web page's viewport on mobile devices. It allows you to define how the content should be displayed and scaled within the available screen space..." />
                <meta name="Keywords" content="meta tag viewport in html, why we use viewport in html, how to find viewport in html, how to do viewport in html, viewport in html example, how to set viewport in html, how to use viewport in html, how to set viewport size in html, what is viewport meta tag in html, what is viewport attribute in html, what is viewport tag, what is viewport in browser, html viewport width" />
                <link rel="canonical" href="https://www.weblession.com/viewport-in-html" />
                <meta name="og:type" property="og:type" content="article" />
                <meta name="og:url" property="og:url" content="https://www.weblession.com/viewport-in-html" />
                <meta name="og:title" property="og:title" content="Viewport in HTML" />
                <meta name="og:description" property="og:description" content="The viewport meta tag is used to control the layout and behavior of a web page's viewport on mobile devices. It allows you to define how the content should be displayed and scaled within the available screen space..." />
                <meta name="og:image" property="og:image" content="images/html_css.webp" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <InnerPagesBanner title="Viewport in HTML" description="Here's a brief explanation of the viewport meta tag in HTML, along with a code example that you can use on your pages." url="Home" sec="HTML" active="Viewport in HTML" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <h2 className="common_title">Viewport in HTML</h2>
                            <p>
                                The <strong>"viewport"</strong> meta tag is used to control the layout and behavior of a web page's viewport on mobile devices. It allows you to define how the content should be displayed and scaled within the available screen space, ensuring a better user experience across different devices and screen sizes.
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippetJSX}
                                    </code>                                    
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippetJSX)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                In the above example, the <strong>"viewport"</strong> meta tag is included within the <strong>{codeSnippetJSX2}</strong> section of the HTML document. The <strong>"content"</strong> attribute of the <strong>"viewport"</strong> meta tag specifies the initial width and scaling behavior of the viewport.
                            </p>
                            <ol>
                                <li>
                                    <strong>width=device-width:</strong> Sets the width of the viewport to the width of the device's screen.
                                </li>
                                <li>
                                    <strong>initial-scale=1.0:</strong> Sets the initial zoom level to 1.0, ensuring that the content fits within the viewport without unnecessary scaling.
                                </li>
                            </ol>
                            <p>
                                This example provides a basic structure for your website along with the <strong> "viewport"</strong> meta tag to ensure proper responsiveness on different devices. You can customize the content, styles, and additional features as needed for your website.
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <HTMLSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default ViewPortinHTML;