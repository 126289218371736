import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import JquerySidebar from "./JquerySidebar";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";

const AppendTextarea = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const codesnippet = `<!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Append Textarea with jQuery</title>
      <script src="https://code.jquery.com/jquery-3.6.4.min.js"></script>
    </head>
    <body>
    
      <div>
        <span data-action="custom-new-field">Click me to add textarea</span>
      </div>
    
      <script>
        $(document).ready(function() {
          // Attach a click event to span elements with data-action="custom-new-field"
          $('span[data-action="custom-new-field"]').on('click', function() {
            // Check if there is no textarea already appended
            if ($('textarea.custom-textarea').length === 0) {
              // Append a new textarea to the parent of the clicked span
              $(this).parent().append('<textarea class="custom-textarea" rows="4" cols="50"></textarea>');
            }
          });
        });
      </script>
    
    </body>
    </html>`;
    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    return (
        <>
            <HighLight />
            <Helmet>
                <title>How to append textarea if span have data action in jQuery - Weblession</title>
                <meta name="description" content="Below is an example of jQuery code that appends a textarea if a span element has a data-action attribute with the value custom-new-field..." />
                <meta name="Keywords" content="How to append textarea if span have data action, how to append text in textarea using jquery, how to add textbox in jquery datatable, how to append data in textarea using jquery, how to append value to textarea in jquery, how to add textbox in table using jquery, how to add new line in textarea using jquery, how to add calendar in textbox using jquery" />
                <link rel="canonical" href="https://www.weblession.com/how-to-append-textarea-if-span-have-data-action" />

                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3545356737183934"
                    crossorigin="anonymous"></script>

                <script>
                    {
                        `(adsbygoogle = window.adsbygoogle || []).push({ });`
                    }
                </script>

            </Helmet>
            <InnerPagesBanner title="How to append textarea if span have data action in jQuery" description="Below is an example of jQuery code that appends a textarea if a span element has a data-action attribute with the value custom-new-field." url="Home" sec="Jquery" active="Textarea Append" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codesnippet}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codesnippet)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                This code uses jQuery to add a click event listener to the span element. When the span is clicked, it checks if a textarea with the class <strong> custom-textarea</strong> is already present. If not, it appends a new textarea to the parent of the clicked span. The textarea is given the class <strong> custom-textarea</strong> and set with some default rows and columns.
                            </p>
                            <div className="mt-2">
                                {/* Append textarea bottom */}
                                <ins class="adsbygoogle"
                                    style={{ display: 'block' }}
                                    data-ad-client="ca-pub-3545356737183934"
                                    data-ad-slot="3665465570"
                                    data-ad-format="auto"
                                    data-full-width-responsive="true"></ins>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <JquerySidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default AppendTextarea;