import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import PHPSidebar from "./PHPSidebar";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";

const BinaryUpload = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const codesnippet = `<!DOCTYPE html>
    <html>
    <head>
        <title>Upload File</title>
    </head>
    <body>
        <form action="upload.php" method="post" enctype="multipart/form-data">
            Select File to Upload:
            <input type="file" name="fileToUpload" id="fileToUpload">
            <input type="submit" value="Upload File" name="submit">
        </form>
    </body>
    </html>`;
    const codesnippet2 = `<?php
    // SQL Server database connection settings
    $serverName = "your_server_name";
    $connectionOptions = array(
        "Database" => "your_database_name",
        "Uid" => "your_username",
        "PWD" => "your_password"
    );
    
    // Connect to SQL Server database
    $conn = sqlsrv_connect($serverName, $connectionOptions);
    if ($conn === false) {
        die("Failed to connect to SQL Server: " . sqlsrv_errors());
    }
    
    // Check if file is uploaded successfully
    if (isset($_FILES["fileToUpload"]) && $_FILES["fileToUpload"]["error"] == UPLOAD_ERR_OK) {
        // Read the file content
        $fileContent = file_get_contents($_FILES["fileToUpload"]["tmp_name"]);
    
        // Prepare SQL statement
        $sql = "INSERT INTO BinaryData (FileName, FileContent) VALUES (?, ?)";
        $params = array($_FILES["fileToUpload"]["name"], $fileContent);
        
        // Execute SQL statement
        $stmt = sqlsrv_query($conn, $sql, $params);
        
        if ($stmt === false) {
            die("Failed to insert binary data into SQL Server: " . sqlsrv_errors());
        } else {
            echo "File uploaded and inserted into SQL Server successfully.";
        }
    
        // Free statement and close connection
        sqlsrv_free_stmt($stmt);
        sqlsrv_close($conn);
    } else {
        echo "Error uploading file.";
    }
    ?>`;

    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    return (
        <>
            <HighLight />
            <Helmet>
                <title>How to insert binary data to SQL Server from a PHP file upload? - Weblession</title>
                <meta name="description" content="In modern web development, handling file uploads is a common task, especially in PHP..." />
                <meta name="Keywords" content="insert binary data to sql server from a php file, insert binary data into sql server, php insert form data into database, export binary data from sql server to file, php write binary data to file, sql server copy binary data from one table to another, mysql insert binary data, sql server insert binary data" />
                <link rel="canonical" href="https://www.weblession.com/insert-binary-data-to-SQL-server-from-php-file-upload" />
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3545356737183934"
                    crossorigin="anonymous"></script>
                <script>
                    {
                        `(adsbygoogle = window.adsbygoogle || []).push({ });`
                    }
                </script>
            </Helmet>
            <InnerPagesBanner title="How to insert binary data to SQL Server from a PHP file upload?" description="In modern web development, handling file uploads is a common task, especially in PHP..." url="Home" sec="PHP" active="Insert binary data" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <p>
                                However, when dealing with binary data, such as images or documents, it's essential to ensure that the data is properly inserted into your SQL Server database. In this guide, we'll walk through the process of securely uploading binary data from a PHP file upload form into SQL Server.
                            </p>
                            <h2 className="common_title">Setting Up the PHP File Upload Form</h2>
                            <p>First, let's create a simple HTML form to handle file uploads:</p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codesnippet}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codesnippet)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <h2 className="common_title">Handling File Upload in PHP</h2>
                            <p>
                                Now, let's create the PHP script <strong>(upload.php)</strong> to handle the file upload and insertion into SQL Server:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codesnippet2}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codesnippet2)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <h2 className="common_title">Explanation</h2>
                            <ol>
                                <li>
                                    <strong>HTML Form</strong>: We create a simple HTML form with a file input field to allow users to select a file for upload.
                                </li>
                                <li>
                                    <strong>PHP Script</strong>: In the PHP script  <strong>(upload.php)</strong>, we first establish a connection to the SQL Server database using <strong>sqlsrv_connect()</strong>. Ensure you replace placeholders with your actual database credentials.
                                </li>
                                <li>
                                    <strong>File Upload Handling</strong>: We check if a file is successfully uploaded and then read its content using <strong>file_get_contents()</strong>.
                                </li>
                                <li>
                                    <strong>File Upload Handling</strong>: We check if a file is successfully uploaded and then read its content using <strong>file_get_contents()</strong>.
                                </li>
                                <li>
                                    <strong>SQL Insertion</strong>: We then prepare an SQL INSERT statement to insert the file's name and content into the <strong>BinaryData table</strong>.
                                </li>
                                <li>
                                    <strong>Execution</strong>: We execute the SQL statement using <strong>sqlsrv_query()</strong> and handle errors appropriately.
                                </li>
                                <li>
                                    <strong>Cleanup</strong>: Finally, we free the statement and close the database connection.
                                </li>
                            </ol>
                            <p>
                                <strong>Conclusion</strong>
                            </p>
                            <p>
                                By following this guide, you can securely upload binary data from a PHP file upload form into your SQL Server database. Remember to always validate and sanitize user inputs to prevent any security vulnerabilities.
                            </p>
                            <div className="mt-2">
                                <ins className="adsbygoogle"
                                    style={{ display: 'block' }}
                                    data-ad-client="ca-pub-3545356737183934"
                                    data-ad-slot="8834857155"
                                    data-ad-format="auto"
                                    data-full-width-responsive="true"></ins>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <PHPSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section >
            <ReactCommonTopic />
        </>
    )
}

export default BinaryUpload;