import React from "react";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import '../css/Banner.css';

const Banner = () => {
    return (
        <>
            <Helmet>
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3545356737183934"
                    crossorigin="anonymous"></script>
                <script>
                    {
                        `(adsbygoogle = window.adsbygoogle || []).push({});`
                    }
                </script>
            </Helmet>
            <section className="banner_area" id="banner_area">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="effects_widget">
                                <Link to="/insert-binary-data-to-SQL-server-from-php-file-upload" className="effect_link">
                                    <div className="grid">
                                        <figure className="effect-lily">
                                            <img src="images/binary_upload-min.webp" width="630px" height="630px" alt="Azure Key Vault" />
                                            <figcaption>
                                                <div>
                                                    <h2><span>How to insert binary data to SQL Server from a PHP file upload?</span></h2>
                                                    <p>A Guide to Safely Inserting Binary Data into SQL Server from PHP File Uploads.</p>
                                                </div>
                                            </figcaption>
                                        </figure>
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="mb-3">
                                        <div className="effects_widget">
                                            <Link to="/how-to-solve-loaders-jquery-is-not-working-in-livewire-pagination" className="effect_link">
                                                <div className="grid">
                                                    <figure className="effect-lily">
                                                        <img src="images/loader_jquery.webp" className="right_img_banner" alt="HTML" />
                                                    </figure>
                                                </div>
                                                <h3 className="small_heading">PHP</h3>
                                                Loaders & jquery is not working in the livewire pagination
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="mb-3">
                                        <div className="effects_widget">
                                            <Link to="/how-to-install-bootstrap-in-react" className="effect_link">
                                                <div className="grid">
                                                    <figure className="effect-lily">
                                                        <img src="images/react_bootstrap.webp" className="right_img_banner"
                                                            alt="Easy way to bootstrap install in react projects" />
                                                    </figure>
                                                </div>
                                                <h3 className="small_heading">React.js</h3>
                                                Easy way to bootstrap install in react projects
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="mb-3">
                                        <div className="effects_widget">
                                            <Link to="/lock-grid-in-fullscreen" className="effect_link">
                                                <div className="grid">
                                                    <figure className="effect-lily">
                                                        <img src="images/lock_grid.webp" className="right_img_banner"
                                                            alt="Grid lock in fullscreen" />
                                                    </figure>
                                                </div>
                                                <h3 className="small_heading">HTML/CSS</h3>
                                                How to lock a 3x3 grid to the viewport regardless of content size in HTML5?
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="mb-3">
                                        <div className="effects_widget">
                                            <Link to="/delete-all-rows-in-table-in-php" className="effect_link">
                                                <div className="grid">
                                                    <figure className="effect-lily">
                                                        <img src="images/trunket_table.webp" className="right_img_banner"
                                                            alt="which statement is used to delete all rows in a table without having the action logged" />
                                                    </figure>
                                                </div>
                                                <h3 className="small_heading">PHP</h3>
                                                Which statement is used to delete all rows in a table without having the action logged
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-2">
                        {/* High CPC Ad */}
                        <ins className="adsbygoogle"
                            style={{ display: 'block' }}
                            data-ad-client="ca-pub-3545356737183934"
                            data-ad-slot="9808846951"
                            data-ad-format="auto"
                            data-full-width-responsive="true"></ins>
                    </div>
                    <div className="mt-2 latest_technology">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <div className="mb-4">
                                    <h2 className="heading_title">Latest Technology</h2>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="text-end">
                                    <Link to="/recent-post" className="common_btn load_more">See All</Link>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-3">
                                <div className="grid">
                                    <Link to="/what-is-react">
                                        <figure className="effect-lily">
                                            <img src="images/react_latest.webp" className="latest_tech" alt="ReactJS" />
                                            <figcaption>
                                                <div>
                                                    <h2><span>React.js</span></h2>
                                                </div>
                                            </figcaption>
                                        </figure>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="grid">
                                    <Link to="/how-to-install-bootstrap-in-vuejs">
                                        <figure className="effect-lily">
                                            <img src="images/vuejs.webp" className="latest_tech" alt="Vue.js" />
                                            <figcaption>
                                                <div>
                                                    <h2><span>Vue.js</span></h2>
                                                </div>
                                            </figcaption>
                                        </figure>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="grid">
                                    <Link to="/how-to-install-bootstrap-in-angularjs">
                                        <figure className="effect-lily">
                                            <img src="images/angularjs.webp" className="latest_tech" alt="AngularJS" />
                                            <figcaption>
                                                <div>
                                                    <h2><span>AngularJS</span></h2>
                                                </div>
                                            </figcaption>
                                        </figure>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-sm-3">
                                <div className="grid">
                                    <Link to="/what-is-php">
                                        <figure className="effect-lily">
                                            <img src="images/php_latest.webp" className="latest_tech" alt="React Native" />
                                            <figcaption>
                                                <div>
                                                    <h2><span>PHP</span></h2>
                                                </div>
                                            </figcaption>
                                        </figure>
                                    </Link>
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Banner;