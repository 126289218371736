import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import HTMLSidebar from "./HTMLSidebar";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";

const WhatisHTML = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const codeSnippetJSX = `<!DOCTYPE html>
    <html>
    <head>
        <title>My First Web Page</title>
    </head>
    <body>
        <header>
            <h1>Welcome to My Website</h1>
        </header>
        <main>
            <p>This represents a paragraph of text within my web page. HTML allows us to structure content and present it effectively.</p>
        </main>
    </body>
    </html>`;
    const codeSnippetJSX2 = `<!DOCTYPE html>`;
    const codeSnippetJSX3 = `<html>`;
    const codeSnippetJSX4 = `<head>`;
    const codeSnippetJSX5 = `<title>`;
    const codeSnippetJSX6 = `<body>`;
    const codeSnippetJSX7 = `<header>`;
    const codeSnippetJSX8 = `<h1>`;
    const codeSnippetJSX9 = `<main>`;
    const codeSnippetJSX10 = `<p>`;
    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    return (
        <>
        <HighLight />
            <Helmet>
                <title>What is HTML - Weblession</title>
                <meta name="description" content="HTML (Hypertext Markup Language) is the backbone of every web page you encounter. It's the language that structures content on the internet, enabling browsers to display text, images, links..." />
                <meta name="Keywords" content="what is html used for, what is html coding, what is html element, what is html and what does it stand for, what is html and css considered, what is html attributes, what is html and why it is used, what is html basic structure, what is html bootstrap, what is html and css considered, what is html tag" />
                <link rel="canonical" href="https://www.weblession.com/what-is-html" />
                <meta name="og:type" property="og:type" content="article" />
                <meta name="og:url" property="og:url" content="https://www.weblession.com/what-is-html" />
                <meta name="og:title" property="og:title" content="What is HTML" />
                <meta name="og:description" property="og:description" content="Understanding HTML - Building the Foundations of the Web." />
                <meta name="og:image" property="og:image" content="images/html_css.webp" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <InnerPagesBanner title="What is HTML" description="Understanding HTML - Building the Foundations of the Web." url="Home" sec="HTML" active="What is HTML" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <h2 className="common_title">Introduction</h2>
                            <p>
                                HTML (Hypertext Markup Language) is the backbone of every web page you encounter. It's the language that structures content on the internet, enabling browsers to display text, images, links, and more in a visually appealing and organized manner. In this article, we'll take a closer look at HTML and its role in creating web pages.
                            </p>
                            <h2 className="common_title">What is HTML?</h2>
                            <p>
                                At its core, HTML is a markup language that uses various tags to define the structure and content of a web page. Each HTML tag corresponds to an element, and these elements determine how content is displayed. HTML provides the foundation upon which other technologies like CSS and JavaScript build to create interactive and stylish websites.
                            </p>
                            <h2 className="common_title">HTML Example: Creating a Basic Web Page</h2>
                            <p>
                                Let's start with a simple example of an HTML structure for a basic web page. In this example, we'll create a webpage with a title, a header, and a paragraph of text.
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippetJSX}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippetJSX)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <h2 className="common_title">Key Components of the Example</h2>
                            <ol>
                                <li>
                                    <strong>{codeSnippetJSX2}</strong> : This declaration specifies the type of document and the version of HTML that is being utilized.
                                </li>
                                <li>
                                    <strong>{codeSnippetJSX3}</strong> : The root element that wraps all other HTML content.
                                </li>
                                <li>
                                    <strong>{codeSnippetJSX4}</strong> : Contains meta-information about the web page, such as the title shown in the browser's tab.
                                </li>
                                <li>
                                    <strong>{codeSnippetJSX5}</strong> : Sets the title of the web page, which appears in the browser's tab or window.
                                </li>
                                <li>
                                    <strong>{codeSnippetJSX6}</strong> : Contains the visible content of the web page.
                                </li>
                                <li>
                                    <strong>{codeSnippetJSX7}</strong> : Represents the header section of the page, often containing the website's logo and main heading.
                                </li>
                                <li>
                                    <strong>{codeSnippetJSX8}</strong> : Defines the main heading, typically the largest and most prominent text on the page.
                                </li>
                                <li>
                                    <strong>{codeSnippetJSX9}</strong> : Holds the primary content of the webpage.
                                </li>
                                <li>
                                    <strong>{codeSnippetJSX10}</strong> : Stands for "paragraph" and is used to structure and display text content.
                                </li>
                            </ol>
                            <h2 className="common_title">Conclusion</h2>
                            <p>
                                HTML is the cornerstone of web development, providing the structure and framework for creating web pages. By understanding the basic elements and tags, you're equipped to build more complex and engaging websites. As you delve deeper into web development, you'll learn how to combine HTML with other technologies to create dynamic and interactive online experiences.
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <HTMLSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default WhatisHTML;