import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import HTMLSidebar from "./HTMLSidebar";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";

const SelfClosing = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const codeSnippetJSX = `"(<img>)"`;
    const codeSnippetJSX2 = `<img src="image.jpg" alt="A beautiful image">`;
    const codeSnippetJSX3 = `"<br>"`;
    const codeSnippetJSX4 = `<p>This is a paragraph.<br>This is a new line.</p>`;
    const codeSnippetJSX5 = `"<hr>"`;
    const codeSnippetJSX6 = `<p>Content above the line</p>
    <hr>
<p>Content below the line</p>`;
    const codeSnippetJSX7 = `"<meta>"`;
    const codeSnippetJSX8 = `"<head>"`;
    const codeSnippetJSX9 = `<meta charset="UTF-8">
<meta name="author" content="John Doe">`;
    const codeSnippetJSX10 = `"input"`;
    const codeSnippetJSX11 = `<input type="text" name="username" placeholder="Enter your username">`;
    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    return (
        <>
            <HighLight />
            <Helmet>
                <title>Self-Closing Tags in HTML - Weblession</title>
                <meta name="description" content="Self-closing tags in HTML are a fundamental aspect of web development. They allow you to insert various elements into your web pages without needing separate opening and closing tags." />
                <meta name="Keywords" content="list of self closing tags in html, examples of self closing tags in html, self-closing tags in html5, what are self closing tags in html, html self closing tags div, self closing tags in html list, self closing tags html5, not a self closing tag in html " />
                <link rel="canonical" href="https://www.weblession.com/self-closing-tag" />
                <meta name="og:type" property="og:type" content="article" />
                <meta name="og:url" property="og:url" content="https://www.weblession.com/self-closing-tag" />
                <meta name="og:title" property="og:title" content="Self-Closing Tags in HTML" />
                <meta name="og:description" property="og:description" content="Self-closing tags in HTML are a fundamental aspect of web development. They allow you to insert various elements into your web pages without needing separate opening and closing tags." />
                <meta name="og:image" property="og:image" content="images/html_css.webp" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <InnerPagesBanner title="Self-Closing Tags in HTML" description="Self-closing tags in HTML are a fundamental aspect of web development. They allow you to insert various elements into your web pages without needing separate opening and closing tags." url="Home" sec="HTML" active="Self-Closing Tags" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <h2 className="common_title">What Are Self-Closing Tags?</h2>
                            <p>
                                Self-closing tags, also known as void or empty tags, are HTML elements that don't require a closing tag. They are self-contained and serve specific functions within the document. Self-closing tags are typically used to embed multimedia, provide metadata, or create line breaks.
                            </p>
                            <h2 className="common_title">Common Self-Closing Tags and Examples</h2>
                            <ol>
                                <li>
                                    <strong>Image Tag {codeSnippetJSX}</strong>
                                    <div className="position-relative">
                                        <pre>
                                            <code className="javascript">
                                                {codeSnippetJSX2}
                                            </code>
                                        </pre>
                                        <div className="copy_btn_right">
                                            <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippetJSX2)}>
                                                <img src="images/copy_icon.svg" />
                                                <span className="tooltiptext">Copy</span>
                                            </button>
                                        </div>
                                    </div>
                                    The image tag is one of the most commonly used self-closing tags. It's used to embed images into webpages.
                                </li>
                                <li>
                                    <strong>Line Break Tag {codeSnippetJSX3}</strong>
                                    <div className="position-relative">
                                        <pre>
                                            <code className="javascript">
                                                {codeSnippetJSX4}
                                            </code>
                                        </pre>
                                        <div className="copy_btn_right">
                                            <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippetJSX4)}>
                                                <img src="images/copy_icon.svg" />
                                                <span className="tooltiptext">Copy</span>
                                            </button>
                                        </div>
                                    </div>
                                    The line break tag creates a simple line break within the text content.
                                </li>
                                <li>
                                    <strong>Horizontal Rule Tag {codeSnippetJSX5}</strong>
                                    <div className="position-relative">
                                        <pre>
                                            <code className="javascript">
                                                {codeSnippetJSX6}
                                            </code>
                                        </pre>
                                        <div className="copy_btn_right">
                                            <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippetJSX6)}>
                                                <img src="images/copy_icon.svg" />
                                                <span className="tooltiptext">Copy</span>
                                            </button>
                                        </div>
                                    </div>
                                    The horizontal rule tag inserts a thematic break or horizontal line in the document.
                                </li>
                                <li>
                                    <strong>Meta Tag {codeSnippetJSX7}</strong>
                                    <div className="position-relative">
                                        <pre>
                                            <code className="javascript">
                                                {codeSnippetJSX9}
                                            </code>
                                        </pre>
                                        <div className="copy_btn_right">
                                            <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippetJSX9)}>
                                                <img src="images/copy_icon.svg" />
                                                <span className="tooltiptext">Copy</span>
                                            </button>
                                        </div>
                                    </div>
                                    The meta tag is used to provide metadata about the document, often placed in the <strong> {codeSnippetJSX8}</strong> section.
                                </li>
                                <li>
                                    <strong>Input Tag {codeSnippetJSX10}</strong>
                                    <div className="position-relative">
                                        <pre>
                                            <code className="javascript">
                                                {codeSnippetJSX11}
                                            </code>
                                        </pre>
                                        <div className="copy_btn_right">
                                            <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippetJSX11)}>
                                                <img src="images/copy_icon.svg" />
                                                <span className="tooltiptext">Copy</span>
                                            </button>
                                        </div>
                                    </div>
                                    The input tag is used for various form elements and does not require a closing tag.
                                </li>
                            </ol>
                            <h2 className="common_title">Conclusion</h2>
                            <p>
                                Self-closing tags in HTML are a fundamental part of web development. They simplify the structure of web documents by eliminating the need for closing tags while serving specific purposes. By mastering the usage of self-closing tags, you can make your code cleaner, more efficient, and easier to maintain. Incorporate them wisely into your web development projects to enhance user experience and functionality.
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <HTMLSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default SelfClosing;