import { Route, Routes } from 'react-router-dom';
import AboutUs from './AboutUs';
import TermCondition from './TermsCondition';
import InstallReact from '../ReactPages/InstallReact';
import WhatisReact from '../ReactPages/WhatReact';
import VirtualDomReact from '../ReactPages/VirtualDomReact';
import CreateReactComponent from '../ReactPages/CreateReactComponent';
import PropsReact from '../ReactPages/PropsReact';
import WhatisStateReact from '../ReactPages/WhatisStateReact';
import UseEffectHook from '../ReactPages/useEffectHook';
import WhtaisJSX from '../ReactPages/WhatisJSX';
import BoostrapInstall from '../ReactPages/BootstrapInstall';
import APICallReact from '../ReactPages/APICallinReact';
import WhatisHTML from '../HTMLPages/WhatisHTML';
import WhatisInitialScal from '../HTMLPages/WhatisInitialScal';
import ViewPortinHTML from '../HTMLPages/ViewPortinHTML';
import DiffrenceDIVSpan from '../HTMLPages/DifrenceDIVSpan';
import SemanticHTML from '../HTMLPages/SemanticElement';
import MetaRole from '../HTMLPages/MetaRole';
import SelfClosing from '../HTMLPages/SelfClosing';
import HTMLElement from '../HTMLPages/HTMLElement';
import DisplaFlex from '../HTMLPages/DisplayFlex';
import DataTable from '../HTMLPages/DataTable';
import WhatisPHP from '../PHP/WhatisPHP';
import EchoPrint from '../PHP/DifferenceEchoPrint';
import PHPJavscript from '../PHP/DifferencePHPJavascript';
import IncludePHPFiles from '../PHP/IncludePHPFiles';
import SessioninPHP from '../PHP/SessioninPHP';
import SetRetrieve from '../PHP/SetRetrieve';
import FileUpload from '../PHP/FileUpload';
import RegularExpressions from '../PHP/RegularExpressiions';
import PaginationPHP from '../PHP/PaginationPHP';
import DiffrenceHTMLText from '../JqueryPages/DifferenceHTMLText';
import AjaxJquery from '../JqueryPages/AjaxJquery';
import ChainingJquery from '../JqueryPages/ChainingJquery';
import NoConflictJquery from '../JqueryPages/NoConflictJquery';
import FadeOutJquery from '../JqueryPages/FadeOut';
import PrivacyPolicy from './PrivacyPolicy';
import Home from './Home';
import NotFound from './NotFound';
import HTMLInterview from '../HTMLInterview/HTMLInterview';
import CSSInterview from '../HTMLInterview/CSSInterview';
import FeedRss from './FeedRss';
import FileUploadUsingCss from '../HTMLPages/FileUploadUsingCss';
import ReactInterview from '../HTMLInterview/ReactInterview';
import PHPInterview from '../HTMLInterview/PHPInterview';
import AllListingPages from './AllListingPages';
import ErrorReloading from '../ReactPages/ErrorReloading';
import RenderBlocking from '../ReactPages/RenderBlocking';
import BoostWebsite from '../ReactPages/BoostWebsite';
import DynamicMetaTag from '../ReactPages/DynamicMetaTag';
import LoadMoreCard from '../ReactPages/LoadMoreCard';
import LoadCDNFile from '../HTMLPages/LoadCDN';
import PageDecrease from '../PHP/PageDecrease';
import TextToSpeech from '../ReactPages/TextToSpeech';
import GoogleAdPage from '../ReactPages/GoogleAd';
import CustomHook from '../ReactPages/CustomHook';
import DbounceReact from '../ReactPages/DbounceReact';
import JsonWebToken from '../python/JsonWebToken';
import PygameShape from '../python/PygameShape';
import NumbaWindow from '../python/NumbaWindow';
import SendRequest from '../python/SendRequest';
import RenderTemplate from '../python/RenderTemplate';
import AppendTextarea from '../JqueryPages/AppendTextarea';
import SmallestBlock from '../ReactPages/SmallestBlock';
import FileHandling from '../python/FileHandling';
import PolymorphismPython from '../python/PolymorphismPython';
import DeleteAllTable from '../PHP/DeleteAllTable';
import AzureKey from '../ReactPages/AzureKey';
import LockGrid from '../HTMLPages/LockGrid';
import LoaderJquery from '../PHP/LoaderJquery';
import Woocommerce from '../Wordpress/Woocommerce';
import WordpressPlaceholder from '../Wordpress/WordpressPlaceholder';
import BinaryUpload from '../PHP/BinaryUpload';
import BootstrapInstallVue from '../VueJs/BootstrapInstall';
import BootstrapAngular from '../Angular/BootstrapAngular';
import AngularDirective from '../Angular/AngularDirective';
import DynamicGenerate from '../Angular/DynamicGenerate';
import AvoidRendering from '../VueJs/AvoidRendering';

function AllRoute() {
    return (
        <>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                {/* inner page */}
                <Route exact path="/about-us" element={<AboutUs />} />
                <Route exact path="/term-condition" element={<TermCondition />} />
                {/* React inner page */}
                <Route exact path="/react-install" element={<InstallReact />} />
                <Route exact path="/what-is-react" element={<WhatisReact />} />                
                <Route exact path="/virtual-dom" element={<VirtualDomReact />} />
                <Route exact path="/create-react-component" element={<CreateReactComponent />} />
                <Route exact path="/props-react" element={<PropsReact />} />
                <Route exact path="/what-is-state-in-react" element={<WhatisStateReact />} />
                <Route exact path="/useEffect-hook" element={<UseEffectHook />} />
                <Route exact path="/what-is-JSX" element={<WhtaisJSX />} />
                <Route exact path="/how-to-install-bootstrap-in-react" element={<BoostrapInstall />} />
                <Route exact path="/api-call-in-react" element={<APICallReact />} />
                <Route exact path="/prevent-404-reloading" element={<ErrorReloading />} />
                <Route exact path="/how-to-control-render-blocking-in-an-HTML-reactjs-application" element={<RenderBlocking />} />
                <Route exact path="/boost-your-website-page-speed-a-comprehensive-guide" element={<BoostWebsite />} />
                <Route exact path="/how-to-create-dynamic-meta-tags-for-every-page-in-a-Reactjs-application" element={<DynamicMetaTag />} />
                <Route exact path="/how-to-show-load-more-card-on-reactjs-application" element={<LoadMoreCard />} />
                <Route exact path="/learn-text-to-speech-in-reactjs-application" element={<TextToSpeech />} />
                <Route exact path="/is-it-possible-to-use-a-custom-hook-inside-useeffect-in-react" element={<CustomHook />} />
                <Route exact path="/how-to-perform-debounce-in-reactjs" element={<DbounceReact />} />
                <Route exact path="/smallest-block-in-reactjs" element={<SmallestBlock />} />
                <Route exact path="/how-can-azure-key-vault-secrets-utilized-in-react-app" element={<AzureKey />} />
                {/* VueJS inner page */}
                <Route exact path="/how-to-install-bootstrap-in-vuejs" element={<BootstrapInstallVue />} />
                <Route exact path="/how-can-i-optimize-swiperjs-in-vuejs-to-prevent-page-freezes" element={<AvoidRendering />} />
                {/* Angular inner page */}
                <Route exact path="/how-to-install-bootstrap-in-angularjs" element={<BootstrapAngular />} />
                <Route exact path="/angularjs-directive-not-working-with-uib-tooltip" element={<AngularDirective />} />
                <Route exact path="/how-can-i-dynamically-generate-an-angular-json-file" element={<DynamicGenerate />} />
                {/* HTML inner page */}
                <Route exact path="/what-is-html" element={<WhatisHTML />} />
                <Route exact path="/initial-scale-in-html" element={<WhatisInitialScal />} />
                <Route exact path="/viewport-in-html" element={<ViewPortinHTML />} />
                <Route exact path="/difference-div-span" element={<DiffrenceDIVSpan />} />
                <Route exact path="/semantic-html" element={<SemanticHTML />} />
                <Route exact path="/meta-tag-in-html" element={<MetaRole />} />
                <Route exact path="/self-closing-tag" element={<SelfClosing />} />
                <Route exact path="/html-elements-tag" element={<HTMLElement />} />
                <Route exact path="/display-flex" element={<DisplaFlex />} />
                <Route exact path="/datatable-in-html" element={<DataTable />} />
                <Route exact path="/how-to-Load-CDN-link-after-click-on-button-in-HTML" element={<LoadCDNFile />} />
                <Route exact path="/lock-grid-in-fullscreen" element={<LockGrid />} />
                {/* PHP inner page */}
                <Route exact path="/what-is-php" element={<WhatisPHP />} />
                <Route exact path="/difference-echo-print" element={<EchoPrint />} />
                <Route exact path="/difference-php-javascript" element={<PHPJavscript />} />
                <Route exact path="/include-external-php-file" element={<IncludePHPFiles />} />
                <Route exact path="/session-in-php" element={<SessioninPHP />} />
                <Route exact path="/set-retrieve-cookies" element={<SetRetrieve />} />
                <Route exact path="/file-uploadin-php" element={<FileUpload />} />
                <Route exact path="/regular-expressions-php" element={<RegularExpressions />} />
                <Route exact path="/pagination-in-php" element={<PaginationPHP />} />
                <Route exact path="/how-to-decrease-page-size-in-HTML-and-PHP" element={<PageDecrease />} />
                <Route exact path="/delete-all-rows-in-table-in-php" element={<DeleteAllTable />} />
                <Route exact path="/how-to-solve-loaders-jquery-is-not-working-in-livewire-pagination" element={<LoaderJquery />} />
                <Route exact path="/insert-binary-data-to-SQL-server-from-php-file-upload" element={<BinaryUpload />} />
                {/* JQuery page */}
                <Route exact path="/difference-html-text-method" element={<DiffrenceHTMLText />} />
                <Route exact path="/ajax-request-using-jquery" element={<AjaxJquery />} />
                <Route exact path="/chaining-in-jquery" element={<ChainingJquery />} />
                <Route exact path="/noconflict-method" element={<NoConflictJquery />} />
                <Route exact path="/fade-out-in-jquery" element={<FadeOutJquery />} />
                <Route exact path="/how-to-append-textarea-if-span-have-data-action" element={<AppendTextarea />} />
                {/* InterviewTips page */}
                <Route exact path="/html-interview" element={<HTMLInterview />} />
                <Route exact path="/css-interview" element={<CSSInterview />} />            
                <Route exact path="/react-interview" element={<ReactInterview />} />
                <Route exact path="/php-interview" element={<PHPInterview />} />
                {/* python pages */}
                <Route exact path="/is-it-possible-json-web-token-in-python" element={<JsonWebToken />} />
                <Route exact path="/how-can-i-ensure-my-pygame-shapes-persist-on-the-screen" element={<PygameShape />} />
                <Route exact path="/how-to-install-numba-on-windows-for-python" element={<NumbaWindow />} />
                <Route exact path="/how-to-establish-communication-between-two-servers-using-requests" element={<SendRequest />} />
                <Route exact path="/i-am-not-receiving-any-errors-in-flask" element={<RenderTemplate />} />
                <Route exact path="/file-handling-in-python" element={<FileHandling />} />
                <Route exact path="/polymorphism-in-python" element={<PolymorphismPython />} />
                {/* wordpress pages */}
                <Route exact path="/update-custom-field-in-wordpress-using-wooCommerce" element={<Woocommerce />} />
                <Route exact path="/how-to-set-attribute-placeholder-for-the-wordpress-login-page" element={<WordpressPlaceholder />} />

                {/* Latest Topic */}
                <Route exact path="/recent-post" element={<AllListingPages />} />
                <Route exact path="/how-to-place-manually-google-adSense-ads-in-reactjs" element={<GoogleAdPage />} />
                {/* <Route exact path="/file-upload-using-css" element={<FileUploadUsingCss />} /> */}

                {/* <Route exact path="/feed" element={<FeedRss />} />        */}
                {/* Error route */}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </>
    );
}

export default AllRoute;