import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import PHPSidebar from "./PHPSidebar";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";

const PaginationPHP = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const codeSnippet = `<?php
    $itemsPerPage = 10;
    $totalItems = 100; // Replace with your actual total item count
    $totalPages = ceil($totalItems / $itemsPerPage);
    
    // Determine the current page
    if (isset($_GET['page']) && is_numeric($_GET['page'])) {
        $currentPage = max(min($_GET['page'], $totalPages), 1);
    } else {
        $currentPage = 1;
    }
    
    // Calculate the offset for database query
    $offset = ($currentPage - 1) * $itemsPerPage;
    
    // Use $offset and $itemsPerPage in your database query
    ?>`;
    const codeSnippet2 = `<?php
    // Assuming you have fetched data from the database, $result contains the data
    $data = []; // Replace this with your actual data
    
    echo "<table>";
    foreach ($data as $item) {
        echo "<tr>";
        echo "<td>{$item['name']}</td>"; // Display your data here
        echo "</tr>";
    }
    echo "</table>";
    ?>`;
    const codeSnippet3 = `<?php
    echo "<ul class='pagination'>";
    for ($i = 1; $i <= $totalPages; $i++) {
        $activeClass = ($i == $currentPage) ? 'active' : '';
        echo "<li class='page-item $activeClass'>";
        echo "<a class='page-link' href='?page=$i'>$i</a>";
        echo "</li>";
    }
    echo "</ul>";
    ?>`;

    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    return (
        <>
        <HighLight />
            <Helmet>
                <title>Implement pagination using PHP - Weblession</title>
                <meta name="description" content="Pagination is a vital component in web applications that display large sets of data. It allows you to break..." />
                <meta name="Keywords" content="how to do pagination using php, implement pagination using php, pagination with array php, php pagination limit page numbers, pagination in php code, pagination using function in php, php pagination offset limit, pagination in php simple, pagination in php with example" />
                <link rel="canonical" href="https://www.weblession.com/pagination-in-php" />
                <meta name="og:type" property="og:type" content="article" />
                <meta name="og:url" property="og:url" content="https://www.weblession.com/pagination-in-php" />
                <meta name="og:title" property="og:title" content="Implement pagination using PHP" />
                <meta name="og:description" property="og:description" content="Pagination is a vital component in web applications that display large sets of data. It allows you to break..." />
                <meta name="og:image" property="og:image" content="images/latest_php.webp" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <InnerPagesBanner title="Implement pagination using PHP" description="Pagination is a vital component in web applications that display large sets of data. It allows you to break down extensive datasets into manageable chunks, improving user experience and site performance." url="Home" sec="PHP" active="Pagination using PHP" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <p>
                                In this guide, we will walk you through the process of implementing pagination using PHP, complete with practical code examples.
                            </p>
                            <h2 className="common_title">Table of Contents</h2>
                            <ol>
                                <li>
                                    <strong>Understanding Pagination</strong>
                                    <ul>
                                        <li>Why pagination is important</li>
                                        <li>How pagination works</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Setting Up Your Environment</strong>
                                    <ul>
                                        <li>Basic HTML structure</li>
                                        <li>Sample database with data</li>
                                        <li>PHP configuration</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Pagination Logic</strong>
                                    <ul>
                                        <li>Calculating total pages</li>
                                        <li>Determining current page</li>
                                        <li>Querying data for the current page</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Displaying Paginated Data</strong>
                                    <ul>
                                        <li>Rendering data in a table</li>
                                        <li>Creating pagination links</li>
                                        <li>Styling for user-friendliness</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Handling User Interaction</strong>
                                    <ul>
                                        <li>Linking to previous and next pages</li>
                                        <li>Directly jumping to a specific page</li>
                                    </ul>
                                </li>
                                <li>
                                    <strong>Best Practices and Optimization</strong>
                                    <ul>
                                        <li>Limiting the number of displayed page links</li>
                                        <li>Caching strategies</li>
                                        <li>Security considerations</li>
                                    </ul>
                                </li>
                            </ol>
                            <p>
                                Let's delve into the code examples to illustrate how to implement pagination in PHP:
                            </p>
                            <h2 className="common_title">Example 1: Pagination Logic</h2>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <h2 className="common_title">Example 2: Displaying Paginated Data</h2>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet2}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet2)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <h2 className="common_title">Example 3: Generating Pagination Links</h2>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet3}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet3)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                By following this guide and using the provided code examples, you'll be able to implement pagination in your PHP web application efficiently. Pagination enhances the usability of your application when dealing with large datasets, ensuring that users can navigate through your content seamlessly. Happy coding!
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <PHPSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default PaginationPHP;