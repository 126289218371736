import React from "react";
import { Link } from "react-router-dom";
const TermCondition = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <h1 className="heading_title">Terms & Conditions</h1>
                    <p>
                        Welcome to Weblession! These Terms & Conditions outline the rules and regulations for the use of our website Weblession. Your use or access of our website constitutes acceptance of these Terms. Should you disagree with any aspect of these Terms, kindly abstain from using our website.
                    </p>
                    <h2 className="common_title">Disclaimer</h2>
                    <p>
                        The content provided on the Weblession website is for informational purposes only and should not be considered as professional advice. Weblession does not warrant the accuracy, completeness, or reliability of any content or materials on the Service. You recognize that placing reliance on such information is undertaken at your own risk.
                    </p>
                    <h2 className="common_title">Images Sourced</h2>
                    <p>
                        The images used on this website have been sourced from <Link to="https://www.freepik.com/" className="effect_link" target="_blank">Freepik.com</Link>. We want to provide proper attribution and credit to the talented creators who have contributed these visuals to the Freepik platform.
                    </p>
                    <h2 className="common_title">Changes to Terms</h2>
                    <p>
                        We retain the right to alter or substitute these Terms at any moment without prior notification. Your continued use of Weblession after any changes constitutes acceptance of the modified Terms.
                    </p>
                    <h2 className="common_title">Changes to Terms</h2>
                    <p>
                        If you have any questions about these Terms, please contact us at <Link to="mailto:weblession01@gmail.com" className="effect_link">weblession01@gmail.com</Link>
                    </p>
                    <p>
                        By using Weblession, you acknowledge that you have read, understood, and agreed to these Terms & Conditions. Thank you for visiting our website!
                    </p>
                </div>
            </section>
        </>
    )
}

export default TermCondition;