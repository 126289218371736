import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";
import HTMLSidebar from "../HTMLPages/HTMLSidebar";

const LoadCDNFile = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    const codeSnippet = `<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Load CDN Link After Click</title>
    </head>
    <body>
        <button id="loadCdnButton">Load CDN Link</button>
    </body>
    </html>`;
    const codeSnippet2 = `<script>
    document.addEventListener('DOMContentLoaded', function () {
        const loadCdnButton = document.getElementById('loadCdnButton');
        
        loadCdnButton.addEventListener('click', function () {
            // Create a new script element
            const script = document.createElement('script');
            script.src = 'YOUR_CDN_LINK_HERE'; // Replace with your CDN link
            
            // Append the script to the document's head
            document.head.appendChild(script);
            
            // Once the script is loaded, you can execute code as needed
            script.onload = function () {
                // Code to run after the CDN script has loaded
                console.log('CDN script loaded successfully!');
            };
        });
    });
</script>`;
    return (
        <>
            <HighLight />
            <Helmet>
                <title>How to Load CDN Link After Click on Button in HTML - Weblession</title>
                <meta name="description" content="In the ever-evolving landscape of web development, optimizing website performance is paramount. Content Delivery Networks (CDNs) play a pivotal role in delivering resources like scripts, stylesheets, and images efficiently to users." />
                <meta name="Keywords" content="How to Load CDN Link After Click on Button in HTML, what is a cdn link, how to load cdn link" />
                <link rel="canonical" href="https://www.weblession.com/how-to-Load-CDN-link-after-click-on-button-in-HTML" />
                <meta name="og:type" property="og:type" content="article" />
                <meta name="og:url" property="og:url" content="https://www.weblession.com/how-to-Load-CDN-link-after-click-on-button-in-HTML" />
                <meta name="og:title" property="og:title" content="How to Load CDN Link After Click on Button in HTML" />
                <meta name="og:description" property="og:description" content="In the ever-evolving landscape of web development, optimizing website performance is paramount. Content Delivery Networks (CDNs) play a pivotal role in delivering resources like scripts, stylesheets, and images efficiently to users." />
                <meta name="og:image" property="og:image" content="images/html_css.webp" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <InnerPagesBanner title="How to Load CDN Link After Click on Button in HTML?" description="In the ever-evolving landscape of web development, optimizing website performance is paramount." url="Home" sec="HTML" active="Load CDN in HTML" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <p>
                                Content Delivery Networks (CDNs) play a pivotal role in delivering resources like scripts, stylesheets, and images efficiently to users. But what if you want to load a CDN link only after a user clicks on a button, conserving bandwidth and improving page load times? In this tutorial, we'll walk you through the steps to achieve just that using HTML and JavaScript.
                            </p>
                            <h2 className="common_title">Setting Up Your HTML Structure</h2>
                            <p>
                                Start by creating the HTML structure for your webpage. You'll need a button that users will click to trigger the loading of the CDN link. Here's a basic example:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <h2 className="common_title">Adding JavaScript</h2>
                            <p>
                                Now, let's add JavaScript to handle the button click event and dynamically load the CDN link. Place this script just before the closing <strong>{"</body>"}</strong> tag:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet2}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet2)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                Replace <strong>'YOUR_CDN_LINK_HERE'</strong> with the actual CDN link you want to load.
                            </p>
                            <h2 className="common_title">Test Your Page</h2>
                            <p>
                                Save your HTML file and open it in a web browser. When you click the "Load CDN Link" button, the CDN script will be dynamically loaded, and any code within the script will execute. In this way, you can load resources from a CDN only when they are needed, optimizing your website's performance.
                            </p>
                            <h2 className="common_title">Conclusion</h2>
                            <p>
                                Dynamically loading CDN links after a button click is a valuable technique to enhance your website's speed and efficiency. By following this tutorial, you've learned how to set up a simple HTML page with a button that, when clicked, loads a CDN link using JavaScript. You can further customize and extend this functionality to suit your specific project requirements.
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <HTMLSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default LoadCDNFile;