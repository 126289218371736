const PHPSidebarData = [
    {
        id: 1,
        menutitle:"What is PHP?",
        url:"/what-is-php",
    },
    {
        id: 2,
        menutitle:"Let's Explain the difference between echo and print in PHP",
        url:"/difference-echo-print",
    },
    {
        id: 3,
        menutitle:"Difference between PHP and JavaScript",
        url:"/difference-php-javascript",
    },
    {
        id: 4,
        menutitle:"How do you include an external PHP file in another PHP file?",
        url:"/include-external-php-file",
    },    
    {
        id: 5,
        menutitle:"Session in PHP",
        url:"/session-in-php",
    },
    {
        id: 6,
        menutitle:"Set and retrieve cookies in PHP",
        url:"/set-retrieve-cookies",
    },
    {
        id: 7,
        menutitle:"How to file upload in PHP?",
        url:"/file-uploadin-php",
    },
    {
        id: 8,
        menutitle:"How to use regular expressions in PHP?",
        url:"/regular-expressions-php",
    },
    {
        id: 8,
        menutitle:"Implement pagination using PHP",
        url:"/pagination-in-php",
    },
    {
        id: 9,
        menutitle:"How to decrease page size in HTML and PHP?",
        url:"/how-to-decrease-page-size-in-HTML-and-PHP",
    },
    {
        id: 10,
        menutitle:"How to solve loaders & jquery is not working in the livewire pagination?",
        url:"/how-to-solve-loaders-jquery-is-not-working-in-livewire-pagination",
    },
    {
        id: 11,
        menutitle:"How do I properly insert binary data to SQL Server from a PHP file upload?",
        url:"/insert-binary-data-to-SQL-server-from-php-file-upload",
    }, 
]

export default PHPSidebarData;