import React from "react";
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import '../css/About.css';
import { Helmet } from 'react-helmet';

const AboutUs = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <Helmet>
                <title>About Us - Weblession</title>
            </Helmet>
            <InnerPagesBanner title="Welcome to Weblession" description="Your Ultimate Source for Web Development Information! Whether you're a beginner taking your first steps or a seasoned pro seeking to refine your skills, Weblession, is here to guide you. Start your learning adventure today!" alt="About Us" url="Home" sec="About Us" active="About Us" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="about_center">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <img src="images/about_banner.webp" className="img-fluid" />
                            </div>
                            <div className="col-sm-6">
                                <h2 className="heading_title">Introduction</h2>
                                <p>
                                    At <strong> Weblession,</strong> we are passionate about all things web development. Whether you're a seasoned developer looking to stay updated with the latest trends or a newcomer eager to learn the ropes, we've got you covered. Our platform is designed to be your go-to resource for comprehensive and up-to-date information on web development, catering to enthusiasts and professionals alike.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="mission_area" className="mission_area">
                <div className="container">
                    <div className="about_center text-center">
                        <h2 className="heading_title white_color">Our Mission</h2>
                        <p className="white_color">
                            Our mission is simple yet profound: to empower individuals with the knowledge and skills they need to excel in the ever-evolving field of web development. We understand that the digital landscape is constantly changing, and staying ahead of the curve can be a challenge. That's why we've made it our goal to curate and deliver the most relevant, accurate, and practical information to help you navigate this dynamic industry with confidence.
                        </p>
                    </div>
                </div>
            </section>
            <section id="why_choose" className="why_choose">
                <div className="container">
                    <div className="about_center">
                        <div className="row align-items-center">
                            <div className="col-sm-6">
                                <div className="mb-2">
                                    <h2 className="heading_title">Why Choose Weblession?</h2>
                                    <ul>
                                        <li>
                                            <strong>Dependable Information:</strong> Our commitment lies in providing information that is both precise and trustworthy. Our content is carefully researched and vetted by professionals, so you can trust the knowledge you gain from our platform.
                                        </li>
                                        <li>
                                            <strong>User-Centric Approach:</strong> We've designed our platform with you in mind. Our user-friendly interface makes navigation a breeze, and our content is structured to cater to learners at various skill levels.
                                        </li>
                                        <li>
                                            <strong>Always Growing:</strong> The world of web development never stops evolving, and neither do we. We're committed to continuously expanding our content to cover emerging technologies and trends, ensuring you're well-equipped for the challenges of tomorrow.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="mb-2">
                                    <img src="images/about_why.jpg" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutUs;