const PythonSidebarData = [
    {
        id: 1,
        menutitle:"is it possible JSON Web Token/JWT in a pip.conf file in python?",
        url:"/is-it-possible-json-web-token-in-python",
    },    
    {
        id: 2,
        menutitle:"How can I make my pygame shapes remain on the screen?",
        url:"/how-can-i-ensure-my-pygame-shapes-persist-on-the-screen",
    },
    {
        id: 3,
        menutitle:"How to Install numba on windows for python?",
        url:"/how-to-install-numba-on-windows-for-python",
    },
    {
        id: 4,
        menutitle:"How to Establish Communication Between Two Servers Using Requests?",
        url:"/how-to-establish-communication-between-two-servers-using-requests",
    },
    {
        id: 5,
        menutitle:"I am not receiving any errors in Flask",
        url:"/i-am-not-receiving-any-errors-in-flask",
    },
    {
        id: 6,
        menutitle:"File Handling in pyhton",
        url:"/file-handling-in-python",
    },
    {
        id: 7,
        menutitle:"Polymorphism in python",
        url:"/polymorphism-in-python",
    },
]

export default PythonSidebarData;