import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import HTMLSidebar from "./HTMLSidebar";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";

const HTMLElement = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const codeSnippetJSX = `"<>"`;
    const codeSnippetJSX2 = `"<h1>, <h2>, <h3>, ... <h6>"`;
    const codeSnippetJSX3 = `"<h1>"`;
    const codeSnippetJSX4 = `"<h6>"`;
    const codeSnippetJSX5 = `<h1>This is a Heading Level 1</h1>
<h2>This is a Heading Level 2</h2>`;
    const codeSnippetJSX6 = `"<p>"`;
    const codeSnippetJSX7 = `<p>This is a paragraph of text.</p>`;
    const codeSnippetJSX8 = `"<a>"`;
    const codeSnippetJSX9 = `<a href="https://www.example.com">Visit Example.com</a>`;
    const codeSnippetJSX10 = `"<img>"`;
    const codeSnippetJSX11 = `<img src="image.jpg" alt="Description of the image">`;
    const codeSnippetJSX12 = `"<ul>, <ol>, <li>"`;
    const codeSnippetJSX13 = `<ul>
    <li>Item 1</li>
    <li>Item 2</li>
  </ul>`;
    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    return (
        <>
            <HighLight />
            <Helmet>
                <title>HTML Elements and Tags - Weblession</title>
                <meta name="description" content="In this article, we'll explore the fundamental building blocks of web development and provide clear code examples to illustrate their usage." />
                <meta name="Keywords" content="difference between html elements and tags, 	
what are tags and elements inserted in html document, what is the difference between html elements and tags, explain p tag in html with example, difference between elements and tags used in html, explain html elements and tags, all html elements and tags" />
                <link rel="canonical" href="https://www.weblession.com/html-elements-tag" />
                <meta name="og:type" property="og:type" content="article" />
                <meta name="og:url" property="og:url" content="https://www.weblession.com/html-elements-tag" />
                <meta name="og:title" property="og:title" content="HTML Elements and Tags" />
                <meta name="og:description" property="og:description" content="In this article, we'll explore the fundamental building blocks of web development and provide clear code examples to illustrate their usage." />
                <meta name="og:image" property="og:image" content="images/html_css.webp" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <InnerPagesBanner title="HTML Elements and Tags" description="In this article, we'll explore the fundamental building blocks of web development and provide clear code examples to illustrate their usage." url="Home" sec="HTML" active="HTML Elements and Tags" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <h2 className="common_title">HTML Elements</h2>
                            <p>
                                HTML elements are the core units of content within a web page. Each element represents a specific type of content and is defined by a pair of tags: an opening tag and a closing tag. The opening tag denotes the beginning of the element, and the closing tag marks its end. Elements can contain text, images, links, and other types of content.
                            </p>
                            <h2 className="common_title">HTML Tags</h2>
                            <p>
                                HTML tags are used to define and enclose HTML elements. Tags are enclosed within angle brackets (<strong>{codeSnippetJSX}</strong> symbols) and are presented in pairs, comprising an opening tag and a corresponding closing tag.. The closing tag includes a forward slash ('/') before the tag name. Tags work together to structure and format the content on a web page.
                            </p>
                            <h2 className="common_title">Common HTML Elements and Tags</h2>
                            <ol>
                                <li>
                                    <strong>Heading Element {codeSnippetJSX2}</strong>
                                    <div className="position-relative">
                                        <pre>
                                            <code className="javascript">
                                                {codeSnippetJSX5}
                                            </code>
                                        </pre>
                                        <div className="copy_btn_right">
                                            <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippetJSX5)}>
                                                <img src="images/copy_icon.svg" />
                                                <span className="tooltiptext">Copy</span>
                                            </button>
                                        </div>
                                    </div>
                                    Heading elements define headings of various levels, with <strong> {codeSnippetJSX3}</strong> being the highest and <strong>{codeSnippetJSX4}</strong> the lowest.
                                </li>
                                <li>
                                    <strong>Paragraph Element {codeSnippetJSX6}</strong>
                                    <div className="position-relative">
                                        <pre>
                                            <code className="javascript">
                                                {codeSnippetJSX7}
                                            </code>
                                        </pre>
                                        <div className="copy_btn_right">
                                            <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippetJSX7)}>
                                                <img src="images/copy_icon.svg" />
                                                <span className="tooltiptext">Copy</span>
                                            </button>
                                        </div>
                                    </div>
                                    The paragraph element is used for text content.
                                </li>
                                <li>
                                    <strong>Link Element {codeSnippetJSX8}</strong>
                                    <div className="position-relative">
                                        <pre>
                                            <code className="javascript">
                                                {codeSnippetJSX9}
                                            </code>
                                        </pre>
                                        <div className="copy_btn_right">
                                            <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippetJSX9)}>
                                                <img src="images/copy_icon.svg" />
                                                <span className="tooltiptext">Copy</span>
                                            </button>
                                        </div>
                                    </div>
                                    The link element creates hyperlinks to other web pages.
                                </li>
                                <li>
                                    <strong>Image Element {codeSnippetJSX10}</strong>
                                    <div className="position-relative">
                                        <pre>
                                            <code className="javascript">
                                                {codeSnippetJSX11}
                                            </code>
                                        </pre>
                                        <div className="copy_btn_right">
                                            <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippetJSX11)}>
                                                <img src="images/copy_icon.svg" />
                                                <span className="tooltiptext">Copy</span>
                                            </button>
                                        </div>
                                    </div>
                                    The image element embeds images into web pages.
                                </li>
                                <li>
                                    <strong>List Elements {codeSnippetJSX12}</strong>
                                    <div className="position-relative">
                                        <pre>
                                            <code className="javascript">
                                                {codeSnippetJSX13}
                                            </code>
                                        </pre>
                                        <div className="copy_btn_right">
                                            <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippetJSX13)}>
                                                <img src="images/copy_icon.svg" />
                                                <span className="tooltiptext">Copy</span>
                                            </button>
                                        </div>
                                    </div>
                                    List elements create unordered and ordered lists.
                                </li>
                            </ol>
                            <h2 className="common_title">Conclusion</h2>
                            <p>
                                HTML elements and tags are the essential building blocks of web development. By combining these elements creatively and appropriately, web developers can craft engaging and functional web pages. Understanding the role of each element and how to use them effectively is the first step towards becoming proficient in web development.
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <HTMLSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default HTMLElement;