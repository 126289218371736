import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import ReactSidebar from '../Pages/Sidebar';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";

const RenderBlocking = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    const codeSnippet = `<!DOCTYPE html>
    <html>
    <head>
        <title>My React App</title>
        <!-- Non-blocking CSS -->
        <link rel="stylesheet" href="styles.css">
    </head>
    <body>
        <div id="root"></div>
    
        <!-- Load React and your app's JavaScript asynchronously -->
        <script src="https://cdn.jsdelivr.net/npm/react@16/umd/react.production.min.js" async></script>
        <script src="https://cdn.jsdelivr.net/npm/react-dom@16/umd/react-dom.production.min.js" async></script>
        <script src="your-app.js" defer></script>
    </body>
    </html>`;
    const codeSnippet2 = `import React, { lazy, Suspense } from 'react';

    const LazyComponent = lazy(() => import('./LazyComponent'));
    
    function App() {
      return (
        <div>
          <h1>Welcome to My React App</h1>
          <Suspense fallback={<div>Loading...</div>}>
            <LazyComponent />
          </Suspense>
        </div>
      );
    }
    
    export default App;`;
    const codeSnippet3 = `<!DOCTYPE html>
    <html>
    <head>
        <title>My React App</title>
        <!-- Inline Critical CSS -->
        <style>
            /* Your critical CSS here */
        </style>
    </head>
    <body>
        <div id="root"></div>
    
        <!-- Inline Critical JavaScript -->
        <script>
            // Your critical JavaScript code here
        </script>
        <!-- Load non-blocking scripts -->
        <script src="your-non-blocking.js"></script>
    </body>
    </html>`;
    return (
        <>
            <HighLight />
            <Helmet>
                <title>How can I control render blocking in an HTML and React.js application - Weblession</title>
                <meta name="description" content="Render blocking can significantly impact the performance of your HTML and React.js application, slowing down the initial load time and user experience." />
                <meta name="Keywords" content="How to eliminate render-blocking resources react js, render blocking in reactjs, render blocking request, how to stop re rendering in react, render blocking scripts, eliminate render-blocking resources, bootstrap render blocking, how to fix render blocking css, eliminate render-blocking, render-blocking javascript, eliminate render-blocking resources bootstrap" />
                <link rel="canonical" href="https://www.weblession.com/how-to-control-render-blocking-in-an-HTML-reactjs-application" />
                <meta name="og:type" property="og:type" content="article" />
                <meta name="og:url" property="og:url" content="https://www.weblession.com/how-to-control-render-blocking-in-an-HTML-reactjs-application" />
                <meta name="og:title" property="og:title" content="How can I control render blocking in an HTML and React.js application" />
                <meta name="og:description" property="og:description" content="Render blocking can significantly impact the performance of your HTML and React.js application, slowing down the initial load time and user experience." />
                <meta name="og:image" property="og:image" content="images/latest_react.webp" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <InnerPagesBanner title="How can I control render blocking in an HTML and React.js application?" description="Render blocking can significantly impact the performance of your HTML and React.js application, slowing down the initial load time and user experience." url="Home" sec="React" active="Render blocking in an HTML and React.js" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <p>
                                It occurs when the browser is prevented from rendering the page until certain resources, like scripts or stylesheets, are loaded and executed. To control render blocking, you can employ various techniques and optimizations. Let's explore some of them with code examples.
                            </p>
                            <h2 className="common_title">Async and Defer Attributes for Script Tags</h2>
                            <p>
                                You can use the async and defer attributes in script tags to control when JavaScript files are executed. Here's how:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                By using async and defer, you allow the browser to load scripts asynchronously without blocking rendering.
                            </p>
                            <h2 className="common_title">Code Splitting in React</h2>
                            <p>
                                React provides built-in support for code splitting, which allows you to split your JavaScript bundle into smaller chunks. This can be especially useful for large React applications. Here's an example using React's lazy and Suspense
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet2}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet2)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                By using lazy and Suspense, you can load components on-demand, reducing the initial bundle size and rendering-blocking JavaScript.
                            </p>
                            <h2 className="common_title">Critical CSS and Inline Scripts</h2>
                            <p>
                                To ensure a faster initial render, consider inlining critical CSS and JavaScript directly into your HTML. For example
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet3}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet3)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                Inlining critical resources eliminates the need for additional requests, speeding up the rendering process.
                            </p>
                            <p>
                                Controlling render blocking in your HTML and React.js application is crucial for optimizing performance. By following these techniques and examples, you can ensure a smoother and faster user experience while maintaining the functionality and usability of your web application.
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <ReactSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default RenderBlocking;