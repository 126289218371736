import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import ReactSidebar from '../Pages/Sidebar';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";

const GoogleAdPage = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const codeSnippet = `npm install react-helmet-async`;
    const codeSnippet2 = `yarn add react-helmet-async`;
    const codeSnippet3 = `import React from 'react';
    import { Helmet } from 'react-helmet-async';
    
    function App() {
      return (
        <div>
          {/* Your React components */}
          <Helmet>
            <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
            <script>
              {           
                (adsbygoogle = window.adsbygoogle || []).push({});
            }
            </script>
            {/* paste script code in between Tilde  */}
          </Helmet>
        </div>
      );
    }
    
export default App;`;

    const codeSnippet4 = `import React from 'react';

    function AdComponent() {
      return (
        <div>
          <ins
            className="adsbygoogle"
            style={{ display: 'block' }}
            data-ad-client="YOUR_AD_CLIENT_ID"
            data-ad-slot="YOUR_AD_SLOT_ID"
            data-ad-format="auto"
          ></ins>
        </div>
      );
    }
    
export default AdComponent;`;

    const [copiedMessage, setCopiedMessage] = useState([]);
    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    return (
        <>
            <HighLight />
            <Helmet>
                <title>How to place manual Google AdSense ads in a React.js - Weblession</title>
                <meta name="description" content="To manually place Google AdSense ads in a React.js application, you can follow these steps:" />
                <meta name="Keywords" content="How to place manual Google AdSense ads in a React.js, google ad place in react.js, react add google ads, react google places api, add google map to react js, react google adsense, google ads in react js, react js google adsense, google ads in react" />
                <link rel="canonical" href="https://www.weblession.com/how-to-place-manually-google-adSense-ads-in-reactjs" />
                <meta name="og:type" property="og:type" content="article" />
                <meta name="og:url" property="og:url" content="https://www.weblession.com/how-to-place-manually-google-adSense-ads-in-reactjs" />
                <meta name="og:title" property="og:title" content="How to place manual Google AdSense ads in a React.js" />
                <meta name="og:description" property="og:description" content="" />
                <meta name="og:image" property="og:image" content="images/manually_place.webp" />
                <meta name="twitter:card" content="summary" />
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3545356737183934"
                    crossorigin="anonymous"></script>
                <script>
                    {
                        `(adsbygoogle = window.adsbygoogle || []).push({ });`
                    }
                </script>
            </Helmet>
            <InnerPagesBanner title="How to place manual Google AdSense ads in a React.js" description="To manually place Google AdSense ads in a React.js application, you can follow these steps:" url="Home" sec="React" active="Manual Google AdSense ads in React.js" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <ins className="adsbygoogle"
                                style={{ display: 'block' }}
                                data-ad-client="ca-pub-3545356737183934"
                                data-ad-slot="2469676314"
                                data-ad-format="auto"
                                data-full-width-responsive="true"></ins>
                            <div className="mt-3">
                                <h2 className="common_title">Create a Google AdSense Account</h2>
                                <p>
                                    If you haven't already, sign up for a Google AdSense account and get your AdSense ad code.
                                </p>
                                <h2 className="common_title">Set Up a React.js Project</h2>
                                <p>
                                    Create or use an existing React.js project where you want to display the ads.
                                </p>
                                <h2 className="common_title">Install React Helmet</h2>
                                <p>
                                    React Helmet is a library that allows you to dynamically insert HTML head elements. You can use it to inject the AdSense script tag into the <strong> {"<head>"}</strong> of your HTML document. Install it with npm or yarn:
                                </p>
                                <div className="position-relative">
                                    <pre>
                                        <code className="javascript">
                                            {codeSnippet}
                                        </code>
                                    </pre>
                                    <div className="copy_btn_right">
                                        <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet)}>
                                            <img src="images/copy_icon.svg" />
                                            <span className="tooltiptext">Copy</span>
                                        </button>
                                    </div>
                                </div>
                                <p>
                                    Or
                                </p>
                                <div className="position-relative">
                                    <pre>
                                        <code className="javascript">
                                            {codeSnippet2}
                                        </code>
                                    </pre>
                                    <div className="copy_btn_right">
                                        <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet2)}>
                                            <img src="images/copy_icon.svg" />
                                            <span className="tooltiptext">Copy</span>
                                        </button>
                                    </div>
                                </div>
                                <h2 className="common_title">
                                    Add AdSense Script to Head
                                </h2>
                                <p>
                                    In your main application file (usually <strong> src/index.js</strong> or <strong>src/App.js</strong>), import <strong>Helmet</strong> from <strong>react-helmet-async</strong>, and use it to insert the Google AdSense script tag into the <strong>{"<head>"}</strong> of your HTML document. Replace <strong>"YOUR_AD_CLIENT_ID"</strong> with your actual AdSense client ID.
                                </p>
                                <div className="position-relative">
                                    <pre>
                                        <code className="javascript">
                                            {codeSnippet3}
                                        </code>
                                    </pre>
                                    <div className="copy_btn_right">
                                        <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet3)}>
                                            <img src="images/copy_icon.svg" />
                                            <span className="tooltiptext">Copy</span>
                                        </button>
                                    </div>
                                </div>
                                <h2 className="common_title">
                                    Place Ad Units in Your Components
                                </h2>
                                <p>
                                    Now that you have the AdSense script in your app, you can place ad units within your components wherever you want to display ads. To do this, you'll need to use AdSense ad unit code. Here's an example of how to insert an AdSense ad unit in a component:
                                </p>
                                <div className="position-relative">
                                    <pre>
                                        <code className="javascript">
                                            {codeSnippet4}
                                        </code>
                                    </pre>
                                    <div className="copy_btn_right">
                                        <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet4)}>
                                            <img src="images/copy_icon.svg" />
                                            <span className="tooltiptext">Copy</span>
                                        </button>
                                    </div>
                                </div>
                                <h2 className="common_title">Styling and Layout</h2>
                                <p>
                                    You can style the ad units according to your application's layout. Just ensure that you comply with Google AdSense policies regarding ad styling.
                                </p>
                                <h2 className="common_title">Testing</h2>
                                <p>
                                    During development, you may see a blank space where ads should appear. This is normal because Google AdSense may not show ads until your site is live and receiving real traffic. Once your site is live and approved by AdSense, ads should start appearing.
                                </p>
                                <h2 className="common_title">Deployment</h2>
                                <p>
                                    Deploy your React.js application to a web server or hosting service to make it accessible to users. Google AdSense will begin serving ads to your site once it's live and compliant with their policies.
                                </p>
                                <p>
                                    Remember to follow Google AdSense's policies and guidelines for ad placement and styling to ensure that your account remains in good standing.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <ReactSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default GoogleAdPage;