const VueSidebarDat = [
    {
        id: 1,
        menutitle:"How to install bootstrap in Angular.js?",
        url:"/how-to-install-bootstrap-in-angularjs",
    },
    {
        id: 2,
        menutitle:"Angular.js directive not working with uib-tooltip",
        url:"/angularjs-directive-not-working-with-uib-tooltip",
    },
    {
        id: 3,
        menutitle:"How can I dynamically generate an angular.json file",
        url:"/how-can-i-dynamically-generate-an-angular-json-file",
    }
]

export default VueSidebarDat;