const HTMLSidebarData = [
    {
        id: 1,
        menutitle:"What is HTML?",
        url:"/what-is-html",
    },
    {
        id: 2,
        menutitle:"Why use initial-scale in HTML?",
        url:"/initial-scale-in-html",
    },
    {
        id: 3,
        menutitle:"Why use viewport in HTML?",
        url:"/viewport-in-html",
    },
    {
        id: 4,
        menutitle:"Difference between <div> and <span> elements.",
        url:"/difference-div-span",
    },
    {
        id: 5,
        menutitle:"Explain the concept of semantic HTML",
        url:"/semantic-html",
    },
    {
        id: 6,
        menutitle:"What is the role of the <meta> tag in HTML?",
        url:"/meta-tag-in-html",
    },
    {
        id: 7,
        menutitle:"What are self-closing tags?",
        url:"/self-closing-tag",
    },
    {
        id: 8,
        menutitle:"What are HTML elements and tags?",
        url:"/html-elements-tag",
    },
    {
        id: 9,
        menutitle:"Use of display flex in CSS",
        url:"/display-flex",
    },
    {
        id: 10,
        menutitle:"How to implement datatable in HTML?",
        url:"/datatable-in-html",
    },
    {
        id: 11,
        menutitle:"Boost Your Website Page Speed: A Comprehensive Guide",
        url:"/boost-your-website-page-speed-a-comprehensive-guide",
    },
    {
        id: 12,
        menutitle:"How to Load CDN link after click on button in HTML",
        url:"/how-to-Load-CDN-link-after-click-on-button-in-HTML",
    },
    {
        id: 13,
        menutitle:"How to lock a 3x3 grid to the viewport regardless of content size in HTML5?",
        url:"/lock-grid-in-fullscreen",
    },
]

export default HTMLSidebarData;