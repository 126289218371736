import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";
import VueSidebar from "../Pages/VueSidebar";

const BootstrapInstallVue = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    const codeSnippet = `npm install -g @vue/cli
vue create vue-bootstrap-project
cd vue-bootstrap-project`;
    const codeSnippet2 = `npm install bootstrap`;
    const codeSnippet3 = `import 'bootstrap/dist/css/bootstrap.css';`;
    const codeSnippet4 = `<template>
    <button class="btn btn-primary">Click me</button>
  </template>
  
  <script>
  export default {
    name: 'BootstrapButton'
  }
  </script>
  
  <style scoped>
  /* Add any custom styles here */
  </style>`;
    const codeSnippet5 = `<template>
  <div id="app">
    <BootstrapButton />
  </div>
</template>

<script>
import BootstrapButton from './components/BootstrapButton.vue';

export default {
  name: 'App',
  components: {
    BootstrapButton
  }
}
</script>

<style>
/* Add any custom styles here */
</style>`;
    return (
        <>
            <HighLight />
            <Helmet>
                <title>How to install bootstrap in Vue.js - Weblession</title>
                <meta name="description" content="Make sure Node.js and npm are installed on your system before proceeding. Then, create a new Vue.js project using the Vue CLI (Command Line Interface). Launch your terminal or command prompt and execute the subsequent commands." />
                <meta name="Keywords" content="How to install bootstrap in Vue.js, install bootstrap in vue 3, install bootstrap vue, how to install bootstrap vue, bootstrap vue installation, vuejs install bootstrap, how to include bootstrap in vue, how to add bootstrap in vue js project, vue 3 install bootstrap 5, install bootstrap in vue js 3, how to use bootstrap with vue 3, vue js 3 bootstrap 5" />
                <link rel="canonical" href="https://www.weblession.com/how-to-install-bootstrap-in-vuejs" />
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3545356737183934"
                    crossorigin="anonymous"></script>
                <script>
                    {
                        `(adsbygoogle = window.adsbygoogle || []).push({ });`
                    }
                </script>
            </Helmet>
            <InnerPagesBanner title="How to install bootstrap in Vue.js" description="Certainly! Here's a step-by-step guide on how to install Bootstrap in a Vue.js project." url="Home" sec="Vue.js" active="Bootstrap install in Vue.js" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <h2 className="common_title">Step 1: Create a Vue.js Project</h2>
                            <p>
                                Make sure Node.js and npm are installed on your system before proceeding. Then, create a new Vue.js project using the Vue CLI (Command Line Interface). Launch your terminal or command prompt and execute the subsequent commands.
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                Follow the prompts to set up your Vue.js project.
                            </p>
                            <h2 className="common_title">Step 2: Install Bootstrap</h2>
                            <p>
                                Once your project is created, navigate into the project directory and install Bootstrap using npm:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet2}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet2)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <h2 className="common_title">Step 3: Import Bootstrap into Your Vue.js Project</h2>
                            <p>
                                Next, you need to import Bootstrap's CSS file into your project. You can do this by adding the following line to your main.js or App.vue file:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet3}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet3)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <h2 className="common_title">Step 4: Use Bootstrap Components in Your Vue Components</h2>
                            <p>
                                Now that Bootstrap is installed and imported into your project, you can start using its components in your Vue components. For example, let's create a simple Bootstrap button component in a new file named <strong>BootstrapButton.vue</strong>:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet4}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet4)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <h2 className="common_title">Step 5: Use the BootstrapButton Component in Your App</h2>
                            <p>
                                Finally, import and use the BootstrapButton component in your App.vue file or any other Vue component:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet5}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet5)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                That's it! You've successfully installed Bootstrap in your Vue.js project and used a Bootstrap component. You can now explore more Bootstrap components and styles to enhance your Vue.js application's UI.
                            </p>
                            <div className="mt-3">
                                {/* Conclusion bottom */}
                                <ins className="adsbygoogle"
                                    style={{ display: 'block' }}
                                    data-ad-client="ca-pub-3545356737183934"
                                    data-ad-slot="2854541895"
                                    data-ad-format="auto"
                                    data-full-width-responsive="true"></ins>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <VueSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default BootstrapInstallVue;