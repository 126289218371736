import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import '../css/sidebar.css';
import PythonSidebarData from "./PythonSidebarData";

const PythonSidebar = () => {
    const [searchInput, setSearchInput] = useState('');
    const filteredData = PythonSidebarData.filter(item =>
        item.menutitle.toLowerCase().includes(searchInput.toLowerCase())
    );
    return (
        <>
            {/* start search filter */}
            <div className="mb-2 position-relative">
                <input className="form-control" type="text" name="" placeholder="Search Menu..." value={searchInput} onChange={(e) => setSearchInput(e.target.value)} id="" />
                <span className="search_icon">
                    <img src="images/search_icon.svg" />
                </span>
            </div>
            {/* end search filter */}

            <ul>
                {
                    filteredData.length === 0 ? (
                        <div className="alert alert-danger p-1 px-2 m-0" role="alert">
                            No matching results found.
                        </div>
                    ) : (
                        filteredData.map((item, index) => (
                            <li key={index}>
                                <NavLink to={item.url} className="side_link" end>{item.menutitle}</NavLink>
                            </li>
                        ))
                    )
                }
            </ul>
        </>
    )
}


export default PythonSidebar;