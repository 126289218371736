import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
// import Home from './Pages/Home';
import Navbar from './Pages/Navbar';
import Footer from './Pages/Footer';
// import { Route, Routes } from 'react-router-dom';
// import ReactInnerRouter from './Router/ReactInnerRouter';
// import PrivacyPolicy from './Pages/PrivacyPolicy';
// import InnerReactRouter from './InnerReactRouter/InnerReactRouter';
// import HTMLRouter from './HTMLRouter/HTMLRouter';
// import PHPRouter from './PHPRouter/PHPRouter';
// import JqueryRouter from "./JqueryRouter/JqueryRouter";
import Loader from './Pages/Loader';
import InterviewTips from "./InterviewRouter/InterviewRouter";
import AllRoute from "./Pages/AllRoute";
import FeedRss from './Pages/FeedRss';

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);



  // const isPage = useLocation().pathname == '/feed' ? false : true;



  return (    
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="App">
            <Navbar />
            <AllRoute />
            {/* <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            </Routes>
            <InnerReactRouter />
            <ReactInnerRouter />
            <HTMLRouter />
            <PHPRouter />
            <JqueryRouter /> */}
            {/* <InterviewTips /> */}
            <Footer />
          </div>
        </>
      )}
    </>
  );


//   if (isPage) {
//     return (
//       <>
//         {loading ? (
//           <Loader />
//         ) : (
//           <>
//             <div className="App">
//               <Navbar />
//               <AllRoute />              
//               <Footer />
//             </div>
//           </>
//         )}
//       </>
//     );
//   } else {
//     return (
//       <>
//         <FeedRss />
//       </>
//     );
//   }
 }

export default App;
