import React from "react";
import Banner from "./Banner";
import LatestTopic from "./LatestTopic";
import InterviewTips from "./InterviewTips";
import { Helmet } from 'react-helmet';
const Home = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    return (
        <>
            <Helmet>
                <title>Weblession - Web development, website design, front-end development, back-end development, responsive design</title>                
                <meta name="description" content="Our platform provides you with expert guidance, the latest tools, and a community of passionate developers to help you master HTML, CSS, JavaScript, jQuery, PHP and ReactJS." />
                <meta name="Keywords" content="Web Development, Website Design, Front-End Development, Responsive Web Design, User Experience (UX), User Interface (UI), Cross-Browser Compatibility, API (Application Programming Interface), Coding Best Practices" />
                <link rel="canonical" href="https://www.weblession.com/" />
                <meta name="og:type" property="og:type" content="article" />
                <meta name="og:url" property="og:url" content="https://www.weblession.com/" />
                <meta name="og:title" property="og:title" content="Responsive Design Techniques, Front-End Frameworks, Mobile-First Development, Web Design Trends 2023" />
                <meta name="og:description" property="og:description" content="Our platform provides you with expert guidance, the latest tools, and a community of passionate developers to help you master HTML, CSS, JavaScript, jQuery, PHP and ReactJS." />
                <meta name="og:image" property="og:image" content="images/html_css.webp" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <Banner />
            <LatestTopic />
            <InterviewTips />
        </>
    )
}

export default Home;