import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import PHPSidebar from "./PHPSidebar";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";

const WhatisPHP = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const codeSnippetJSX = `<!DOCTYPE html>
    <html>
    <head>
        <title>First PHP Code Example</title>
    </head>
    <body>
        <?php
        //starts PHP code here
        $message = "My First PHP Code";
        echo "<h1>$message</h1>";
        //Ends PHP code here
        ?>
    </body>
</html>`;
    const codeSnippetJSX2 = `"<?php ?>"`;
    const codeSnippetJSX3 = `"echo"`;
    const codeSnippetJSX4 = `"<h1>"`;
    const codeSnippetJSX5 = `"$message"`;

    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };

    return (
        <>
        <HighLight />
            <Helmet>
                <title>What is PHP - Weblession</title>
                <meta name="description" content="Hypertext Preprocessor, abbreviated as PHP, enjoys widespread popularity as a server-side scripting language extensively employed in web development." />
                <meta name="Keywords" content="what is php used for, what is php in web development, what is php and what is it used for, what is php application, what is array in php, what is difference between session and cookies in php, what is php code, what is cookies in php, what is php developer job, what is php developer skills, what is php extension, what is php echo" />
                <link rel="canonical" href="https://www.weblession.com/what-is-php" />
                <meta name="og:type" property="og:type" content="article" />
                <meta name="og:url" property="og:url" content="https://www.weblession.com/what-is-php" />
                <meta name="og:title" property="og:title" content="What is PHP" />
                <meta name="og:description" property="og:description" content="Hypertext Preprocessor, abbreviated as PHP, enjoys widespread popularity as a server-side scripting language extensively employed in web development." />
                <meta name="og:image" property="og:image" content="images/latest_php.webp" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <InnerPagesBanner title="What is PHP" description="Hypertext Preprocessor, abbreviated as PHP, enjoys widespread popularity as a server-side scripting language extensively employed in web development." url="Home" sec="PHP" active="What is PHP" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <p>
                                PHP, an open-source server-side scripting language originally tailored for web development, has also found utility as a versatile general-purpose programming language. In stark contrast to the static nature of traditional HTML, PHP empowers developers to craft dynamic web pages capable of reacting to user input, engaging with databases, and executing diverse real-time tasks.
                            </p>
                            <h2 className="common_title">Why Use PHP?</h2>
                            <ul>
                                <li>
                                    <strong>Versatility:</strong> PHP can be embedded within HTML, making it easy to integrate with existing web pages.
                                </li>
                                <li>
                                    <strong>Open Source:</strong> It's free to use and has a vast community of developers and resources.
                                </li>
                                <li>
                                    <strong>Platform Independence:</strong> PHP can run on various operating systems, including Windows, macOS, and Linux.
                                </li>
                                <li>
                                    <strong>Large Community:</strong> There's an extensive library of pre-built scripts and frameworks, simplifying development.
                                </li>
                                <li>
                                    <strong>Database Integration:</strong> PHP seamlessly connects with databases like MySQL, making it suitable for dynamic web applications.
                                </li>
                            </ul>
                            <h2 className="common_title">A Simple PHP Example</h2>
                            <p>
                                Let's create a basic PHP script that displays a <strong>"My First PHP Code"</strong> message. This will give you a glimpse of how PHP works.
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippetJSX}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippetJSX)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                <strong>In this example</strong>
                            </p>
                            <ul>
                                <li>
                                    We embed PHP code within HTML using <strong>{codeSnippetJSX2}</strong> tags.
                                </li>
                                <li>
                                    We declare a variable <strong>{codeSnippetJSX5}</strong> and assign it the value "My First PHP Code".
                                </li>
                                <li>
                                    We use <strong>{codeSnippetJSX3}</strong> to output the message inside an HTML <strong>{codeSnippetJSX4}</strong> element.
                                </li>
                            </ul>
                            <p>
                                When you open this file in a web browser, you'll see "My First PHP Code" displayed on the page. This demonstrates PHP's ability to generate dynamic content within an HTML framework.
                            </p>
                            <h2 className="common_title">Conclusion</h2>
                            <p>
                                PHP is a versatile server-side scripting language that plays a crucial role in web development. It enables the creation of dynamic web pages and offers a wide range of features for building interactive web applications. As you continue your journey into web development, mastering PHP will open up a world of possibilities for creating powerful and engaging websites.
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <PHPSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default WhatisPHP;