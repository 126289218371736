import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";
import WordpressSidebar from "../Pages/WorpdressSidebar";

const Woocommerce = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const codeSnippet = `// Add custom field for shipping country
    function add_custom_field_for_shipping_country() {
        add_post_meta( 'your_custom_field_key', 'shipping_country', '', true );
    }
    add_action( 'woocommerce_checkout_update_order_meta', 'add_custom_field_for_shipping_country' );`;

    const codeSnippet2 = `// Update custom field based on shipping country
    jQuery(document).ready(function($) {
        $('#shipping_country').change(function() {
            var selectedCountry = $(this).val();
            var orderId = $('input[name="post_ID"]').val();
            
            $.ajax({
                url: ajaxurl,
                type: 'POST',
                data: {
                    action: 'update_custom_field_based_on_shipping_country',
                    country: selectedCountry,
                    order_id: orderId,
                    security: ajax_nonce // Add nonce for security
                },
                success: function(response) {
                    console.log('Custom field updated successfully');
                },
                error: function(error) {
                    console.error('Error updating custom field');
                }
            });
        });
    });`;
    const codeSnippet3 = `// Handle AJAX request to update custom field based on shipping country
    function update_custom_field_based_on_shipping_country() {
        check_ajax_referer( 'update_custom_field_nonce', 'security' );
    
        $order_id = isset( $_POST['order_id'] ) ? $_POST['order_id'] : '';
        $selected_country = isset( $_POST['country'] ) ? $_POST['country'] : '';
    
        // Update custom field value
        update_post_meta( $order_id, 'shipping_country', $selected_country );
    
        wp_die();
    }
    add_action( 'wp_ajax_update_custom_field_based_on_shipping_country', 'update_custom_field_based_on_shipping_country' );`;

    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    return (
        <>
            <HighLight />
            <Helmet>
                <title>Updating Custom Field WordPress Using WooCommerce - Weblession</title>
                <meta name="description" content="In WordPress, WooCommerce blocks offer a powerful way to customize and extend the functionality of your e-commerce store..." />
                <meta name="Keywords" content="Updating Custom Field WordPress Using WooCommerce, wordpress custom fields not updating, adding custom fields to woocommerce products, add custom fields to woocommerce products, wordpress custom fields not showing, woocommerce custom product fields, wordpress add custom field to post" />
                <link rel="canonical" href="https://www.weblession.com/update-custom-field-in-wordpress-using-wooCommerce" />
            </Helmet>
            <InnerPagesBanner title="Updating Custom Field WordPress Using WooCommerce" description="In WordPress, WooCommerce blocks offer a powerful way to customize and extend the functionality of your e-commerce store." url="Home" sec="Wordpress" active="WordPress Using WooCommerce" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <p>
                                One common requirement is to update custom field values dynamically based on the shipping country selected by the customer. This can be achieved using WooCommerce blocks along with some custom JavaScript and PHP code.
                            </p>
                            <h2 className="common_title">Step 1: Create a Custom Field</h2>
                            <p>
                                First, we need to create a custom field where we'll store the shipping country for each order. You can create a custom field using a plugin like Advanced Custom Fields or by adding it directly to your theme's <strong>"functions.php"</strong> file.
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                Replace <strong>'your_custom_field_key'</strong> with the actual key for your custom field.
                            </p>
                            <h2 className="common_title">Step 2: Update Custom Field Based on Shipping Country</h2>
                            <p>
                                Next, we'll use JavaScript to detect changes in the shipping country field and update the custom field accordingly. We'll add this script to the checkout page using a custom JavaScript file.
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet2}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet2)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <h2 className="common_title">Step 3: Handle AJAX Request and Update Custom Field</h2>
                            <p>
                                Finally, we'll handle the AJAX request on the server-side and update the custom field value based on the selected shipping country.
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet3}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet3)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                Ensure to replace <strong>'update_custom_field_nonce'</strong> with a unique nonce for security purposes.
                            </p>
                            <h2 className="common_title">Conclusion</h2>
                            <p>
                                By following these steps and incorporating the provided code examples into your WordPress theme or plugin, you can update custom field values based on the shipping country selected by customers during checkout. This customization enhances the flexibility and functionality of your WooCommerce store, providing a tailored experience for your customers based on their shipping preferences.
                            </p>
                            <p>
                                Remember to test thoroughly to ensure everything works as expected, and consider adding error handling and validation to improve the robustness of your solution. Happy coding!
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <WordpressSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default Woocommerce;