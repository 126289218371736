import errorpage from '../images/404_error.webp';
import renderblock from '../images/render_block.webp';
import boostpage from '../images/boost_website.webp';
import dynamicmeta from '../images/dynamic_meta.webp';
import loadmore from '../images/load_more.webp';
import cdnlink from '../images/cdn_link.webp';
import googleads from '../images/manually_place.webp';
import customhook from '../images/custom_hook.webp';
import dbounceReact from '../images/dbounce_perform.webp';
import PythonJson from '../images/python_json.webp';
import PygameShape from '../images/Pygame_shape.webp';
import numbaWindow from '../images/numba_window.webp';
import sendRequest from '../images/send_request.webp';
import RenderTemplate from '../images/render_template.webp';
import AppendTextarea from '../images/jquery_append.webp';
import Polymorphism from '../images/polymorphism_python.webp';
import html from '../images/lock_grid.webp';
import PHPLaravel from '../images/loader_jquery.webp';
import Wordpress from '../images/update_woocommerce-min.webp';
import BinayUpload from '../images/binayupload-min.webp';

const AllPagesData = [
    {        
        title:"How to insert binary data to SQL Server from a PHP file upload?",
        subtitle:"PHP",
        imgsrc: BinayUpload,
        link:"/insert-binary-data-to-SQL-server-from-php-file-upload",
        altTag:"insert binary data to SQL Server from a PHP file upload?",
    },
    {        
        title:"Updating Custom Field WordPress Using WooCommerce",
        subtitle:"Wordpress",
        imgsrc: Wordpress,
        link:"/update-custom-field-in-wordpress-using-wooCommerce",
        altTag:"Updating Custom Field WordPress Using WooCommerce",
    },
    {        
        title:"Loaders & jquery is not working in the livewire pagination",
        subtitle:"PHP laravel",
        imgsrc: PHPLaravel,
        link:"/how-to-solve-loaders-jquery-is-not-working-in-livewire-pagination",
        altTag:"Livewire Pagination",
    },
    {        
        title:"How to lock a 3x3 grid to the viewport regardless of content size in HTML5?",
        subtitle:"HTML",
        imgsrc: html,
        link:"/lock-grid-in-fullscreen",
        altTag:"Lock grid in fullscreen",
    },
    {        
        title:"Polymorphism in python",
        subtitle:"Python",
        imgsrc: Polymorphism,
        link:"/polymorphism-in-python",
        altTag:"polymorphism in python",
    },
    {        
        title:"I am not receiving any errors in Flask; however, I am still unable to render the template.",
        subtitle:"Python",
        imgsrc: RenderTemplate,
        link:"/i-am-not-receiving-any-errors-in-flask",
        altTag:"I am not receiving any errors in Flask",
    },
    {        
        title:"How to append textarea if span have data action?",
        subtitle:"jQuery",
        imgsrc: AppendTextarea,
        link:"/how-to-append-textarea-if-span-have-data-action",
        altTag:"How to append textarea if span have data action?",
    },
    {        
        title:"How to Establish Communication Between Two Servers Using Requests?",
        subtitle:"Python",
        imgsrc: sendRequest,
        link:"/how-to-establish-communication-between-two-servers-using-requests",
        altTag:"How to Establish Communication Between Two Servers Using Requests?",
    },
    {        
        title:"How to Install numba on windows for python?",
        subtitle:"Python",
        imgsrc: numbaWindow,
        link:"/how-to-install-numba-on-windows-for-python",
        altTag:"How to Install numba on windows for python?",
    },
    {        
        title:"How can I make my pygame shapes remain on the screen?",
        subtitle:"Python",
        imgsrc: PygameShape,
        link:"/how-can-i-ensure-my-pygame-shapes-persist-on-the-screen",
        altTag:"How can I ensure my pygame shapes persist on the screen?",
    },
    {        
        title:"is it possible JSON Web Token/JWT in a pip.conf file in python",
        subtitle:"Python",
        imgsrc: PythonJson,
        link:"/is-it-possible-json-web-token-in-python",
        altTag:"is it possible json web token in python",
    },
    {        
        title:"How to Perform Debounce in React.js",
        subtitle:"React.js",
        imgsrc: dbounceReact,
        link:"/how-to-perform-debounce-in-reactjs",
        altTag:"How to Perform Debounce in React.js",
    },
    {        
        title:"How to place manually Google AdSense ads in react.js",
        subtitle:"React.js",
        imgsrc: googleads,
        link:"/how-to-place-manually-google-adSense-ads-in-reactjs",
        altTag:"How to place manually Google AdSense ads in react.js",
    },
    {        
        title:"Is it possible to use a custom hook inside useeffect in react (reactjs, react hooks, development)?",
        subtitle:"React.js",
        imgsrc: customhook,
        link:"/is-it-possible-to-use-a-custom-hook-inside-useeffect-in-react",
        altTag:"404 error when reloading a page in a React.js application",
    },
    {        
        title:"How to Prevent a 404 error when reloading a page in a React.js application?",
        subtitle:"React.js",
        imgsrc: errorpage,
        link:"/prevent-404-reloading",
        altTag:"404 error when reloading a page in a React.js application",
    },
    {        
        title:"How can I control render blocking in an HTML and React.js application?",
        subtitle:"HTML/CSS/JS",
        imgsrc: renderblock,
        link:"/how-to-control-render-blocking-in-an-HTML-reactjs-application",
        altTag:"How can I control render blocking",
    },
    {        
        title:"Boost Your Website Page Speed: A Comprehensive Guide",
        subtitle:"HTML/CSS/JS",
        imgsrc: boostpage,
        link:"/boost-your-website-page-speed-a-comprehensive-guide",
        altTag:"Boost Your Website Page Speed",
    },
    {        
        title:"How to create dynamic meta tags for every page in a React.js application?",
        subtitle:"React.js",
        imgsrc: dynamicmeta,
        link:"/how-to-create-dynamic-meta-tags-for-every-page-in-a-Reactjs-application",
        altTag:"Dynamic meta tags for every page",
    },    
    {        
        title:"How can i show load more card on react.js application?",
        subtitle:"React.js",
        imgsrc: loadmore,
        link:"/how-to-show-load-more-card-on-reactjs-application",
        altTag:"Load more card",
    },
    {        
        title:"How to Load CDN link after click on button in HTML?",
        subtitle:"HTML/JS",
        imgsrc: cdnlink,
        link:"/how-to-Load-CDN-link-after-click-on-button-in-HTML",
        altTag:"CDN Load in HTML",
    },
    {        
        title:"How to decrease page size in HTML and PHP?",
        subtitle:"HTML/PHP",
        imgsrc: boostpage,
        link:"/how-to-decrease-page-size-in-HTML-and-PHP",
        altTag:"Decrease page size",
    },
    {        
        title:"Learn text to speech in react.js application",
        subtitle:"React",
        imgsrc: boostpage,
        link:"/learn-text-to-speech-in-reactjs-application",
        altTag:"Learn text to speech in Reactjs",
    },
]

export default AllPagesData;