import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";
import PythonSidebar from "./Sidebar";

const PygameShape = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    const codeSnippet = `import pygame
    pygame.init()
    
    # Set up the screen
    screen_width = 800
    screen_height = 600
    screen = pygame.display.set_mode((screen_width, screen_height))
    
    # Define colors
    white = (255, 255, 255)
    black = (0, 0, 0)
    
    # Create a clock to control the frame rate
    clock = pygame.time.Clock()
    
    # Define variables to store shape positions
    circle_x = 100
    circle_y = 100
    rect_x = 200
    rect_y = 200
    
    # Game loop
    running = True
    while running:
        for event in pygame.event.get():
            if event.type == pygame.QUIT:
                running = False
    
        # Update the game logic here (if needed)
        # For example, you can update the positions of shapes.
    
        # Clear the screen
        screen.fill(white)
    
        # Draw shapes on the screen
        pygame.draw.circle(screen, black, (circle_x, circle_y), 30)
        pygame.draw.rect(screen, black, (rect_x, rect_y, 50, 50))
    
        # Update the display
        pygame.display.flip()
    
        # Control the frame rate
        clock.tick(60)
    
    # Quit Pygame
pygame.quit()`;

    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    return (
        <>
            <HighLight />
            <Helmet>
                <title>How can I ensure that my Pygame shapes persist on the screen? - Weblession</title>
                <meta name="description" content="In Pygame, shapes are typically drawn on the screen during each frame of the game loop. To make shapes remain on the screen, you need to continuously redraw them in each frame to create the illusion of persistence..." />
                <meta name="Keywords" content="how to make a shape in pygame, draw shape using pygame, pygame random polygon, pygame not displaying, pygame transparent shape, pygame transparent polygon, surface shape pygame, pygame polygon star, how to run pygame examples, pygame draw line between two points, how does pygame work, pygame polygon fill, pygame collision shape, simple pygame example, how to remove a shape in pygame, how to rotate a shape in pygame, pygame antialias polygon, pygame polygon alpha" />
                <link rel="canonical" href="https://www.weblession.com/how-can-i-ensure-my-pygame-shapes-persist-on-the-screen" />
                <meta name="og:type" property="og:type" content="article" />
                <meta name="og:url" property="og:url" content="https://www.weblession.com/how-can-i-ensure-my-pygame-shapes-persist-on-the-screen" />
                <meta name="og:title" property="og:title" content="How can I ensure that my Pygame shapes persist on the screen?" />
                <meta name="og:description" property="og:description" content="In Pygame, shapes are typically drawn on the screen during each frame of the game loop. To make shapes remain on the screen, you need to continuously redraw them in each frame to create the illusion of persistence..." />
                <meta name="og:image" property="og:image" content="images/python_json.webp" />
                <meta name="twitter:card" content="summary" />

                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3545356737183934"
                    crossorigin="anonymous"></script>

                <script>
                    {
                        `(adsbygoogle = window.adsbygoogle || []).push({ });`
                    }
                </script>                
            </Helmet>
            <InnerPagesBanner title="How can I ensure that my Pygame shapes persist on the screen?" description="In Pygame, shapes are typically drawn on the screen during each frame of the game loop." url="Home" sec="Python" active="Pygame Shapes" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <div className="mb-3">
                                {/* pyGame top */}
                                <ins className="adsbygoogle"
                                    style={{ display: 'block' }}
                                    data-ad-client="ca-pub-3545356737183934"
                                    data-ad-slot="1818807145"
                                    data-ad-format="auto"
                                    data-full-width-responsive="true"></ins>
                            </div>
                            <p>
                                To make shapes remain on the screen, you need to continuously redraw them in each frame to create the illusion of persistence. This involves clearing the screen and redrawing all the shapes you want to display in each iteration of the game loop.
                            </p>
                            <p>
                                In Pygame, if you want shapes to remain on the screen, you need to continuously redraw them on each frame. Pygame follows a typical game loop structure, where you update the game's state and redraw the screen at a consistent frame rate. Here's a basic outline of how you can make shapes remain on the screen:
                            </p>
                            <h2 className="common_title">Initialize Pygame</h2>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                In this code:
                            </p>
                            <ul>
                                <li>
                                    We initialize Pygame, set up the screen, and define some basic colors.
                                </li>
                                <li>
                                    We use a game loop to keep the program running until the user closes the window.
                                </li>
                                <li>
                                    Inside the game loop, we can update the game logic, such as changing the positions of the shapes.
                                </li>
                                <li>
                                    We clear the screen by filling it with the background color.
                                </li>
                                <li>
                                    We draw the shapes on the screen using pygame.draw.circle() and pygame.draw.rect().
                                </li>
                                <li>
                                    We update the display using pygame.display.flip() to show the changes.
                                </li>
                                <li>
                                    We use clock.tick(60) to control the frame rate to 60 frames per second (you can adjust this value as needed).
                                </li>
                            </ul>
                            <p>
                                By continuously redrawing the shapes in the game loop, you can make them remain on the screen as long as the game is running.
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <PythonSidebar />
                            </div>
                            <div className="mt-3">
                                {/* right sidebar pygame */}
                                <ins className="adsbygoogle"
                                    style={{ display: 'block' }}
                                    data-ad-client="ca-pub-3545356737183934"
                                    data-ad-slot="6996742519"
                                    data-ad-format="auto"
                                    data-full-width-responsive="true"></ins>
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default PygameShape;