import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";
import ReactSidebar from "../Pages/Sidebar";

const LoadMoreCard = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    const codeSnippet = `npx create-react-app load-more-cards
cd load-more-cards
npm start`;
    const codeSnippet2 = `// src/Card.js

import React from 'react';

const Card = ({ title, content }) => {
  return (
    <div className="card">
      <h2>{title}</h2>
      <p>{content}</p>
    </div>
  );
};

export default Card;`;
    const codeSnippet3 = `// src/LoadMore.js

import React, { useState } from 'react';
import Card from './Card';

const LoadMore = ({ cardsData }) => {
  const [visibleCards, setVisibleCards] = useState(4); // Adjust the initial number of visible cards here

  const loadMore = () => {
    // Increase the number of visible cards by a suitable value
    setVisibleCards(visibleCards + 4); // You can change '4' to any number you prefer
  };

  return (
    <div className="load-more">
      {cardsData.slice(0, visibleCards).map((card, index) => (
        <Card key={index} title={card.title} content={card.content} />
      ))}
      {visibleCards < cardsData.length && (
        <button onClick={loadMore}>Load More</button>
      )}
    </div>
  );
};

export default LoadMore;`;
    const codeSnippet4 = `// src/App.js

import React from 'react';
import './App.css';
import LoadMore from './LoadMore';

const cardData = [
  // Add your card data here
  { title: 'Card 1', content: 'Content for Card 1' },
  { title: 'Card 2', content: 'Content for Card 2' },
  // Add more cards as needed
];

function App() {
  return (
    <div className="App">
      <h1>Load More Cards Example</h1>
      <LoadMore cardsData={cardData} />
    </div>
  );
}

export default App;`;
    return (
        <>
            <HighLight />
            <Helmet>
                <title>How can i show load more card on react.js application - Weblession</title>
            </Helmet>
            <InnerPagesBanner title="How can i show load more card on react.js application?" description="Are you looking to enhance user experience on your React.js application by implementing a Load More feature for displaying cards dynamically? This tutorial will guide you through the process step-by-step, with a practical code example." url="Home" sec="React" active="Dynamic Meta Tag in React.js" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <h2 className="common_title">
                                Set Up Your React.js Project
                            </h2>
                            <p>
                                If you haven't already, create a new React.js project or use an existing one. You can use create-react-app to set up a new project:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <h2 className="common_title">
                                Create a Card Component
                            </h2>
                            <p>
                                Let's start by creating a simple Card component that we can load dynamically. Inside your src directory, create a new file called Card.js:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet2}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet2)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <h2 className="common_title">Create the LoadMore Component</h2>
                            <p>
                                Next, create a <strong>LoadMore.js</strong> component that will manage the "Load More" functionality:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet3}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet3)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <h2 className="common_title">Use the LoadMore Component in Your App</h2>
                            <p>
                                Now, you can use the <strong>LoadMore</strong> component in your main application file <strong> (src/App.js)</strong>, passing in your card data as a prop:
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet4}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet4)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <h2 className="common_title">Style Your Cards</h2>
                            <p>
                                You can add CSS styles to your cards and "Load More" button according to your project's design requirements.
                            </p>
                            <h2 className="common_title">Test and Deploy</h2>
                            <p>
                                Finally, test your React application to ensure everything works as expected. Once you're satisfied, you can deploy it to your preferred hosting platform.
                            </p>                           
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <ReactSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default LoadMoreCard;