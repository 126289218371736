import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import JquerySidebar from "./JquerySidebar";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";

const DiffrenceHTMLText = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const codesnippet1 = `"<p>"`;
    const codesnippet = `<!DOCTYPE html>
    <html>
    <head>
        <title>.html() and .text() method in Jquery</title>
        <script src="https://code.jquery.com/jquery-3.6.0.min.js"></script>
    </head>
    <body>
        <div id="myElement">
            <p>This is some <strong>HTML</strong> content.</p>
        </div>
    
        <script>
            $(document).ready(function () {
                var htmlContent = $('#myElement').html();
                console.log(htmlContent);
            });
        </script>
    </body>
</html>`;
    const codesnippet2 = `"<strong>"`;
    const codesnippet3 = `<script>
    $(document).ready(function () {
        var newHtmlContent = '<p>New HTML content</p>';
        $('#myElement').html(newHtmlContent);
    });
</script>`;
    const codesnippet4 = `<script>
    $(document).ready(function () {
        var textContent = $('#myElement').text();
        console.log(textContent);
    });
</script>`;
    const codesnippet5 = `<script>
    $(document).ready(function () {
        var newTextContent = 'New text content';
        $('#myElement').text(newTextContent);
    });
</script>`;
    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    return (
        <>
        <HighLight />
            <Helmet>
                <title>Difference between .html and .text - Weblession</title>
                <link rel="canonical" href="https://www.weblession.com/difference-html-text-method" />
                <meta name="og:type" property="og:type" content="article" />
                <meta name="og:url" property="og:url" content="https://www.weblession.com/difference-html-text-method" />
                <meta name="og:title" property="og:title" content="Difference between .html and .text" />
                <meta name="og:description" property="og:description" content="When working with jQuery, you often need to manipulate the content of HTML elements. Two commonly used methods for this purpose are .html() and .text()." />
                <meta name="og:image" property="og:image" content="images/latest_jquery.webp" />
                <meta name="twitter:card" content="summary" />
            </Helmet>
            <InnerPagesBanner title="Difference between .html() and .text()" description="When working with jQuery, you often need to manipulate the content of HTML elements. Two commonly used methods for this purpose are .html() and .text()." url="Home" sec="Jquery" active="Difference between .html() and .text()" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">
                            <h2 className="common_title">.html() Method</h2>
                            <p>
                                The  <strong>".html()"</strong> method is used to get or set the HTML content of an element, including any nested HTML tags. It allows you to retrieve the HTML content of an element, or replace it with new HTML content.
                            </p>
                            <h2 className="common_title">Example 1: Getting HTML content</h2>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codesnippet}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codesnippet)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                In this example, the <setong>".html()"</setong> method retrieves the HTML content of the <strong>"#myElement"</strong> div, including the nested <strong>{codesnippet1}</strong> and <strong>{codesnippet2}</strong> tags.
                            </p>
                            <h2 className="common_title">Example 2: Setting HTML content</h2>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codesnippet3}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codesnippet3)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                Here, we use <strong>".html()"</strong> to replace the existing content of <strong>"#myElement"</strong> with the new HTML content provided.
                            </p>
                            <h2 className="common_title">.text() Method</h2>
                            <p>
                                The <strong>".text()"</strong> method, on the other hand, is used to get or set the text content of an element. It retrieves the text content of an element, excluding any HTML tags.
                            </p>
                            <h2 className="common_title">Example 3: Getting text content</h2>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codesnippet4}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codesnippet4)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                In this example, the <strong>".text()"</strong> method retrieves the text content of the <strong>"#myElement"</strong> div, which is "This is some HTML content."
                            </p>
                            <h2 className="common_title">Example 4: Setting text content</h2>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codesnippet5}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codesnippet5)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                Here, we use <strong>".text()"</strong> to replace the existing text content of <strong>"#myElement"</strong> with the new text content provided.
                            </p>
                            <h2 className="common_title">Conclusion</h2>
                            <p>
                                In summary, the main difference between <strong>".html()"</strong> and <strong>.text()</strong> methods in jQuery lies in what they retrieve or set. <strong>".html()"</strong> deals with the HTML content, including tags, while <strong>".text()"</strong> focuses solely on the text content, excluding any HTML tags. Understanding these differences is crucial for effective manipulation of your webpage's content using jQuery.
                            </p>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <JquerySidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default DiffrenceHTMLText;