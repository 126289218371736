import React from 'react';
import '../css/Loader.css';

const Loader = () => {
    return (
        <>
            <div className="loader-container">
                <div className="loader"></div>
            </div>
        </>
    )
}


export default Loader;