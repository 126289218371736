import React, { useState } from "react";
import '../css/InnerPages.css';
import ReactCommonTopic from '../Pages/ReactCommonTopic';
import InnerPagesBanner from "../InnerPagesBanner/InnerPagesbanner";
import { Helmet } from 'react-helmet';
import HighLight from "../Pages/HighLight";
import ReactSidebar from "../Pages/Sidebar";

const AzureKey = () => {
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const [copiedMessage, setCopiedMessage] = useState([]);

    const handleCopyClick = (content) => {
        navigator.clipboard.writeText(content).then(() => {
            setCopiedMessage('Copied to clipboard!');
            setTimeout(() => {
                setCopiedMessage('');
            }, 3000);
        });
    };
    const codeSnippet = `npm install @azure/identity @azure/keyvault-secrets`;
    const codeSnippet2 = `// keyVaultService.js

    import { SecretClient } from "@azure/keyvault-secrets";
    import { DefaultAzureCredential } from "@azure/identity";
    
    const keyVaultName = "<Your-Key-Vault-Name>";
    const vaultUrl ="https://$ {keyVaultName}.vault.azure.net";
    
    const secretClient = new SecretClient(vaultUrl, new DefaultAzureCredential());
    
    export const getKeyVaultSecret = async (secretName) => {
      try {
        const secret = await secretClient.getSecret(secretName);
        return secret.value;
      } catch (error) {
        console.error("Error fetching Key Vault secret:", error.message);
        throw error;
      }
    };`;
    const codeSnippet3 = `// App.js

    import React, { useEffect, useState } from "react";
    import { getKeyVaultSecret } from "./services/keyVaultService";
    
    const App = () => {
      const [apiKey, setApiKey] = useState(null);
    
      useEffect(() => {
        const fetchSecret = async () => {
          try {
            const secretValue = await getKeyVaultSecret("YourSecretName");
            setApiKey(secretValue);
          } catch (error) {
            // Handle error
          }
        };
    
        fetchSecret();
      }, []);
    
      return (
        <div>
          <h1>React App with Azure Key Vault Secrets</h1>
          <p>API Key from Azure Key Vault: {apiKey}</p>
        </div>
      );
    };
    
    export default App;`;
    const codeSnippet4 = `npm start`;
    
    return (
        <>
            <HighLight />
            <Helmet>
                <title>How can Azure Key Vault secrets be utilized in a React App - Weblession</title>
                <meta name="description" content="Website page speed is a critical factor that can significantly impact user experience and search engine rankings..." />
                <meta name="Keywords" content="Using Azure Key Vault secrets in a React app involves retrieving sensitive information securely from Azure Key Vault and incorporating it into your application. Here is a detailed walkthrough accompanied by a code illustration." />
                <link rel="canonical" href="https://www.weblession.com/how-can-azure-key-vault-secrets-utilized-in-react-app" />                
                <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3545356737183934"
                    crossorigin="anonymous"></script>
                <script>
                    {
                        `(adsbygoogle = window.adsbygoogle || []).push({ });`
                    }
                </script>
            </Helmet>
            <InnerPagesBanner title="How can Azure Key Vault secrets be utilized in a React App?" description="Using Azure Key Vault secrets in a React app involves retrieving sensitive information securely from Azure Key Vault and incorporating it into your application. Here is a detailed walkthrough accompanied by a code illustration." url="Home" sec="React" active="Azure Key Vault" />
            <section id="common_inner" className="common_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-9">                            
                            <h2 className="common_title">Step 1: Set Up Azure Key Vault</h2>
                            <ol>
                                <li>
                                    Create an Azure Key Vault in the Azure portal.
                                </li>
                                <li>
                                    Add secrets or keys to your Azure Key Vault.
                                </li>
                            </ol>
                            <h2 className="common_title">Step 2: Set Up Azure AD App Registration</h2>
                            <ol>
                                <li>
                                    Register a new application in the Azure Active Directory (AD).
                                </li>
                                <li>
                                    Grant necessary permissions to the application to access the Key Vault secrets.
                                </li>
                            </ol>
                            <h2 className="common_title">Step 3: Install Necessary Packages</h2>
                            <p>
                                Install the Azure SDK for JavaScript in your React app to interact with Azure Key Vault.
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <h2 className="common_title">Step 4: Implement Key Vault Integration in React App</h2>
                            <p>
                                Create a service to fetch secrets from Azure Key Vault. Use the <strong> @azure/identity</strong> library for authentication and <strong>@azure/keyvault-secrets </strong> for interacting with Key Vault.
                            </p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet2}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet2)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                           <h2 className="common_title">Step 5: Use the Service in Your React Components</h2>
                           <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet3}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet3)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <h2 className="common_title">Step 6: Run Your React App</h2>
                            <p>Start your React app:</p>
                            <div className="position-relative">
                                <pre>
                                    <code className="javascript">
                                        {codeSnippet4}
                                    </code>
                                </pre>
                                <div className="copy_btn_right">
                                    <button className="copy_btn tooltip_new" onClick={() => handleCopyClick(codeSnippet4)}>
                                        <img src="images/copy_icon.svg" />
                                        <span className="tooltiptext">Copy</span>
                                    </button>
                                </div>
                            </div>
                            <p>
                                Now, your React app should securely retrieve and display the Key Vault secret.  
                            </p>
                            <p>
                                Remember to keep your Azure AD credentials and sensitive information secure, and consider additional security measures based on your application requirements.
                            </p>
                            <p>
                                This example provides a basic implementation; you may need to adjust it based on your specific use case and security considerations.
                            </p>
                           
                            <div className="mt-3">
                                {/* Conclusion bottom */}
                                <ins className="adsbygoogle"
                                    style={{ display: 'block' }}
                                    data-ad-client="ca-pub-3545356737183934"
                                    data-ad-slot="2854541895"
                                    data-ad-format="auto"
                                    data-full-width-responsive="true"></ins>
                            </div>
                        </div>
                        <div className="col-sm-3">
                            <div className='sidebar_widget sidebar_around'>
                                <ReactSidebar />
                            </div>
                        </div>
                    </div>
                    <div className="copy_widget">
                        {copiedMessage && <div>{copiedMessage}</div>}
                    </div>
                </div>
            </section>
            <ReactCommonTopic />
        </>
    )
}

export default AzureKey;